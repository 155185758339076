import { MouseEvent, useEffect, useState } from "react";
import {
  Avatar,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { useUser } from "../../../hooks/useUser";
import useSWR from "swr";
import { apiClient } from "../../../services/apiClient";
import { useNavigate } from "react-router-dom";
import { permissionService } from "../../../services/permissionService";
import useLogoutZulip from "../../../hooks/useLogoutZulip";
import useLogout from "../../../hooks/useLogout";
import useLogoutAdmin from "../../../hooks/useLogoutAdmin";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import Box from "@mui/material/Box";
import * as Sentry from "@sentry/react";

declare global {
  interface Window {
    deferredPrompt: any;
  }
}

export default function AccountMenu() {
  const logoutZulip = useLogoutZulip();
  const logout = useLogout();
  const logoutAdmin = useLogoutAdmin();
  const navigate = useNavigate();
  const user = useUser();

  const { data: userClasses } = useSWR(
    apiClient().allUserClassesList.name,
    () => {
      return apiClient()
        .allUserClassesList()
        .then((res) => res.data);
    },
    {
      revalidateIfStale: false,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [accountId, setAccountId] = useState<string>("");
  const open = Boolean(anchorEl);

  useEffect(() => {
    setAccountId(user.account?.toString() || "");
  }, [user]);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleAccountSwitch = async (event: SelectChangeEvent) => {
    setAccountId(event.target.value);
    await apiClient().allUserClassesSwitchAccountCreate(
      parseInt(event.target.value),
    );
    await Promise.all([logoutZulip(), logoutAdmin()]);
    window.location.reload();
  };

  const [showInstall, setShowInstall] = useState(false);
  useEffect(() => {
    if (window?.deferredPrompt) {
      setShowInstall(true);
    } else {
      setShowInstall(false);
    }
  }, [window?.deferredPrompt]);

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton onClick={handleMenuOpen} size="small" sx={{ ml: 2 }}>
          <Avatar sx={{ width: 32, height: 32 }} src={user.imageUrl} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1 },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {userClasses && userClasses?.length > 1 && (
          <FormControl sx={{ m: 1 }}>
            <InputLabel>Current Class</InputLabel>
            <Select
              label="Current Account"
              value={accountId}
              onChange={handleAccountSwitch}
            >
              {userClasses.map(({ account }) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <MenuItem onClick={() => navigate("/home")}>Home</MenuItem>
        {showInstall && (
          <MenuItem
            onClick={async () => {
              if (window?.deferredPrompt === null) return;

              window?.deferredPrompt.prompt();

              const { outcome } = await window.deferredPrompt.userChoice;

              if (outcome === "accepted") {
                Sentry.captureMessage("User accepted the install prompt.", {
                  level: "info",
                });
                setShowInstall(false);
              } else if (outcome === "dismissed") {
                Sentry.captureMessage("User dismissed the install prompt", {
                  level: "info",
                });
              }

              window.deferredPrompt = null;
            }}
          >
            <InstallDesktopIcon />{" "}
            <Box sx={{ paddingLeft: "10px" }}>Save to Home Screen</Box>
          </MenuItem>
        )}
        {user && permissionService.isAdmin(user).success && (
          <MenuItem onClick={() => navigate("/app/getting_started")}>
            Getting Started
          </MenuItem>
        )}
        <MenuItem onClick={() => navigate("/app/profile/0")}>Profile</MenuItem>
        <MenuItem onClick={() => navigate("/app/guide")}>User Guide</MenuItem>
        <MenuItem onClick={() => navigate("/app/suggestions")}>
          Wishlist
        </MenuItem>
        {user && permissionService.isAdmin(user).success && (
          <MenuItem onClick={() => navigate("/admin/classes")}>Admin</MenuItem>
        )}
        <MenuItem onClick={() => logout()}>Logout</MenuItem>
      </Menu>
    </>
  );
}
