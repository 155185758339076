
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import { apiClient } from "../services/apiClient.ts";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import LoadingScreen from "../components/Loading/loadingScreen.tsx";
import SignUp from "../components/SignUp/signUp.tsx";
import WaitlistForm from "../components/Waitlist/waitlistForm.tsx";

export default function InvitePage() {
  const { code } = useParams();
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [used, setUsed] = useState(false);

  useEffect(() => {
    apiClient()
      .allInviteGetByCodeRetrieve(code || "")
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setValid(true);
          setUsed(response.data.used || false);
          console.log("Invite retrieved:", response.data);
        } else {
          setLoading(false);
          setValid(false);
          console.error("Failed to retrieve invite:", response);
        }
      })
      .catch((error) => {
        setLoading(false);
        setValid(false);
        console.error("Failed to retrieve invite:", error);
      });
  }, [code]);

  if (loading) {
    return <LoadingScreen fullScreen={true} />;
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <>
          {valid && (
            <>
              {used && (
                <>
                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{ textAlign: "center" }}
                  >
                    This invite code has already been used but please join our
                    waitlist
                  </Typography>
                  <Box mt={4}>
                    <WaitlistForm />
                  </Box>
                </>
              )}
              {!used && (
                <>
                  <SignUp code={code} />
                </>
              )}
            </>
          )}
          {!valid && (
            <>
              <Typography component="h1" variant="h5">
                Invalid invite code. Please check the code and try again or sign
                up our waitlist
              </Typography>
              <Box mt={4}>
                <WaitlistForm />
              </Box>
            </>
          )}
        </>
      </Box>
    </Container>
  );
}
