import React from "react";
import { Box, Card, CardContent, Stack } from "@mui/material";
import { Nations } from "../../my-api-client";
import ImageBox from "../ImageBox";
import {permissionService} from "../../services/permissionService.ts";
import NationName from "./nationName.tsx";
import {useUser} from "../../hooks/useUser.ts";
import Link from "../link";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

interface UserListProps {
    nation: Nations;
}

const FlagCard: React.FC<UserListProps> = ({ nation }) => {
    const user = useUser();

    return (
        <Card>
            <CardContent>
                <Stack direction={{sm: "column", md: "row"}} spacing={3} p={2} sx={{ justifyContent: "left", alignItems: {sm: "center", md:"start"} }} >
            <ImageBox
                id={"flag_" + nation.id}
                key={"flag_" + nation.id}
                image={nation.imageUrl}
                field={"flag"}
                upload={`nations/${nation.id}/`}
                canEdit={permissionService.canUploadFlag(user, nation).success}
                circle={false}
            />
                    <Box>
            <NationName nation={nation} id={"nation_name_" + nation.id} sx={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"}} />

                    <Link
                        href={`/app/nations/${nation.id}/constitution`}
                        id={"constitution_" + nation.id}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                            padding: "10px 0px",
                            fontSize: ".875rem",
                            textDecoration: "none",
                        }}
                    >
                        Constitution <ArticleOutlinedIcon sx={{ marginLeft: "10px" }} />
                    </Link>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default FlagCard;