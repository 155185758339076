import { cloneElement, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

const Page = ({ title, children }: { title: string; children: any }) => {
  const [queryParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    document.title = title || "";
  }, [title]);

  // this should reload the page if a notification is clicked
  return (
    <>
      {cloneElement(children, {
        key: queryParams.get("notificationId") || location.pathname,
      })}
    </>
  );
};

export default Page;
