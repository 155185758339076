import useSWR, { SWRResponse } from "swr";
import {MilitaryAlliance, TradeAlliance} from "../my-api-client";
import { apiClient } from "../services/apiClient";

export const useTradeAlliances = (): SWRResponse<TradeAlliance[], Error> => {
  const tradeAlliancesFetcher = () => {
    return apiClient()
        .allTradeAlliancesList()
        .then((res) => {
          return res.data;
        });
  };

  return useSWR<TradeAlliance[], Error>(
      apiClient().allTradeAlliancesList.name,
      tradeAlliancesFetcher,
  );
};

export const useMilitaryAlliances = (): SWRResponse<MilitaryAlliance[], Error> => {
    const militaryAlliancesFetcher = () => {
        return apiClient()
            .allMilitaryAlliancesList()
            .then((res) => {
                return res.data;
            });
    };

    return useSWR<TradeAlliance[], Error>(
        apiClient().allMilitaryAlliancesList.name,
        militaryAlliancesFetcher,
    );
};