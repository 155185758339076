
import { FormEvent, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";

export default function ResetPassword() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const isResponseOk = (response: any) => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    } else {
      return response.json().then((data: any) => {
        throw Error(data.email || "Unable to validate email");
      });
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    event.preventDefault();
    fetch(import.meta.env.VITE_NODE + "/api/password_reset/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        email: data.get("email"),
      }),
    })
      .then(isResponseOk)
      .then(() => {
        setSuccess("Please check your email for a password reset link.");
        setError("");
      })
      .catch((err: Error) => {
        console.error(err);
        setError(err.message);
        setSuccess("");
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password?
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            disabled={!!success}
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!!success}
            sx={{ mt: 3, mb: 2 }}
          >
            Send Email
          </Button>
          <Box sx={{ color: "red" }}>{error}</Box>
          <Box>{success}</Box>
        </Box>
      </Box>
    </Container>
  );
}
