import Constitution from "../../components/Constitution";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../components/Loading/loadingScreen.tsx";
import { useNation } from "../../hooks/useNations.ts";

function ConstitutionPage() {
  const { id } = useParams();
  const { data: nation } = useNation(parseInt(id || "0"));

  if (!nation) {
    return <LoadingScreen fullScreen={true} />;
  }

  return (
    <>
      <Constitution key={nation.id} nation={nation} />
    </>
  );
}

export default ConstitutionPage;
