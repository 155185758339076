/** @jsxImportSource @emotion/react */
import {
  AppBar,
  Badge,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useUser } from "../../hooks/useUser";
import AdminHeader from "../AdminHeader";
import NotificationsDropdown from "./Notifications";
import AccountMenu from "./UserMenu";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import RouterLink from "../RouterLink";
import { useNotifs } from "../../hooks/useNotifs.ts";

const Logo: FC = () => (
  <Box
    sx={{
      borderRadius: "50%",
      backgroundColor: "#fff",
      height: "32px",
      width: "32px",
    }}
  >
    <img alt="Logo" src="/favicon-32x32.png" height="32" width="32" />
  </Box>
);

const DrawerContent: FC<{
  pages: { text: string; link: string; condition: boolean }[];
  handleDrawerToggle: () => void;
}> = ({ pages, handleDrawerToggle }) => (
  <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
    <Box
      sx={{
        py: 2,
        display: "flex",
        justifyContent: "center",
        backgroundColor: "primary.main",
        color: "#fff",
      }}
    >
      <Logo />
      <Typography variant="h6" ml={1}>
        GeoStrat
      </Typography>
    </Box>
    <Divider />
    <List>
      {pages
        .filter((item) => item.condition)
        .map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              sx={{ textAlign: "left" }}
              component={RouterLink}
              to={item.link}
            >
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  </Box>
);

export default function Header() {
  const drawerWidth = 240;
  const user = useUser();
  const notifs = useNotifs();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prevState) => !prevState);
  }, []);

  const toggleMenu = useCallback(() => {
    setMenuOpen((prevState) => !prevState);
  }, []);

  const pages = useMemo(() => {
    return [
      { text: "News", link: "/app/news", condition: true, show: false },
      { text: "Nations", link: "/app/nations", condition: true, show: false },
      {
        text: "Diplomacy",
        link: "/app/diplomacy",
        condition: true,
        show:
          (notifs?.data?.militaryAlliances || 0) > 0 ||
          (notifs?.data?.tradeAlliances || 0) > 0 ||
          (notifs?.data?.wars || 0) > 0 ||
          (notifs?.data?.warnings || 0) > 0 ||
          (notifs?.data?.subpoenas || 0) > 0,
      },
      {
        text: "Trade",
        link: "/app/trade",
        condition: true,
        show: (notifs?.data?.trades || 0) > 0,
      },
      {
        text: "Development",
        link: "/app/development",
        condition: true,
        show:
          (notifs?.data?.purchases || 0) > 0 ||
          (notifs?.data?.militaryPurchases || 0) > 0,
      },
      {
        text: "Map",
        link: "/app/map",
        condition: true,
        show:
          (notifs?.data?.placements || 0) > 0 ||
          (notifs?.data?.militaryPlacements || 0) > 0,
      },
    ];
  }, [notifs.data]);

  if (!user) {
    return null;
  }

  return (
    <>
      <Box sx={{ height: "64px" }}></Box>
      <AppBar position="fixed" component="nav" sx={{ height: "64px" }}>
        <Toolbar sx={{ height: "64px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" }, paddingLeft: "24px" }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <Logo />
            <Typography
              ml={1}
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              GeoStrat
            </Typography>
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
              height: "32px",
            }}
          >
            {pages
              .filter((item) => item.condition)
              .map((page) => (
                <Badge
                  variant={"dot"}
                  color="secondary"
                  invisible={!page.show}
                  key={page.link}
                >
                  <Button
                    to={page.link}
                    component={RouterLink}
                    variant="contained"
                    sx={{ boxShadow: "none" }}
                    tabIndex={-1}
                    id={page.text}
                  >
                    {page.text}
                  </Button>
                </Badge>
              ))}
          </Box>
          <Box sx={{ flex: 1, justifyContent: "flex-end", display: "flex" }}>
            {user.isAdmin && (
              <Button
                className={"admin-menu"}
                variant="contained"
                sx={{
                  boxShadow: "none",
                  display: { xs: "inline-block", sm: "inline-block" },
                  maxHeight: "64px",
                }}
                onClick={toggleMenu}
              >
                {user.className.name}: {user.nation.name}
              </Button>
            )}
            <NotificationsDropdown />
            <AccountMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <DrawerContent pages={pages} handleDrawerToggle={handleDrawerToggle} />
      </Drawer>

      {user.isAdmin && menuOpen && <AdminHeader menuOpen={menuOpen} />}
    </>
  );
}
