/** @jsxImportSource @emotion/react */
import {
  Box,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Trade } from "../../../my-api-client";
import { css } from "@emotion/react";
import LoadingScreen from "../../Loading/loadingScreen.tsx";

interface TradesTableProps {
  trades: Trade[] | undefined;
  isLoading: boolean;
}

export default function GlobalTradesTable({
  trades,
  isLoading,
}: TradesTableProps) {
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (trades?.length == 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        No trades yet
      </Box>
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: "center" }}>Proposer</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Offer</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Acceptor</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Offer</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Shipping</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Last Update</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Info</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trades &&
              trades.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell data-label={"Proposer"}>
                    <Stack
                      gap={1}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        css={css`
                          border: 1px solid black;
                        `}
                        src={row.offeringNation.imageUrl}
                        width={24}
                        height={16}
                        alt="offering nation flag"
                      />
                      <Box>{row.offeringNation.name}</Box>
                    </Stack>
                  </TableCell>
                  <TableCell data-label={"Offers"}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {row.offeringResources.map((resource) => {
                        return (
                          <div
                            css={css`
                              display: flex;
                            `}
                            key={resource.id}
                          >
                            <img
                              width="20"
                              height="20"
                              alt="image"
                              src={`${import.meta.env.VITE_CDN}${
                                resource.resourceType.icon
                              }`}
                            />
                            &nbsp;{resource.total}
                          </div>
                        );
                      })}
                    </Box>
                  </TableCell>
                  <TableCell data-label={"Acceptor"}>
                    <Stack
                      gap={1}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        css={css`
                          border: 1px solid black;
                        `}
                        src={row.receivingNation.imageUrl}
                        width={24}
                        height={16}
                        alt="offering nation flag"
                      />
                      <Box>{row.receivingNation.name}</Box>
                    </Stack>
                  </TableCell>
                  <TableCell data-label={"Offers"}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {row.receivingResources.map((resource) => {
                        return (
                          <div
                            css={css`
                              display: flex;
                            `}
                            key={resource.id}
                          >
                            <img
                              width="20"
                              height="20"
                              alt="image"
                              src={`${import.meta.env.VITE_CDN}${
                                resource.resourceType.icon
                              }`}
                            />
                            &nbsp;{resource.total}
                          </div>
                        );
                      })}
                    </Box>
                  </TableCell>
                  <TableCell data-label={"Shipping Cost"}>
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      `}
                    >
                      <img
                        width="20"
                        height="20"
                        alt="image"
                        src={`${import.meta.env.VITE_CDN}/images/Oil.png`}
                      />
                      &nbsp;1
                    </div>
                  </TableCell>
                  <TableCell data-label={"Date"} sx={{ textAlign: "center" }}>
                    {new Date(row.updatedAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell
                    data-label={"Status"}
                    sx={{ whiteSpace: "pre-line" }}
                  >
                    <Box>Proposed By: {row.proposingUser}</Box>
                    <Box>Approved By: {row.confirmationUser}</Box>
                    <Box>Accepted By: {row.opposingUser}</Box>
                  </TableCell>
                  <TableCell
                    data-label={"Status"}
                    sx={{ whiteSpace: "pre-line", textAlign: "center" }}
                  >
                    <Chip label={"Complete"} color={"success"} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
