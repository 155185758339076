
import { Box } from "@mui/material";
import { NationStat } from "../../my-api-client";
import GeoWrapperTooltip from "../GeoWrapperTooltip";
import { FC } from "react";

interface NationStatsDisplayProps {
  nationStat: NationStat;
}

// Main Component to Display Nation Stats as List
const NationPowerCardsDisplay: FC<NationStatsDisplayProps> = ({
  nationStat,
}) => {
  return (
    <>
      
        {nationStat.powerCards?.map((powerCard, index) => (
          <GeoWrapperTooltip
            key={index}
            title={powerCard.description}
            placement="top"
          >
            <Box>
                {powerCard.name + ": " + powerCard.value}
            </Box>
          </GeoWrapperTooltip>
        ))}
    </>
  );
};

export default NationPowerCardsDisplay;
