
import GettingStarted from "../components/GettingStarted";

function GettingStartedPage() {
  return (
    <>
      <GettingStarted />
    </>
  );
}

export default GettingStartedPage;
