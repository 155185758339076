import BasicTabs from "../components/Development/basicTabs";


function DevelopmentPage() {
  return (
    <>
      <BasicTabs />
    </>
  );
}

export default DevelopmentPage;
