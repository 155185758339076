import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const roles = [
  "Prime Minister",
  "Defense Minister",
  "Secretary of State",
  "Comptroller",
  "Chief Financial Officer",
];

const permissionsData = [
  { name: "Declaring War", roles: [1, 1, 0, 0, 0] },
  { name: "Sending Warnings", roles: [1, 1, 1, 0, 0] },
  { name: "Sending Subpoenas", roles: [1, 1, 1, 1, 0] },
  { name: "Managing Military Alliances", roles: [1, 1, 0, 0, 0] },
  { name: "Performing Military Actions", roles: [1, 1, 0, 0, 0] },
  { name: "Managing Trades", roles: [0, 0, 0, 1, 1] },
  { name: "Managing Trade Alliances", roles: [0, 0, 0, 1, 1] },
  { name: "Managing Infrastructure", roles: [0, 0, 0, 1, 1] },
  { name: "Naming Cities", roles: [0, 0, 1, 1, 0] },
  { name: "Uploading Constitution", roles: [0, 0, 0, 1, 0] },
  { name: "Uploading Flag", roles: [0, 0, 0, 1, 0] },
  { name: "Publishing News", roles: [0, 0, 1, 0, 0] },
];

const PermissionsTable = () => {
  return (
    <TableContainer component={Paper}>
      <Table>
        {/* Table Head */}
        <TableHead>
          <TableRow>
            <TableCell>
              <strong></strong>
            </TableCell>
            {roles.map((role, index) => (
              <TableCell key={index} align="center">
                <strong>{role}</strong>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {permissionsData.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <strong>{row.name}</strong>
              </TableCell>
              {row.roles.map((hasPermission, i) => (
                <TableCell key={i} align="center">
                  {hasPermission ? (
                    <span style={{ color: "green", fontSize: "1.2rem" }}>
                      ✔️
                    </span>
                  ) : (
                    <span style={{ color: "red", fontSize: "1.2rem" }}>✘</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PermissionsTable;
