import Nation from "../../components/Nation";


function NationPage() {
  return (
    <>
      <Nation />
    </>
  );
}

export default NationPage;
