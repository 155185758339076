import { Button, Tooltip, useTheme } from "@mui/material";
import { ReactNode } from "react";

// A button with a tooltip
export default function GeoButton({
  title,
  children,
  variant,
  width,
  ...props
}: {
  title: string;
  children: ReactNode;
  variant?: any;
  width?: string;
  [key: string]: any;
}) {
  const theme = useTheme();

  return (
    <Tooltip enterTouchDelay={0} title={title}>
      <span
        style={{
          display: "inline-block",
          cursor: "pointer",
          width: width || "auto",
        }}
      >
        <Button
          variant={variant || "outlined"}
          sx={{
            height: "100%",
            width: "100%",
            "&.Mui-disabled": {
              color: theme.palette.primary.main,
            },
          }}
          {...props}
        >
          {children}
        </Button>
      </span>
    </Tooltip>
  );
}
