import useSWR, { Fetcher, SWRResponse } from "swr";
import { Nations, Nations as NationsType } from "../my-api-client";
import { apiClient } from "../services/apiClient";

export const useNations = (): SWRResponse<Nations[], Error> => {
  const nationsFetcher: Fetcher<NationsType[]> = () => {
    return apiClient()
      .allNationsList()
      .then((res) => {
        return res.data;
      });
  };

  return useSWR<NationsType[], Error>(
    apiClient().allNationsList.name,
    nationsFetcher,
    {
      revalidateIfStale: false,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );
};

export const useNation = (
  id: number,
): {
  data: Nations | undefined;
  error: Error | undefined;
  isLoading: boolean;
} => {
  const nations = useNations();
  return {
    data: nations?.data?.find((nation) => nation.id === id),
    error: nations.error,
    isLoading: nations.isLoading,
  };
};
