import { Tooltip } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

interface GeoWrapperTooltip {
  title: string;
  children: ReactNode;
  inline?: boolean;
  style?: CSSProperties;
  [key: string]: any; // Allows for any other props
}

// A button with a tooltip
export default function GeoWrapperTooltip({
  title,
  children,
  inline,
  ...props
}: GeoWrapperTooltip) {
  return (
    <Tooltip enterTouchDelay={0} title={title}>
      <span
        style={{
          display: inline ? "inline" : "inline-block",
          cursor: "default",
        }}
        {...props}
      >
        {children}
      </span>
    </Tooltip>
  );
}
