import Profile from "../../components/Profile";


function ProfilePage() {
  return (
    <>
      <Profile />
    </>
  );
}

export default ProfilePage;
