import React from "react";
import {Box, Card, CardContent, Divider, Stack} from "@mui/material";
import { Nations, NationStat, ResourceStat } from "../../my-api-client";
import Typography from "@mui/material/Typography";
import { Inventory2 } from "@mui/icons-material";


interface ResourcePanelProps {
    nation: Nations;
    stats?: NationStat;
}

const ResourcePanel: React.FC<ResourcePanelProps> = ({ nation, stats }) => {
    // map stats.production to name: object
    const production = stats?.production?.reduce((acc, curr) => {
        acc[curr.name] = curr;
        return acc;
    }, {} as Record<string, ResourceStat>);

    const consumption = stats?.consumption?.reduce((acc, curr) => {
        acc[curr.name] = curr;
        return acc;
    }, {} as Record<string, ResourceStat>);

    return (
        <Card sx={{height: "100%"}}>
            <CardContent>
        <Stack display="flex" gap={3} p={2}>
            <Box display="flex" alignItems="center" gap={1}>
                <Inventory2  />
                <Typography variant="h6">
                    Resources
                </Typography>
            </Box>
            <Divider sx={{ mt: 1, width: "100%", margin: "auto" }} />
            {nation.resources.map((resource) => (
                <Box key={resource.resourceType.name} display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle1">
                        <img
                            width={20}
                            height={20}
                            alt="image"
                            src={`${import.meta.env.VITE_CDN}${
                                resource.resourceType.icon
                            }`}
                        />
                        {resource.resourceType.name}: {resource.total} (+{production ? production[resource.resourceType.name]?.total : 0}|{consumption ? consumption[resource.resourceType.name]?.total : "-0"})</Typography>
                </Box>
            ))}
        </Stack></CardContent></Card>
    );
};

export default ResourcePanel;
