import { permissionService } from "../../services/permissionService";
import { IconButton, CircularProgress } from "@mui/material";
import { myMutate, useUser } from "../../hooks/useUser";
import { apiClient } from "../../services/apiClient.ts";
import Box from "@mui/material/Box";
import ArticleForm from "./articleForm.tsx";
import { Article } from "../../my-api-client";
import { useState } from "react";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import EditNoteIcon from "@mui/icons-material/EditNote";

export default function EditArticle({
  article,
  editing,
  setEditing,
}: {
  article: Article;
  editing: boolean;
  setEditing: any;
}) {
  const [saving, setSaving] = useState(false);
  const user = useUser();

  const onSave = async (title: string, content: string) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);

    apiClient()
      .allArticlesPartialUpdate(article.id, {
        title: title,
        content: content,
      })
      .then((res) => {
        setEditing(false);
        myMutate(apiClient().allArticlesList.name);
        myMutate(apiClient().allArticlesRetrieve.name);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error editing article: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);
        closeSnackbar(snack);
      });
  };

  const onCancel = () => {
    setEditing(false);
  };

  // @ts-ignore
  return (
    <>
      {permissionService.canEditNews(user, 0).success ? (
        <>
          {!editing ? (
            <Box display="flex" justifyContent="flex-end" mt={0} mb={2}>
              <IconButton
                color="bland"
                sx={{ mt: 2 }}
                onClick={() => {
                  setEditing(true);
                }}
              >
                <EditNoteIcon />
              </IconButton>
            </Box>
          ) : (
            <ArticleForm
              ogTitle={article.title}
              ogContent={article.content}
              onCancel={onCancel}
              onSave={onSave}
              saving={saving}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
