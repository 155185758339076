
import Container from "@mui/material/Container";

function Terms() {
  return (
    <Container
      sx={{
        maxWidth: "1400px",
        padding: "20px",
      }}
    >
      <h1>Terms of Service for Geostratapp.com</h1>
      <p>
        <strong>Last Updated:</strong> 12/14/2023
      </p>

      <h2>1. Agreement to Terms</h2>
      <p>
        By accessing or using our website, you agree to be bound by these Terms
        of Service and our Privacy Policy.
      </p>

      <h2>2. Use of Our Service</h2>
      <p>
        Geostratapp.com provides a platform for students to interact with each
        other while exploring concepts related to geography education. These
        services are provided for your personal, non-commercial use only.
      </p>

      <h2>3. User Conduct</h2>
      <p>
        Users are expected to use the website responsibly and ethically.
        Prohibited activities include
        <ul>
          <li>
            <strong>Harassment and Bullying:</strong> Abusive, threatening,
            discriminatory, or offensive behavior towards others is not
            tolerated.
          </li>
          <li>
            <strong>Posting Inappropriate Content:</strong> No uploading or
            sharing of obscene, offensive, or inappropriate material.
          </li>
          <li>
            <strong>Impersonation:</strong> Users must not impersonate others in
            a deceptive or misleading manner.
          </li>
          <li>
            <strong>Misuse of Personal Information:</strong> Unauthorized
            collection or solicitation of personal information is forbidden.
          </li>
          <li>
            <strong>Illegal Activities:</strong> Engaging in or promoting
            illegal activities is prohibited.
          </li>
          <li>
            <strong>Spamming and Advertising:</strong> Unsolicited promotional
            messages and unauthorized advertising are not permitted.
          </li>
          <li>
            <strong>Spreading Malware or Viruses:</strong> Uploading harmful
            software, viruses, or malware is banned.
          </li>
          <li>
            <strong>Disrupting the Platform's Operation:</strong> Actions
            designed to disrupt the operation of the website are prohibited.
          </li>
          <li>
            <strong>Sharing Inappropriate External Links:</strong> Posting
            irrelevant or inappropriate external links is not allowed.
          </li>
        </ul>
      </p>

      <h2>4. Intellectual Property Rights</h2>
      <p>
        All content on this website, including text, graphics, logos, and
        images, is the property of Geostratapp.com or its content suppliers and
        protected by copyright and intellectual property laws.
      </p>

      <h2>5. Third-Party Links</h2>
      <p>
        Our website may contain links to third-party websites or services that
        are not owned or controlled by Geostratapp.com. We assume no
        responsibility for the content, privacy policies, or practices of any
        third-party websites or services.
      </p>

      <h2>6. Termination</h2>
      <p>
        We may terminate or suspend access to our website immediately, without
        prior notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms of Service.
      </p>

      <h2>7. Limitation of Liability</h2>
      <p>
        Geostratapp.com shall not be liable for any direct, indirect,
        incidental, special, consequential, or punitive damages resulting from
        your use or inability to use our website.
      </p>

      <h2>8. Changes to Terms</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at
        hello@geostratapp.com.
      </p>
    </Container>
  );
}

export default Terms;
