import { useState } from "react";
import useSWR from "swr";
import { apiClient } from "../../services/apiClient";
import { UserClass } from "../../my-api-client";
import { userFetcher } from "../../utils";
import { InnerUserContext } from "./innerUserContext.tsx";

export default function UserContext(props: any) {
  const [chatLoaded, setChatLoaded] = useState(false);

  // eslint-disable-next-line prefer-const
  let { data, error, isLoading } = useSWR<UserClass, Error>(
    apiClient().allUsersRetrieve.name,
    userFetcher,
    {
      shouldRetryOnError: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  if (error) {
    data = undefined;
    localStorage.removeItem("user");
  } else if (data && !isLoading) {
    localStorage.setItem("user", JSON.stringify(data));
  } else {
    const user = localStorage.getItem("user");
    if (user) {
      data = JSON.parse(user);
    }
  }

  /*useEffect(() => {
                  if (!isLoading) setLoading(false);
                }, [isLoading]);*/

  /*if (!data && isLoading) {
            return <></>;
          }*/

  return (
    <InnerUserContext.Provider
      value={{ user: data as UserClass, isLoading, chatLoaded, setChatLoaded }}
    >
      {props.children}
    </InnerUserContext.Provider>
  );
}
