import { mutate } from "swr";
import { Item, Purchase, UserClass } from "../my-api-client";
import { apiClient } from "../services/apiClient";
import { useContext } from "react";

import { InnerUserContext } from "../components/UserContext/innerUserContext.tsx";

export const useUser = (): UserClass => {
  const { user } = useContext(InnerUserContext);
  return user;
};

export const myMutate = (id: string) => {
  return Promise.all([
    mutate(id.replace("bound ", "")),
    mutate(id),
    mutate("bound " + id),
    mutate(apiClient().allUserNotifsRetrieve.name),
  ]);
};

export const mutateUser = () => {
  return myMutate(apiClient().allUsersRetrieve.name);
};

const getItems = async () => {
  const res = await apiClient().allItemsList();
  return res.data
    ? new Map(res.data.map((item) => [item.id, item]))
    : undefined;
};

let items: Map<number, Item> | undefined;
getItems()
  .then((res) => {
    items = res;
  })
  .catch((_) => {
    // this can happen and ill try once more
    setTimeout(() => {
      getItems()
        .then((res) => {
          items = res;
        })
        .catch((e) => {
          console.error(e);
          throw e;
        });
    }, 3000);
  });

export const mutatePurchase = async (key: string, result: Purchase | void) => {
  if (!items) {
    items = await getItems();
  }
  mutate(apiClient().allUserNotifsRetrieve.name);
  if (result) {
    const item = items?.get(result.itemId);
    if (item) {
      // @ts-ignore
      result.item = item;
    }
    mutate(
      key,
      () => {
        return result;
      },
      {
        populateCache: (updatedPurchase, purchases) => {
          // filter the list, and return it with the updated item
          if (purchases) {
            const filteredPurchases = purchases.filter(
              (purchase: Purchase) => purchase.id !== updatedPurchase.id,
            );
            return [updatedPurchase, ...filteredPurchases];
          } else {
            return [updatedPurchase];
          }
        },
        revalidate: false,
      },
    );
  }
};

export const mutateDeletePurchase = (key: string, result: Purchase | void) => {
  if (result) {
    mutate(
      key,
      () => {
        return result;
      },
      {
        populateCache: (updatedPurchase, purchases) => {
          // filter the list, and return it with the updated item
          if (purchases) {
            const filteredPurchases = purchases.filter(
              (purchase: Purchase) => purchase.id !== updatedPurchase.id,
            );
            return [...filteredPurchases];
          } else {
            return [];
          }
        },
        revalidate: false,
      },
    );
  }
};
