/** @jsxImportSource @emotion/react */
import {
  Box,
  Chip,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Trade, TradeStatusStatusEnum } from "../../../my-api-client";
import { apiClient } from "../../../services/apiClient";
import { permissionService } from "../../../services/permissionService";
import { myMutate, useUser } from "../../../hooks/useUser";
import { css } from "@emotion/react";
import { ReactNode, useState } from "react";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import LoadingScreen from "../../Loading/loadingScreen.tsx";
import GeoIconButton from "../../GeoIconButton";
import SyncAltIcon from "@mui/icons-material/SyncAlt";

interface TradesTableProps {
  trades: Trade[] | undefined;
  incoming: boolean;
  isLoading: boolean;
}

export default function TradesTable({
  trades,
  incoming,
  isLoading,
}: TradesTableProps) {
  const user = useUser();
  const [saving, setSaving] = useState(false);

  const outgoingStatusMap: { [name: string]: string } = {
    CREATED: `Awaiting 2nd approval`,
    SENDER_ACCEPTED: `Approved By: [confirmationUser]
    Offer sent`,
    RECEIVER_ACCEPTED: `Approved By: [confirmationUser]
    Accepted By: [opposingUser]
    `,
    SENDER_REJECTED: `Rejected by: [confirmationUser]`,
    RECEIVER_DECLINED: `Declined by: [opposingUser]`,
    SENDER_CANCELED: `Canceled by: [confirmationUser]`,
    RECEIVER_CANCELED: `Canceled by: [opposingUser]`,
  };

  const incomingStatusMap: { [name: string]: string } = {
    CREATED: "", // this shouldn't happen
    SENDER_ACCEPTED: `Approved By: [confirmationUser]
    Awaiting approval`,
    RECEIVER_ACCEPTED: `Approved By: [confirmationUser]
    Accepted By: [opposingUser]
    `,
    SENDER_REJECTED: `Rejected by: [confirmationUser]`,
    RECEIVER_DECLINED: `Declined by: [opposingUser]`,
    SENDER_CANCELED: `Canceled by: [confirmationUser]`,
    RECEIVER_CANCELED: `Canceled by: [opposingUser]`,
  };

  const getStatus = (row: Trade) => {
    const statusMap = incoming ? incomingStatusMap : outgoingStatusMap;
    return (
      <>
        {statusMap[row.status!]
          .replace("[confirmationUser]", row.confirmationUser || "team")
          .replace("[opposingUser]", row.opposingUser || "team")
          .replace("[proposingUser]", row.proposingUser)}
      </>
    );
  };

  const simpleStatusMap: { [name: string]: ReactNode } = {
    CREATED: <Chip label={"Pending"} color={"info"} />,
    SENDER_ACCEPTED: <Chip label={"Pending"} color={"info"} />,
    RECEIVER_ACCEPTED: <Chip label={"Complete"} color={"success"} />,
    SENDER_REJECTED: <Chip label={"Failed"} color={"default"} />,
    RECEIVER_DECLINED: <Chip label={"Failed"} color={"default"} />,
    SENDER_CANCELED: <Chip label={"Failed"} color={"default"} />,
    RECEIVER_CANCELED: <Chip label={"Failed"} color={"default"} />,
  };

  const doApproveTrade = async (row: Trade) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    apiClient()
      .allTradesSenderAcceptCreate(row.id)
      .then((res) => {
        enqueueSnackbar("Trade Approved", { variant: "success" });
        myMutate(apiClient().allTradesList.name);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error approving trade: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);

        closeSnackbar(snack);
      });
  };

  const doExecuteTrade = async (row: Trade) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    await apiClient()
      .allTradesReceiverAcceptCreate(row.id)
      .then((res) => {
        enqueueSnackbar("Trade Completed", { variant: "success" });
        myMutate(apiClient().allTradesList.name);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar(
          "Error completing trade: " + e.response.data.detail.toString(),
          {
            variant: "error",
          },
        );
      })
      .finally(() => {
        setSaving(false);

        closeSnackbar(snack);
      });
  };

  const doCancelTrade = async (row: Trade) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    await apiClient()
      .allTradesSenderCancelCreate(row.id)
      .then((res) => {
        enqueueSnackbar("Trade Cancelled", { variant: "success" });
        myMutate(apiClient().allTradesList.name);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error cancelling trade: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);

        closeSnackbar(snack);
      });
  };

  const doDeclineTrade = async (row: Trade) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    await apiClient()
      .allTradesSenderDeclineCreate(row.id)
      .then((res) => {
        enqueueSnackbar("Trade Declined", { variant: "success" });
        myMutate(apiClient().allTradesList.name);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error declining trade: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        closeSnackbar(snack);
        setSaving(false);
      });
  };

  const doRejectTrade = async (row: Trade) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    await apiClient()
      .allTradesReceiverDeclineCreate(row.id)
      .then((res) => {
        myMutate(apiClient().allTradesList.name);
        enqueueSnackbar("Trade Rejected", { variant: "success" });
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error rejecting trade: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        closeSnackbar(snack);
        setSaving(false);
      });
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (trades?.length == 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        No trades yet
      </Box>
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nation</TableCell>
              <TableCell sx={{ textAlign: "end" }}>Our Offer</TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                <SyncAltIcon />
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>Their Offer</TableCell>
              <TableCell>Shipping</TableCell>
              <TableCell>Last Update</TableCell>
              <TableCell>Info</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trades &&
              trades.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell data-label={"Nation"}>
                    <Stack
                      gap={1}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        css={css`
                          border: 1px solid black;
                        `}
                        src={
                          incoming
                            ? row.offeringNation.imageUrl
                            : row.receivingNation.imageUrl
                        }
                        width={24}
                        height={16}
                        alt="nation flag"
                      />
                      <Box>
                        {incoming
                          ? row.offeringNation.name
                          : row.receivingNation.name}
                      </Box>
                    </Stack>
                  </TableCell>
                  <TableCell data-label={"Our Resources"}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "end",
                        flexDirection: "column",
                      }}
                    >
                      {(incoming
                        ? row.receivingResources
                        : row.offeringResources
                      ).map((resource) => {
                        return (
                          <div
                            css={css`
                              display: flex;
                            `}
                            key={resource.id}
                          >
                            <img
                              width="20"
                              height="20"
                              alt="image"
                              src={`${import.meta.env.VITE_CDN}${
                                resource.resourceType.icon
                              }`}
                            />
                            &nbsp;{resource.total}
                          </div>
                        );
                      })}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <SyncAltIcon />
                  </TableCell>
                  <TableCell data-label={"Their Resources"}>
                    {(incoming
                      ? row.offeringResources
                      : row.receivingResources
                    ).map((resource) => {
                      return (
                        <div
                          css={css`
                            display: flex;
                          `}
                          key={resource.id}
                        >
                          <img
                            width="20"
                            height="20"
                            alt="image"
                            src={`${import.meta.env.VITE_CDN}${
                              resource.resourceType.icon
                            }`}
                          />
                          &nbsp;{resource.total}
                        </div>
                      );
                    })}
                  </TableCell>
                  <TableCell data-label={"Shipping Cost"}>
                    <div
                      css={css`
                        display: flex;
                      `}
                    >
                      <img
                        width="20"
                        height="20"
                        alt="image"
                        src={`${import.meta.env.VITE_CDN}/images/Oil.png`}
                      />
                      &nbsp;1
                    </div>
                  </TableCell>
                  <TableCell data-label={"Date"}>
                    {new Date(row.updatedAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell
                    data-label={"Status"}
                    sx={{ whiteSpace: "pre-line" }}
                  >
                    <Box>Proposed By: {row.proposingUser}</Box>
                    {getStatus(row)}
                  </TableCell>
                  <TableCell
                    data-label={"Status"}
                    sx={{ whiteSpace: "pre-line" }}
                  >
                    {simpleStatusMap[row.status!]}
                  </TableCell>
                  <TableCell data-label={"Action"}>
                    {row.status === TradeStatusStatusEnum.SenderAccepted &&
                      incoming && (
                        <>
                          <GeoIconButton
                            css={css`
                              margin-right: 15px;
                            `}
                            title={
                              permissionService.canAcceptIncomingTrades(
                                user,
                                row,
                              ).message
                            }
                            disabled={
                              !permissionService.canAcceptIncomingTrades(
                                user,
                                row,
                              ).success || saving
                            }
                            onClick={() => {
                              doExecuteTrade(row);
                            }}
                          >
                            <ThumbUpOffAltIcon />
                          </GeoIconButton>
                          <GeoIconButton
                            title={
                              permissionService.canAcceptIncomingTrades(
                                user,
                                row,
                              ).message
                            }
                            disabled={
                              !permissionService.canAcceptIncomingTrades(
                                user,
                                row,
                              ).success || saving
                            }
                            onClick={() => {
                              doRejectTrade(row);
                            }}
                          >
                            <ThumbDownOffAltIcon />
                          </GeoIconButton>
                        </>
                      )}
                    {row.status === TradeStatusStatusEnum.Created &&
                      !incoming && (
                        <>
                          <GeoIconButton
                            css={css`
                              margin-right: 15px;
                            `}
                            title={
                              permissionService.canApproveOutgoingTrades(
                                user,
                                row,
                              ).message ||
                              "Approving this trade request will sent if to the target nation for approval.  Declining this request will make it void"
                            }
                            disabled={
                              !permissionService.canApproveOutgoingTrades(
                                user,
                                row,
                              ).success || saving
                            }
                            onClick={() => {
                              doApproveTrade(row);
                            }}
                          >
                            <ThumbUpOffAltIcon />
                          </GeoIconButton>
                          <GeoIconButton
                            title={
                              permissionService.canApproveOutgoingTrades(
                                user,
                                row,
                              ).message
                            }
                            disabled={
                              !permissionService.canApproveOutgoingTrades(
                                user,
                                row,
                              ).success || saving
                            }
                            onClick={() => {
                              doDeclineTrade(row);
                            }}
                          >
                            <ThumbDownOffAltIcon />
                          </GeoIconButton>
                        </>
                      )}
                    {row.status === TradeStatusStatusEnum.SenderAccepted &&
                      !incoming && (
                        <>
                          <GeoIconButton
                            title={
                              permissionService.canCancelOutgoingTrades(
                                user,
                                row,
                              ).message
                            }
                            disabled={
                              !permissionService.canCancelOutgoingTrades(
                                user,
                                row,
                              ).success || saving
                            }
                            onClick={() => {
                              doCancelTrade(row);
                            }}
                          >
                            <ThumbDownOffAltIcon />
                          </GeoIconButton>
                        </>
                      )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
