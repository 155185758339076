/** @jsxImportSource @emotion/react */
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import useSWR, { Fetcher } from "swr";
import { Trade as TradeType } from "../../my-api-client";
import { apiClient } from "../../services/apiClient";
import { useUser } from "../../hooks/useUser";
import JoyrideWrapper from "../Joyridewrapper";
import TradeDialog from "./TradeDialog.tsx";
import TradesTable from "./TradesTable";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { useSearchParams } from "react-router-dom";
import GlobalTradesTable from "./TradesTable/global.tsx";

const fetchTrades: Fetcher<TradeType[]> = async () =>
  apiClient()
    .allTradesList()
    .then((res) => res.data);

export default function Trade() {
  const user = useUser();
  const { data: trades, isLoading: tradesLoading } = useSWR(
    apiClient().allTradesList.name,
    fetchTrades,
  );

  // Filter trades based on the selected team
  const { outgoingTrades, incomingTrades, globalTrades } = useMemo(() => {
    if (!user || !trades) return { outgoingTrades: [], incomingTrades: [] };

    return {
      outgoingTrades: trades.filter(
        (trade) => trade.offeringNationId === user.nation.id,
      ),
      incomingTrades: trades.filter(
        (trade) => trade.receivingNationId === user.nation.id,
      ),
      globalTrades: trades.filter(
        (trade) => trade.status === "RECEIVER_ACCEPTED",
      ),
    };
  }, [trades, user]);

  const steps = [
    {
      target: "#new_trade_button",
      content: "Trade resources with other nations",
    },
  ];

  const [queryParams, setQueryParams] = useSearchParams();
  const [value, setValue] = useState("pending");
  useEffect(() => {
    const tab = queryParams.get("tab") || "pending";
    setValue(tab);
  }, [queryParams]);

  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setQueryParams({ tab: newValue });
  };

  return (
    <Container>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="Your Trades" value="pending" />
            <Tab label="Global Trades" value="global" />
          </TabList>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ maxWidth: "100%" }}>
            <TabPanel value="pending">
              <JoyrideWrapper id="trade_ride" steps={steps} />
              <Stack sx={{ maxWidth: "100%" }} gap={3}>
                <Stack gap={1} direction="row" alignItems="center">
                  <Typography variant="h6">Outgoing Trades</Typography>
                  <TradeDialog />
                </Stack>
                <TradesTable
                  trades={outgoingTrades}
                  incoming={false}
                  isLoading={tradesLoading}
                />

                <Typography gutterBottom variant="h6">
                  Incoming Trades
                </Typography>
                <TradesTable
                  trades={incomingTrades}
                  incoming={true}
                  isLoading={tradesLoading}
                />
              </Stack>
            </TabPanel>
            <TabPanel value="global">
              <Stack sx={{ maxWidth: "100%" }} gap={3}>
                <Stack gap={1} direction="row" alignItems="center">
                  <Typography variant="h6">Global Trades</Typography>
                </Stack>
                <GlobalTradesTable
                  trades={globalTrades}
                  isLoading={tradesLoading}
                />
              </Stack>
            </TabPanel>
          </Box>
        </Box>
      </TabContext>
    </Container>
  );
}
