/** @jsxImportSource @emotion/react */
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Stack,
} from "@mui/material";
import { apiClient } from "../../services/apiClient";
import { Purchase } from "../../my-api-client";
import Grid from "@mui/material/Grid2";
import { permissionService } from "../../services/permissionService";
import { mutatePurchase, useUser } from "../../hooks/useUser";
import { ReactElement, useEffect, useState } from "react";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import StoreContent from "./storeContent.tsx";
import GeoButton from "../GeoButton";
import { useNation } from "../../hooks/useNations.ts";
import Typography from "@mui/material/Typography";
import { PurchaseItem } from "../../types/PurchaseItem.ts";

interface ItemInputProps {
  purchase: PurchaseItem;
}

export default function PurchaseCard({ purchase }: ItemInputProps) {
  const [saving, setSaving] = useState(false);
  const user = useUser();
  const [canAfford, setCanAfford] = useState(false);
  const { data } = useNation(user.nation.id);

  useEffect(() => {
    if (data) {
      const result = data.resources?.every((resource) => {
        if (
          // @ts-ignore
          purchase.item.itemPrice[
            resource.resourceType.name.toLowerCase() + "Cost"
          ] == 0
        ) {
          return true;
        }
        if (
          resource.total <
          // @ts-ignore
          purchase.item.itemPrice[
            resource.resourceType.name.toLowerCase() + "Cost"
          ]
        ) {
          return false;
        }
        return true;
      });
      setCanAfford(result);
    } else {
      setCanAfford(false);
    }
  }, [data, purchase]);

  const approve = async (purchase: Purchase) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    apiClient()
      .allPurchasesSenderAcceptCreate(purchase.id)
      .then((res) => {
        enqueueSnackbar("Purchase Approved", { variant: "success" });
        mutatePurchase(apiClient().allPurchasesList.name, res.data);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error approving purchase: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);
        closeSnackbar(snack);
      });
  };

  const statusMap: { [name: string]: ReactElement } = {
    CREATED: <>Proposed, Awaiting Approval</>,
    SENDER_ACCEPTED: <>Approved</>,
    SENDER_REJECTED: <>Rejected</>,
    SENDER_CANCELED: <>Canceled</>,
    PURCHASE_PLACED: <>Build Completed</>,
    PURCHASE_DESTROYED: <>Destroyed</>,
  };

  const decline = (purchase: Purchase) => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    apiClient()
      .allPurchasesSenderDeclineCreate(purchase.id)
      .then((res) => {
        enqueueSnackbar("Purchase Declined", { variant: "success" });
        mutatePurchase(apiClient().allPurchasesList.name, res.data);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error declining trade: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);
        closeSnackbar(snack);
      });
  };
  // @ts-ignore
  const points = purchase?.points;

  return (
    <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={purchase.id}>
      <Card
        sx={{ height: "100%", flexDirection: "column", display: "flex", p: 1 }}
      >
        <CardContent sx={{ flex: 1 }}>
          <Stack spacing={1}>
            <StoreContent item={purchase.item} points={points} />
            <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
              <Typography variant="subtitle2">Status:</Typography>
              <Typography variant="body2">
                {statusMap[purchase.status!]}
              </Typography>
            </Box>
            {purchase.proposingUser && ( // if this is a gift, there is no proposing user
              <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                <Typography variant="subtitle2">Requested by:</Typography>
                <Typography variant="body2">
                  {purchase.proposingUser}
                </Typography>
              </Box>
            )}
            {purchase.confirmationUser && (
              <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                <Typography variant="subtitle2">
                  {purchase.status === "SENDER_CANCELED" ||
                  purchase.status === "SENDER_REJECTED"
                    ? "Declined by:"
                    : "Confirmed by:"}
                </Typography>
                <Typography variant="body2">
                  {purchase.confirmationUser}
                </Typography>
              </Box>
            )}
          </Stack>
        </CardContent>
        {purchase.status === "CREATED" && (
          <CardActions>
            <GeoButton
              width={"100%"}
              variant={"outlined"}
              size="small"
              color="primary"
              title={
                permissionService.canPurchaseItem(user, purchase.item)
                  .message ||
                (!canAfford
                  ? "Not enough resources"
                  : purchase.item.isMilitary
                    ? permissionService.canApproveMilitary(
                        user,
                        purchase.createdBy!,
                      ).message
                    : permissionService.canApproveInfrastructure(
                        user,
                        purchase.createdBy!,
                      ).message ||
                      "Approve this purchase and it will be available for placement on the map.")
              }
              disabled={
                !permissionService.canPurchaseItem(user, purchase.item)
                  .success ||
                saving ||
                !canAfford ||
                (purchase.item.isMilitary
                  ? !permissionService.canApproveMilitary(
                      user,
                      purchase.createdBy!,
                    ).success
                  : !permissionService.canApproveInfrastructure(
                      user,
                      purchase.createdBy!,
                    ).success)
              }
              onClick={() => {
                approve(purchase);
              }}
            >
              Approve{" "}
            </GeoButton>
            <GeoButton
              width={"100%"}
              variant={"outlined"}
              size="small"
              color="primary"
              title={
                purchase.item.isMilitary
                  ? permissionService.canApproveMilitary(
                      user,
                      purchase.createdBy!,
                    ).message
                  : permissionService.canApproveInfrastructure(
                      user,
                      purchase.createdBy!,
                    ).message
              }
              disabled={
                saving ||
                (purchase.item.isMilitary
                  ? !permissionService.canApproveMilitary(
                      user,
                      purchase.createdBy!,
                    ).success
                  : !permissionService.canApproveInfrastructure(
                      user,
                      purchase.createdBy!,
                    ).success)
              }
              onClick={() => {
                decline(purchase);
              }}
            >
              Decline
            </GeoButton>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
}
