import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const host = window.location.hostname;
if (host != "localhost") {
  ReactGA.initialize("G-767H2Q7V9Q");
}

export default function Analytics(props: any) {
  const location = useLocation();

  useEffect(() => {
    const host = window.location.hostname;
    if (host != "localhost") {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
        title: "C: " + document.title,
      }); // The c is for custom.  For some reason this captures more events than the default.
    }
  }, [location]);

  return <>{props.children}</>;
}
