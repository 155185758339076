import useSWR, { Fetcher } from "swr";
import { UserClass } from "../my-api-client";
import { apiClient } from "../services/apiClient";

export const useProfileUser = (id: number): UserClass | undefined => {
  const userFetcher: Fetcher<UserClass> = () => {
    return apiClient()
      .allUsersRetrieve(id)
      .then((res) => {
        return res.data;
      });
  };

  const { data: user } = useSWR<UserClass, Error>(
    apiClient().allUsersRetrieve.name + id,
    userFetcher,
  );

  if (user) {
    return user;
  } else {
    return undefined;
  }
};
