/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";

import NationDropdown from "../NationDropdown";
import { myMutate, useUser } from "../../hooks/useUser";
import useSWR, { Fetcher } from "swr";
import { TradeAlliance, TradeStatusStatusEnum } from "../../my-api-client";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { PermissionResult } from "../../services/permissionService";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import GeoButton from "../GeoButton";
import PostAddIcon from "@mui/icons-material/PostAdd";
import QuillEditor from "../Editor/quill_editor.tsx";

interface DeclarationModalInputProps {
  allianceCreate: (
    tradeAllianceRequest: any,
    options?: AxiosRequestConfig<any> | undefined,
  ) => Promise<AxiosResponse<TradeAlliance, any>>;
  listCall: (
    options?: AxiosRequestConfig<any> | undefined,
  ) => Promise<AxiosResponse<TradeAlliance[], any>>;
  allianceTypeTitle: string;
  allianceTypeText: string;
  allianceTypeId?: string;
  disabled: PermissionResult;
}

export default function DeclarationModal({
  allianceCreate,
  allianceTypeText,
  allianceTypeId,
  disabled,
  listCall,
}: DeclarationModalInputProps) {
  const [saving, setSaving] = useState(false);
  const user = useUser();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [allianceType] = useState("public");
  const [selectedNation, setSelectedNation] = useState<number | null>(null);
  const [declaration, setDeclaration] = useState("");
  const [disabledNations, setDisabledNations] = useState<number[]>([]);

  const handleNationChange = (event: SelectChangeEvent<number>) => {
    setSelectedNation(event.target.value as number);
  };

  const tradeAlliancesFetcher: Fetcher<TradeAlliance[]> = () => {
    return listCall().then((res) => {
      return res.data;
    });
  };

  const { data: alliances } = useSWR<TradeAlliance[], Error>(
    listCall.name.replace("bound ", ""),
    tradeAlliancesFetcher,
  );

  useEffect(() => {
    if (alliances) {
      const existing = alliances.filter(
        (alliance) =>
          (alliance.offeringNationId === user?.nation.id ||
            alliance.receivingNationId === user?.nation.id) &&
          [
            TradeStatusStatusEnum.Created.toString(),
            TradeStatusStatusEnum.SenderAccepted.toString(),
            TradeStatusStatusEnum.ReceiverAccepted.toString(),
          ].indexOf(alliance.status!) > -1,
      );
      if (existing.length > 0) {
        const results: number[] = [];
        existing.forEach((alliance) => {
          results.push(alliance.offeringNationId);
          results.push(alliance.receivingNationId);
        });
        setDisabledNations(results);
      }
    }
  }, [alliances, user?.nation.id]);

  const submitAlliance = () => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    allianceCreate({
      offeringNationId: user?.nation.id || 0,
      receivingNationId: selectedNation,
      declaration,
      isSecret: allianceType === "secret",
    })
      .then(() => {
        enqueueSnackbar("Saved", { variant: "success" });
        myMutate(listCall.name.replace("bound ", ""));
        handleClose();
        setDeclaration("");
        setSelectedNation(null);
      })
      .catch((e) => {
        enqueueSnackbar("Error: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);

        closeSnackbar(snack);
      });
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <PostAddIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Stack gap={1} p={1}>
            <Box>
              {user?.nation.name} would like to {allianceTypeText}
            </Box>
            <NationDropdown
              excluded={disabledNations}
              selected={selectedNation}
              onChange={handleNationChange}
            />

            <Box>with the following declaration</Box>
            <div style={{ height: "300px", position: "relative" }}>
              <QuillEditor
                onChange={setDeclaration}
                value={declaration}
                style={{
                  height: `80%`,
                }}
              />
            </div>
            {allianceTypeId == "subpoena" && (
              <Typography color="warning.main">
                Warning: The courts may issue a fine for frivolous or malicious
                subpoenas.
              </Typography>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          <GeoButton
            title={
              disabled.message ||
              (selectedNation == null
                ? "Must select a nation"
                : declaration.trim().length < 100
                  ? "Declaration must be at least 100 characters"
                  : "")
            }
            disabled={
              !disabled.success ||
              selectedNation == 0 ||
              declaration.trim().length < 100 ||
              saving
            }
            onClick={submitAlliance}
          >
            Sign
          </GeoButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
