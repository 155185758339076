/** @jsxImportSource @emotion/react */
import useSWR, { Fetcher } from "swr";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { TradeAlliance } from "../../my-api-client";
import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
} from "@mui/material";
import NationImage from "../Nation/nationImage";
import { useUser } from "../../hooks/useUser";

import Typography from "@mui/material/Typography";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import ExecuteButton from "./executeButton.tsx";
import DeclarationView from "./declarationView.tsx";
import { ReactNode, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

interface AlliancesInputProps {
  listCall: (
    options?: AxiosRequestConfig<any> | undefined,
  ) => Promise<AxiosResponse<TradeAlliance[], any>>;
  buttonMapSend: any;
  buttonMapReceive: any;
  customStatusMap?: any;
}

export default function Alliances({
  listCall,
  buttonMapSend,
  buttonMapReceive,
  customStatusMap,
}: AlliancesInputProps) {
  const user = useUser();
  const tradeAlliancesFetcher: Fetcher<TradeAlliance[]> = () => {
    return listCall().then((res) => {
      return res.data;
    });
  };
  const [showInactive, setShowInactive] = useState(
    localStorage.getItem("show_inactive") == "true",
  );

  const {
    data: alliances,
    error,
    isLoading,
  } = useSWR<TradeAlliance[], Error>(
    listCall.name.replace("bound ", ""),
    tradeAlliancesFetcher,
  );

  if (error) return <>"Error loading trade alliances."</>;

  const statusMap: { [name: string]: string } = Object.assign(
    {
      CREATED: "Awaiting approval before sending",
      SENDER_ACCEPTED: "Awaiting Acceptance",
      RECEIVER_ACCEPTED: "Signed",
      SENDER_REJECTED: "Rejected by [confirmationUser]",
      RECEIVER_DECLINED: "Declined by [opposingUser]",
      SENDER_CANCELED: "Canceled by [confirmationUser]",
      RECEIVER_CANCELED: "Canceled by [opposingUser]",
      // SENDER_PEACE_PROPOSED: "Awaiting Peace Acceptance",
      // SENDER_PEACE_DECLINED: "Signed on [date]",
      // SENDER_PEACE_ACCEPTED: "Sent",
      // RECEIVER_PEACE_PROPOSED: "Awaiting Peace Acceptance",
      // RECEIVER_PEACE_DECLINED: "Signed on [date]",
      // RECEIVER_PEACE_ACCEPTED: "Sent",
      // SENDER_SURRENDER_PROPOSED: "Awaiting Surrender Acceptance",
      // SENDER_SURRENDER_DECLINED: "Signed on [date]",
      // SENDER_SURRENDER_ACCEPTED: "Surrendered",
      // RECEIVER_SURRENDER_PROPOSED: "Awaiting Surrender Acceptance",
      // RECEIVER_SURRENDER_DECLINED: "Signed on [date]",
      // RECEIVER_SURRENDER_ACCEPTED: "Surrendered",
    },
    customStatusMap || {},
  );

  const getStatusText: { [name: string]: string } = Object.assign(
    {
      CREATED: "Pending",
      SENDER_ACCEPTED: "Pending",
      RECEIVER_ACCEPTED: "Active",
      SENDER_REJECTED: "Inactive",
      RECEIVER_DECLINED: "Inactive",
      SENDER_CANCELED: "Inactive",
      RECEIVER_CANCELED: "Inactive",
    },
    {},
  );

  const simpleStatusMap: { [name: string]: ReactNode } = Object.assign(
    {
      CREATED: <Chip label={"Pending"} color={"info"} />,
      SENDER_ACCEPTED: <Chip label={"Pending"} color={"info"} />,
      RECEIVER_ACCEPTED: <Chip label={"Active"} color={"success"} />,
      SENDER_REJECTED: <Chip label={"Inactive"} color={"default"} />,
      RECEIVER_DECLINED: <Chip label={"Inactive"} color={"default"} />,
      SENDER_CANCELED: <Chip label={"Inactive"} color={"default"} />,
      RECEIVER_CANCELED: <Chip label={"Inactive"} color={"default"} />,
    },
    customStatusMap || {},
  );

  const getStatus = (alliance: TradeAlliance) => {
    return (
      <>
        {(statusMap[alliance.status!] || "")
          .replace("[confirmationUser]", alliance.confirmationUser)
          .replace("[opposingUser]", alliance.opposingUser)}
      </>
    );
  };

  const getSimpleStatus = (alliance: TradeAlliance) => {
    if (statusMap[alliance.status!].startsWith("Delivered")) {
      return (
        <>
          <Chip label={"Delivered"} color={"success"} />
        </>
      );
    }
    return <>{simpleStatusMap[alliance.status!] || ""}</>;
  };

  const handleShowInactiveClick = (_: any) => {
    setShowInactive((showInactive) => !showInactive);
    localStorage.setItem("show_inactive", (!showInactive).toString());
  };

  return (
    <Box sx={{ position: "relative" }}>
      <FormGroup sx={{ position: "absolute", right: 0, top: "-54px" }}>
        <FormControlLabel
          control={
            <Switch
              checked={showInactive}
              onChange={handleShowInactiveClick}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Show Inactive"
        />
      </FormGroup>

      {isLoading && (
        <Box sx={{ pt: 2, pb: 2 }}>
          <LoadingScreen />
          <hr />
        </Box>
      )}
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        {alliances?.length == 0 && <Box sx={{ pt: 4 }}>Nothing yet</Box>}
        {alliances &&
          alliances
            .filter(
              (alliance) =>
                getStatusText[alliance.status!] != "Inactive" || showInactive,
            )
            .sort(
              (a, b) =>
                new Date(b.updatedAt).getMilliseconds() -
                new Date(a.updatedAt).getMilliseconds(),
            )
            .map((item: TradeAlliance) => {
              return (
                <Grid size={{ lg: 4, md: 6, sm: 8, xs: 12 }} key={item.id}>
                  <Card
                    sx={{
                      position: "relative",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    <CardContent sx={{ height: "100%" }}>
                      <Stack gap={2}>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          sx={{ justifyContent: "center", alignItems: "start" }}
                        >
                          <Stack
                            spacing={1}
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                              width: "210px",
                            }}
                          >
                            <NationImage
                              src={item.offeringNation.imageUrl}
                              width={"150"}
                              height={"100"}
                            />
                            <Typography variant={"subtitle2"}>
                              {item.offeringNation.name}
                            </Typography>
                          </Stack>
                          <Stack
                            spacing={1}
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                              width: "210px",
                            }}
                          >
                            <NationImage
                              src={item.receivingNation.imageUrl}
                              width={"150"}
                              height={"100"}
                            />
                            <Typography variant={"subtitle2"}>
                              {item.receivingNation.name}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Divider sx={{ mt: 1, width: "80%", margin: "auto" }} />
                        <Stack
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          direction={"row"}
                          px={2}
                        >
                          <Typography variant={"subtitle2"}>
                            Declaration:
                          </Typography>
                          <DeclarationView item={item} />
                        </Stack>
                        <Stack
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          direction={"row"}
                          px={2}
                        >
                          <Typography variant={"subtitle2"}>
                            Privacy:
                          </Typography>
                          <Typography variant={"body2"}>
                            {item.isSecret ? "Secret" : "Public"}
                          </Typography>
                        </Stack>
                        <Stack
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          direction={"row"}
                          px={2}
                        >
                          <Typography variant={"subtitle2"}>Status:</Typography>
                          <Box>{getSimpleStatus(item)}</Box>
                        </Stack>

                        <Stack
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          direction={"row"}
                          px={2}
                        >
                          <Typography variant={"subtitle2"}>Info:</Typography>
                          <Typography variant={"body2"}>
                            {getStatus(item)}
                          </Typography>
                        </Stack>
                        <Stack
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          direction={"row"}
                          px={2}
                        >
                          <Typography variant={"subtitle2"}>
                            Last Update:
                          </Typography>
                          <Typography variant={"body2"}>
                            {new Date(item.updatedAt).toLocaleDateString()}
                          </Typography>
                        </Stack>

                        <Box></Box>
                      </Stack>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {item.offeringNationId === user?.nation.id &&
                        buttonMapSend(item)[item.status!]?.map((item: any) => {
                          return <ExecuteButton item={item} key={item.text} />;
                        })}
                      {item.receivingNationId === user?.nation.id &&
                        buttonMapReceive(item)[item.status!]?.map(
                          (item: any) => {
                            return (
                              <ExecuteButton item={item} key={item.text} />
                            );
                          },
                        )}
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
      </Grid>
    </Box>
  );
}
