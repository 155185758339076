import { FC, useState } from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import TradeForm from "./TradeForm";
import PostAddIcon from "@mui/icons-material/PostAdd";

const TradeDialog: FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton id={"new_trade_button"} onClick={handleOpen}>
        <PostAddIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <TradeForm handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TradeDialog;
