
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { useUser } from "../../hooks/useUser";
import {useNations} from "../../hooks/useNations.ts";
import NationFlag from "../Flag";

interface NationDropdownProps {
  selected: number | null;
  onChange: any;
  excluded: number[];
}

export default function NationDropdown({
  selected,
  onChange,
  excluded,
}: NationDropdownProps) {
  const data = useUser();
  const { data: nations } = useNations();

  return (
    <>
      <FormControl fullWidth>
        <Select value={selected?.toString() || ""} onChange={onChange} >
          {nations
            ?.filter((nation) => nation.id !== data?.nation.id)
            .map((nation) => {
              return (
                <MenuItem
                  key={nation.id}
                  value={nation.id}
                  disabled={excluded.indexOf(nation.id) > -1}
                >
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <NationFlag nationId={nation.id}/>&nbsp;{nation.name}
                  </Box>

                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </>
  );
}
