import GeoButton from "../GeoButton";
import { useState } from "react";

export default function ExecuteButton(props: any) {
  const item = props.item;
  const [saving, setSaving] = useState(false);

  return (
    <GeoButton
      title={item.title}
      key={item.text}
      disabled={item.disabled || saving}
      variant="outlined"
      onClick={() => {
        item.onClick(setSaving);
      }}
      width={"100%"}
    >
      {item.text}
    </GeoButton>
  );
}
