import NationContent from "./nationContent";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { apiClient } from "../../services/apiClient.ts";
import { NationsStat } from "../../my-api-client";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import { useNation } from "../../hooks/useNations.ts";

export default function Nation() {
  const { id } = useParams();
  const { data, error, isLoading } = useNation(parseInt(id || "0"));

  const { data: stats } = useSWR<NationsStat, Error>(
    apiClient().allNationStatsRetrieve.name,
    function () {
      return apiClient()
        .allNationStatsRetrieve()
        .then((res) => {
          return res.data;
        });
    },
  );

  const statDict = {};
  for (const stat of stats?.nations || []) {
    // @ts-ignore
    statDict[stat.id] = stat;
  }

  if (error) return "Error loading nation.";

  if (!data || isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <NationContent
        key={id}
        index={0}
        nation={data}
        stats={
          // @ts-ignore
          statDict[id] || {}
        }
      />
    </div>
  );
}
