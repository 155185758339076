/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useSWR from "swr";
import NewArticle from "./newArticle";
import { Article } from "../../my-api-client";
import { apiClient } from "../../services/apiClient";
import ArticleComponent from "./Article";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useUser } from "../../hooks/useUser.ts";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import JoyrideWrapper from "../Joyridewrapper";
import { useNavigate } from "react-router-dom";

export default function News() {
  const user = useUser();

  const navigate = useNavigate();

  // @ts-ignore
  window.handleAClick = () => {
    navigate("/app/getting_started");
    return false;
  };

  const demoArticle: Article = {
    id: 0,
    title: "Welcome to GeoStrat!",
    content: `You're currently viewing a sandbox environment that you can explore and use for testing purposes.  Feel free to take a look around.
    
When you're ready to setup your first class there is a <a style="color: #2f2f2f" href="#" onClick={window.handleAClick()}>Getting Started</a> guide available in the user menu. If you have any questions or need help, please feel free to reach out using at <a style="color: #2f2f2f" href="mailto:hello@geostratapp.com">hello@geostratapp.com</a>.`,

    createdAt: new Date().toString(),
    createdBy: {
      id: 1,
      fullName: "GeoStrat",
      imageUrl: "",
      nation: null,
    },
    comments: [],
    classNameId: 1,
  };

  const { data, error, isLoading } = useSWR<Article[], Error>(
    apiClient().allArticlesList.name,
    function () {
      return apiClient()
        .allArticlesList()
        .then((res) => {
          return res.data;
        });
    },
  );

  function groupByDay(items: Article[]): Article[][] {
    const grouped: { [key: string]: Article[] } = {};

    items.forEach((item) => {
      const date = new Date(item.createdAt); // Convert timestamp to Date object
      const day = date.toISOString().split("T")[0]; // Extract YYYY-MM-DD part

      // Group by the date
      if (!grouped[day]) {
        grouped[day] = [];
      }
      grouped[day].push(item);
    });

    return Object.values(grouped);
  }

  function formatTimestamp(timestamp: string): string {
    const date = new Date(timestamp);

    // Array of weekdays and months for easy lookup
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the day of the week, month, day of the month, and year
    const weekday = weekdays[date.getUTCDay()]; // Get day of the week (e.g., "Monday")
    const month = months[date.getUTCMonth()]; // Get month (e.g., "August")
    const dayOfMonth = date.getUTCDate(); // Get the day of the month (e.g., 30)
    const year = date.getUTCFullYear(); // Get the year (e.g., 1978)

    // Format the date into the desired string
    return `${weekday} ${month} ${dayOfMonth}, ${year}`;
  }

  // Group items by day
  const groupedItems = groupByDay(data || []);

  if (error) return "Error loading posts.";

  const steps = [
    {
      target: "#header",
      content:
        "Welcome to GeoStrat News! On this page news articles will be written based off of actions taken in this world. ",
    },
  ];

  if (user.isAdmin) {
    steps.push({
      target: ".admin-menu",
      content:
        "Have multiple classes?  You can use this menu to switch between different classes and view the site as different nations.",
    });
    steps.push({
      target: "#account-menu-icon",
      content:
        'In this user menu you can find additional options.  Most importantly the "Admin" menu where you can add classes and users',
    });
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        margin-top: 20px;
        padding-bottom: 20px;
        padding-left: 5px;
        padding-right: 5px;
      `}
    >
      <JoyrideWrapper
        id={"news_ride"}
        steps={steps}
        styles={{
          options: {
            zIndex: 1200,
          },
        }}
      />
      <div
        css={css`
          max-width: 100%;
          width: 800px;
          display: flex;
          flex-direction: column;
        `}
      >
        <Box>
          <NewArticle ogTitle="" ogContent="" />
        </Box>

        {isLoading && <LoadingScreen fullScreen />}

        <Box sx={{ textAlign: "center" }} id={"header"}>
          <header
            css={css`
              font-family: "Playfair Display", serif;
              font-weight: 900;
              font-size: 80px;
              text-transform: uppercase;
              display: inline-block;
              line-height: 72px;
              margin-bottom: 20px;
              @media (max-width: 600px) {
                font-size: 50px;
              }
            `}
          >
            GeoStrat News
          </header>
        </Box>

        {data && groupedItems && (
          <>
            <Typography
              component="h1"
              variant="body1"
              css={css`
                text-align: center;
                margin-top: 20px;
              `}
            >
              {data.length === 0 &&
                "This is a brand new world! It looks like there is nothing to report on yet but I'm sure I'll dig up something soon"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {groupedItems.map((group) => {
                return (
                  <Box key={group[0].id} sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          textTransform: "uppercase",
                          borderBottom: "2px solid #2f2f2f",
                          borderTop: "2px solid #2f2f2f",
                          padding: "12px 0 12px 0",
                        }}
                      >
                        {formatTimestamp(group[0].createdAt)}
                      </Box>
                    </Box>
                    {group.map((article) => {
                      return (
                        <ArticleComponent article={article} key={article.id} />
                      );
                    })}
                  </Box>
                );
              })}
              {user.isAdmin && user.className.isDemoClass && (
                <>
                  <ArticleComponent
                    article={demoArticle}
                    key={demoArticle.id}
                  />{" "}
                  <Box mb={10}></Box>
                </>
              )}
            </Box>
          </>
        )}
      </div>
    </div>
  );
}
