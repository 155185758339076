/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useSWR from "swr";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { mutateUser, myMutate, useUser } from "../../hooks/useUser";
import { AdminNations } from "../../my-api-client";
import { adminApiClient, apiClient } from "../../services/apiClient";
import { permissionService } from "../../services/permissionService";
import GeoTooltip from "../GeoTooltip";

interface AdminHeaderProps {
  menuOpen: boolean;
}

export default function AdminHeader({ menuOpen }: AdminHeaderProps) {
  const [saving, setSaving] = useState(false);
  const user = useUser();
  const [adminOn, setAdminOn] = useState(permissionService.getAdminOn());
  const [className, setClassName] = useState(user.className.id);
  const [nation, setNation] = useState(user.nation.id);
  const [classNations, setClassNations] = useState<AdminNations[]>([]);

  const { data: nations } = useSWR(
    adminApiClient().drfadminNationsList.name,
    () =>
      adminApiClient()
        .drfadminNationsList()
        .then((res) => res.data),
  );

  const { data: classes } = useSWR(
    adminApiClient().drfadminClassesList.name,
    () =>
      adminApiClient()
        .drfadminClassesList()
        .then((res) => res.data),
  );

  useEffect(() => {
    const updated =
      nations?.results?.filter((n) => n.className === className) || [];
    setClassNations(updated);
    // if nation is not in updated set it to the world bank
    if (updated.length && !updated.some((n) => n.id === nation)) {
      setNation(
        nations?.results?.find((n) => n.className == className && n.isWorldBank)
          ?.id || 0,
      );
    }
  }, [className, nation, nations]);

  const updateClass = (event: SelectChangeEvent) => {
    setClassName(parseInt(event.target.value));
  };

  const updateUser = async () => {
    if (!user) return;

    const snack = enqueueSnackbar(
      <>
        Saving <CircularProgress size={20} />
      </>,
      { variant: "info", persist: true },
    );
    setSaving(true);

    try {
      await adminApiClient().drfadminUsersPartialUpdate(user.id, {
        className,
        nation,
      });
      mutateUser();
      myMutate(apiClient().allTradesList.name);
      enqueueSnackbar("Admin options updated", { variant: "success" });
      location.reload();
    } catch (e) {
      enqueueSnackbar(`Error updating admin options: ${e}`, {
        variant: "error",
      });
    } finally {
      setSaving(false);
      closeSnackbar(snack);
    }
  };

  if (!classNations.length) {
    return <></>;
  }

  return menuOpen ? (
    <div
      css={css`
        color: red;
        background: lightgray;
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        position: fixed;
        z-index: 2;
      `}
    >
      <FormControl sx={{ marginLeft: "10px" }}>
        <InputLabel>Class Select</InputLabel>
        <Select
          label="Class Select"
          value={className.toString()}
          onChange={updateClass}
        >
          {classes?.results?.map((cls) => (
            <MenuItem key={cls.id} value={cls.id}>
              {cls.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ marginLeft: "15px" }}>
        <InputLabel>Nation Select</InputLabel>
        <Select
          label="Nation Select"
          value={nation.toString()}
          onChange={(e) => setNation(parseInt(e.target.value))}
        >
          {classNations.map((nat) => (
            <MenuItem key={nat.id} value={nat.id}>
              {nat.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {(user.nation.id !== nation || user.className.id !== className) && (
        <Button
          sx={{ marginLeft: "15px" }}
          variant="contained"
          onClick={updateUser}
          disabled={saving || !classNations.some((n) => n.id === nation)}
        >
          Save
        </Button>
      )}

      <FormGroup sx={{ marginLeft: "15px" }}>
        <FormControlLabel
          control={
            <Switch
              checked={!adminOn}
              onChange={() => {
                setAdminOn(!adminOn);
                permissionService.setAdminOn(!adminOn);
              }}
            />
          }
          label="Disable Admin"
        />
      </FormGroup>
      <GeoTooltip title="As an admin you often have options which may not be available to other users. This switch will disable those options so you can view the site as a regular user." />
    </div>
  ) : null;
}
