import Nations from "../../components/Nations";


function NationsPage() {
  return (
    <>
      <Nations />
    </>
  );
}

export default NationsPage;
