import useSWR, { SWRResponse } from "swr";
import { Notif } from "../my-api-client";
import { apiClient } from "../services/apiClient";

export const useNotifs = (): SWRResponse<Notif, Error> => {
  const notifFetcher = () => {
    return apiClient()
      .allUserNotifsRetrieve()
      .then((res) => {
        return res.data;
      });
  };

  return useSWR(apiClient().allUserNotifsRetrieve.name, notifFetcher);
};
