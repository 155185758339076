/** @jsxImportSource @emotion/react */
import { apiClient } from "../../services/apiClient";
import Alliances from "./alliances";
import { Stack } from "@mui/material";

import AllianceModal from "./allianceModal";
import { useUser } from "../../hooks/useUser";
import { permissionService } from "../../services/permissionService";
import { getButtonReceive, getButtonSend } from "./utils";

export default function MilitaryAlliances() {
  const user = useUser();
  return (
    <Stack
      sx={{
        textAlign: "center",
      }}
      spacing={2}
    >
      <AllianceModal
        listCall={apiClient().allMilitaryAlliancesList.bind(apiClient())}
        allianceCreate={apiClient().allMilitaryAlliancesCreate.bind(
          apiClient(),
        )}
        allianceTypeTitle={"Military"}
        disabled={permissionService.canInitiateMilitaryAlliance(user)}
      />
      <Alliances
        listCall={apiClient().allMilitaryAlliancesList.bind(apiClient())}
        buttonMapSend={getButtonSend(
          user,
          "Military Alliance",
          "MilitaryAlliance",
        )}
        buttonMapReceive={getButtonReceive(
          user,
          "Military Alliance",
          "MilitaryAlliance",
        )}
      ></Alliances>
    </Stack>
  );
}
