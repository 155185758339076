import React, { useState } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import {
  LocalHospital,
  School,
  Home,
  People,
  Security,
  Business,
  LibraryBooks,
  Opacity,
} from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import { Nation, NationStat } from "../../my-api-client";
import GeoWrapperTooltip from "../GeoWrapperTooltip";
import { permissionService } from "../../services/permissionService.ts";
import PopulationChange from "./populationChange.tsx";
import GeoButton from "../GeoButton";
import { useUser } from "../../hooks/useUser.ts";

interface NationStatsProps {
  stats?: NationStat;
  nation: Nation;
}

const iconMap: { [id: string]: any } = {
  Healthcare: <LocalHospital />,
  Education: <School />,
  Housing: <Home />,
  "Foreign Relations": <People />,
  Defense: <Security />,
  Buildings: <Business />,
  "Human Resources": <LibraryBooks />,
  "Water Supply": <Opacity />,
  Population: <People />,
};

const NationStats: React.FC<NationStatsProps> = ({ stats, nation }) => {
  const user = useUser();
  const [open, setOpen] = useState(false);
  const housingStat = stats?.stats?.find((stat) => stat.name === "Housing");

  return (
    <Grid container spacing={2}>
      {stats?.stats?.sort()?.map((stat, index) => (
        <Grid key={index} size={{ xs: 12, sm: 6, md: 4 }}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
                <Box sx={{ mr: 2 }}>{iconMap[stat.name]}</Box>
                <Box>
                  {permissionService.isAdmin(user).success &&
                  stat.name === "Population" ? (
                    <>
                      <PopulationChange
                        nationId={nation.id}
                        open={open}
                        setOpen={setOpen}
                        population={stat}
                        housing={housingStat!}
                      />

                      <GeoButton
                        id={"population_stat_" + nation.id}
                        sx={{ padding: 0, textDecoration: "underline" }}
                        variant={"text"}
                        onClick={() => {
                          setOpen(true);
                        }}
                        title={stat.description}
                      >
                        <Typography variant="h6">{stat.name}</Typography>
                      </GeoButton>
                    </>
                  ) : (
                    <GeoWrapperTooltip title={stat.description} placement="top">
                      <Typography variant="h6">{stat.name}</Typography>
                    </GeoWrapperTooltip>
                  )}
                  <Typography variant="body1">{stat.displayValue}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default NationStats;
