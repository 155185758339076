/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { TextField } from "@mui/material";
import { Resource } from "../../my-api-client";

interface ResourceInputProps {
  resource: Resource;
  register: any;
  name: string;
}

export default function ResourceInput({
  resource,
  register,
  name,
}: ResourceInputProps) {
  const [value] = useState("0");

  return (
    <TextField
      sx={{
        height: 60,
        width: "calc(50% - 26px)",
      }}
      {...register(`${name}.${resource.resourceType.id}`)}
      label={`${resource.resourceType.name} ${
        resource.total - parseInt(value)
      }`}
      variant="outlined"
      type="number"
      defaultValue="0"
      InputProps={{
        inputProps: {
          max: Math.max(0, resource.total),
          min: 0,
        },
      }}
    />
  );
}
