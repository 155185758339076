import useSWR, { Fetcher, SWRResponse } from "swr";
import { Item } from "../my-api-client";
import { apiClient } from "../services/apiClient";

export const useItems = (): SWRResponse<Item[], Error> => {
  const itemsFetcher: Fetcher<Item[]> = () => {
    return apiClient()
      .allItemsList()
      .then((res) => {
        return res.data;
      });
  };

  return useSWR<Item[], Error>(apiClient().allItemsList.name, itemsFetcher, {
    revalidateIfStale: false,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: 0,
  });
};
