/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { SyncAlt } from "@mui/icons-material";
import {
  Nations,
  TradeAlliance,
  MilitaryAlliance,
  TradeAllianceStatusEnum,
} from "../../my-api-client";
import { FC } from "react";

interface AlliancesCardProps {
  alliances: TradeAlliance[] | MilitaryAlliance[] | undefined;
  name: string;
  nation: Nations;
}

const AlliancesCard: FC<AlliancesCardProps> = ({ name, alliances, nation }) => {
  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1}>
        <SyncAlt />
        <Typography variant="subtitle2">{name} Alliances</Typography>
      </Box>
      <Stack gap={2} p={2}>
        {alliances
          ?.filter((n) => n.status == TradeAllianceStatusEnum.ReceiverAccepted)
          ?.map((alliance) => (
            <Box key={alliance.id}>
              {alliance.offeringNationId === nation.id && (
                <Box display="flex" alignItems="center">
                  {alliance.receivingNation.imageUrl && (
                    <img
                      src={alliance.receivingNation.imageUrl}
                      css={css`
                        border: 1px solid black;
                        margin-right: 10px;
                      `}
                      width={27}
                      height={18}
                      alt="nation flag"
                      title={alliance.receivingNation.name}
                    />
                  )}
                  {alliance.receivingNation.name}
                </Box>
              )}
              {alliance.receivingNationId === nation.id && (
                <Box display="flex" alignItems="center">
                  {alliance.offeringNation.imageUrl && (
                    <img
                      src={alliance.offeringNation.imageUrl}
                      css={css`
                        border: 1px solid black;
                        margin-right: 10px;
                      `}
                      width={27}
                      height={18}
                      alt="nation flag"
                      title={alliance.offeringNation.name}
                    />
                  )}
                  {alliance.offeringNation.name}
                </Box>
              )}
            </Box>
          ))}
      </Stack>
    </Box>
  );
};

export default AlliancesCard;
