import { Link as MuiLink, LinkProps } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";

import {ForwardedRef, forwardRef} from "react";

// add disabled prop to LinkProps
export interface LinkProps2 extends LinkProps {
  disabled?: boolean;
  href?: string;
}
const Link = forwardRef((props: LinkProps2, ref: ForwardedRef<any>) => {
  return (
    <MuiLink
      sx={{
        textDecoration: "none",
        cursor: props.disabled ? "default" : "pointer",
      }}
      onClick={(event) => props.disabled && event.preventDefault()}
      {...props}
      component={ReactRouterLink}
      to={props.href ?? "#"}
      ref={ref}
    />
  );
});

export default Link;
