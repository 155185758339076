/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useNations } from "../../hooks/useNations.ts";
import { FC, ImgHTMLAttributes } from "react";

interface ImageComponentProps extends ImgHTMLAttributes<HTMLImageElement> {
  nationId?: number | null;
}

const NationFlag: FC<ImageComponentProps> = ({ nationId, ...props }) => {
  const { data } = useNations();
  const nation = data?.find((nation) => nation.id === nationId);

  if (!nation || !nation.imageUrl) {
    return null;
  }

  return (
    <img
      src={nation.imageUrl}
      css={css`
        margin-left: 10px;
        border: 1px solid black;
      `}
      width={27}
      height={18}
      alt="nation flag"
      title={nation.name}
      {...props}
    />
  );
};

export default NationFlag;
