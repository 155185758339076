/** @jsxImportSource @emotion/react */
import { Box, Stack } from "@mui/material";

import { css } from "@emotion/react";
import { Item } from "../../my-api-client";
import Cost from "./cost.tsx";
import Typography from "@mui/material/Typography";

interface ItemInputProps {
  item: Item;
  points?: string;
}

export default function StoreContent({ item, points }: ItemInputProps) {
  const placements = [];
  item.isLand &&
    placements.push("/temp2/tile_05.png") &&
    placements.push("/temp2/tile_06.png") &&
    placements.push("/temp2/tile_10.png") &&
    placements.push("/temp2/tile_09.png") &&
    placements.push("/temp2/tile_03.png");
  item.isOcean &&
    placements.push("/temp2/tile_11.png") &&
    placements.push("/temp2/tile_12.png") &&
    placements.push("/temp2/tile_13.png") &&
    placements.push("/temp2/tile_14.png");
  item.isWell && placements.push("/temp2/tile_09.png");
  item.isOceanOil && placements.push("/temp2/tile_14.png");
  item.isFish && placements.push("/temp2/tile_13.png");
  item.isMineral && placements.push("/temp2/tile_10.png");
  item.isLumber && placements.push("/temp2/tile_06.png");
  item.isAg && placements.push("/temp2/tile_05.png");
  item.isRiver &&
    placements.push("/temp2/tile_05_1.png") &&
    placements.push("/temp2/tile_05_2.png") &&
    placements.push("/temp2/tile_05_3.png") &&
    placements.push("/temp2/tile_05_4.png");
  item.isCity &&
    placements.push("/temp2/tile_05.png") &&
    placements.push("/temp2/tile_03.png");
  item.isLandOil && placements.push("/temp2/tile_03.png");
  item.isShoreline && placements.push("/temp2/tile_12.png");

  // if (item.name.includes("Jet")) {
  //   placements.push("/temp2/tile_05.png") &&
  //   placements.push("/temp2/tile_06.png") &&
  //   placements.push("/temp2/tile_10.png") &&
  //   placements.push("/temp2/tile_09.png") &&
  //   placements.push("/temp2/tile_03.png") &&
  //   placements.push("/temp2/tile_11.png") &&
  //   placements.push("/temp2/tile_12.png") &&
  //   placements.push("/temp2/tile_13.png") &&
  //   placements.push("/temp2/tile_14.png") &&
  //   placements.push("/temp2/tile_09.png") &&
  //   placements.push("/temp2/tile_14.png") &&
  //   placements.push("/temp2/tile_13.png") &&
  //   placements.push("/temp2/tile_10.png") &&
  //   placements.push("/temp2/tile_06.png") &&
  //   placements.push("/temp2/tile_05_1.png") && placements.push("/temp2/tile_05_2.png") && placements.push("/temp2/tile_05_3.png") && placements.push("/temp2/tile_05_4.png") &&
  //   placements.push("/temp2/tile_03.png") &&
  //   placements.push("/temp2/tile_03.png") &&
  //   placements.push("/temp2/tile_12.png");
  // }

  return (
    <Stack gap={1}>
      <Box sx={{ display: "flex", alignItems: "end" }} gap={1}>
        <img height="32" width={32} src={item.image || ""} alt="" />
        <Typography variant="h6">{item.name}</Typography>
      </Box>
      <Typography variant="body2">{item.description}</Typography>
      {item.housing ? (
        <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
          <Typography variant="subtitle2">Max Population:</Typography>
          <Typography variant="body2">
            ~{(item.housing * 0.95).toLocaleString()}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
      {item.name == "City" ? (
        <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
          <Typography variant="subtitle2">Resource Multiplier:</Typography>
          <Typography variant="body2">2x Total</Typography>
        </Box>
      ) : (
        <></>
      )}
      {item.name == "Megalopolis" ? (
        <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
          <Typography variant="subtitle2">Resource Multiplier:</Typography>
          <Typography variant="body2">2.5x Total</Typography>
        </Box>
      ) : (
        <></>
      )}
      <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
        <Typography variant="subtitle2">Placement:</Typography>
        <Typography variant="body2" sx={{ display: "flex", flexWrap: "wrap" }}>
          {placements.length == 0 ? " Anywhere" : ""}
          {placements.map((tile, index) => (
            <img
              src={tile}
              width={24}
              height={24}
              key={index}
              css={css`
                margin-left: 4px;
                margin-right: 4px;
              `}
            />
          ))}
        </Typography>
      </Box>
      {(points == undefined ? item.points : points) ? (
        <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
          <Typography variant="subtitle2">Power Points:</Typography>
          <Typography variant="body2">
            {points == undefined ? item.points : points}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
      <Cost item={item} costKey={"cost"} title="Development Cost" />
      <Cost
        item={item}
        costKey={"dailyCost"}
        title="Daily Cost"
        id={"daily_cost"}
      />
      {item.dice ? (
        <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
          <Typography variant="subtitle2">Strength:</Typography>
          <Typography variant="body2">{item.dice}</Typography>
        </Box>
      ) : (
        <></>
      )}
      {item.movementRange ? (
        <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
          <Typography variant="subtitle2">Movement Speed:</Typography>
          <Typography variant="body2">{item.movementRange}</Typography>
        </Box>
      ) : (
        <></>
      )}
    </Stack>
  );
}
