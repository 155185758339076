import { Tooltip, useTheme, IconButton } from "@mui/material";


// A button with a tooltip
export default function GeoIconButton({ title, children, ...props }: any) {
  const theme = useTheme();

  return (
    <Tooltip enterTouchDelay={0} title={title}>
      <span style={{ display: "inline-block", cursor: "pointer" }}>
        <IconButton color="primary" variant={"outlined"}
                    sx={{
                        height: "100%",
                        "&.Mui-disabled": {
                            color: theme.palette.primary.main,
                        },
                    }}
                    {...props}>
          {children}
        </IconButton>
      </span>
    </Tooltip>
  );
}
