import WelcomePage from "../components/Waitlist";
import { useUser } from "../hooks/useUser.ts";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Landing() {
  const user = useUser();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  useEffect(() => {
    if (user && location.pathname === "/") {
      navigate("/app/news"); // Redirect to app
    }
  }, [location.pathname, navigate, user]);

  return <WelcomePage />;
}
