import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { apiClient } from "../../services/apiClient.ts";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";

const SuggestionsPage = () => {
  const [inputValue, setInputValue] = useState("");
  const [sent, setSent] = useState(false);

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    const snack = enqueueSnackbar(
      <>
        Sending&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );

    apiClient()
      .allSuggestionsCreate({ suggestion: inputValue })
      .then((res) => {
        enqueueSnackbar("Suggestion Sent", { variant: "success" });
        setSent(true);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error sending suggestion: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        closeSnackbar(snack);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > :not(style)": { m: 1 }, // spacing
      }}
    >
      Please share ideas for features or fixes you'd like to see in the app.
      <TextField
        rows={4}
        multiline
        label=""
        sx={{ width: "100%", maxWidth: "500px" }}
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
      />
      {sent && <p>Thanks for the suggestion!</p>}
      <Button variant="contained" onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
};

export default SuggestionsPage;
