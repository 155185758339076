/** @jsxImportSource @emotion/react */
import NationContent from "../Nation/nationContent";
import useSWR from "swr";
import { apiClient } from "../../services/apiClient";
import { NationsStat } from "../../my-api-client";
import LoadingScreen from "../Loading/loadingScreen.tsx";

import { useNations } from "../../hooks/useNations.ts";
import { SyntheticEvent, useEffect, useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Box, Container, Tab } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import { useSearchParams } from "react-router-dom";

export default function Nations() {
  const { data, error } = useNations();
  const [value, setValue] = useState("0");
  const [queryParams, setQueryParams] = useSearchParams();

  useEffect(() => {
    const tab = queryParams.get("tab") || "0";
    // @ts-ignore
    setValue(tab);
  }, [queryParams]);

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setQueryParams({ tab: newValue });
  };

  const { data: stats } = useSWR<NationsStat, Error>(
    apiClient().allNationStatsRetrieve.name,
    function () {
      return apiClient()
        .allNationStatsRetrieve()
        .then((res) => {
          return res.data;
        });
    },
  );

  const statDict = {};
  for (const stat of stats?.nations || []) {
    // @ts-ignore
    statDict[stat.id] = stat;
  }

  if (error) return "Error loading nations.";

  if (!data) {
    return <LoadingScreen fullScreen={true} />;
  }

  return (
    <Container>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {data
              .filter((nation) => nation.name != "World Bank")
              .map((nation, index) => (
                <Tab
                  label={nation.name}
                  value={index.toString()}
                  key={index.toString()}
                />
              ))}
          </TabList>
        </Box>
        {data
          .filter((nation) => nation.name != "World Bank")
          .map((nation, index) => (
            <TabPanel value={index.toString()} key={index.toString()}>
              <NationContent
                index={index}
                nation={nation}
                stats={
                  // @ts-ignore
                  statDict[nation.id] || {}
                }
              />
            </TabPanel>
          ))}
      </TabContext>
    </Container>
  );
}
