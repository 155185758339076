/** @jsxImportSource @emotion/react */
import { PurchaseStatusEnum } from "../../my-api-client";
import Grid from "@mui/material/Grid2";
import { css } from "@emotion/react";
import StoreItem from "./storeItem";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import { useUser } from "../../hooks/useUser.ts";
import JoyrideWrapper from "../Joyridewrapper";
import { useItems } from "../../hooks/useItems.ts";
import { usePurchases } from "../../hooks/usePurchases.ts";

export default function Development() {
  const user = useUser();

  const { data: items2, error } = useItems();

  const { data: purchases2, error: error2, isLoading } = usePurchases();

  if (error || error2) return "Error loading nations.";

  if (!items2 || isLoading) {
    return <LoadingScreen fullScreen />;
  }

  const items = items2?.filter((item) => !item.isMilitary);

  const purchases = purchases2?.filter(
    (item) =>
      item.controlledBy.id == user.nation.id &&
      (item.status == PurchaseStatusEnum.PurchasePlaced ||
        item.status == PurchaseStatusEnum.Created ||
        item.status == PurchaseStatusEnum.SenderAccepted),
  );

  // this is kind of a jank way to show powerpoints properly
  // Create a map to count purchases for each item id
  const purchaseCountMap = purchases?.reduce(
    (acc, purchase) => {
      acc[purchase.itemId] = (acc[purchase.itemId] || 0) + 1;
      return acc;
    },
    {} as Record<number, number>,
  );

  // Adjust item powerpoints based on purchase count
  const adjustedItems = items?.map((item) => {
    const purchaseCount = purchaseCountMap?.[item.id] || 0;
    return {
      ...item,
      points: Math.max(item.points - purchaseCount, 0),
    };
  });

  const steps = [
    {
      target: ".infrastructure_header",
      content:
        "You can use resources to purchase various items to develop your nation.",
    },
    {
      target: ".military_header",
      content:
        "Once you have a military base you can also purchase military items.",
    },
    {
      target: ".pending_header",
      content:
        "Once a member of your nation purchases an item it still needs to be approved.",
    },
    {
      target: "#placement",
      content: "Items may only be placed on specific titles",
    },
    {
      target: "#power_points",
      content:
        "Each item will give a different amount of power points.  This number decreases for each additional asset owned of the same type",
    },
    {
      target: "#daily_cost",
      content:
        "In addition to the development cost some items include a cost which will be deducted from your resources 2 days a week.",
    },
  ];
  if (user.isAdmin) {
    steps.push({
      target: "#gift",
      content:
        "As an admin you can gift an item which will allow the nation to place it on the map immediately",
    });
  }

  return (
    <>
      <JoyrideWrapper id={"development_ride"} steps={steps} />

      <Grid
        container
        spacing={1}
        css={css`
          margin: 10px;
        `}
      >
        {adjustedItems?.map((item) => {
          return <StoreItem item={item} key={item.id} />;
        })}
      </Grid>
    </>
  );
}
