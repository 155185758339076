/** @jsxImportSource @emotion/react */
import { apiClient } from "../../services/apiClient";
import Alliances from "./alliances";
import { Stack } from "@mui/material";

import DeclarationModal from "./declarationModal";
import { useUser } from "../../hooks/useUser";
import { permissionService } from "../../services/permissionService";
import { getButtonSend } from "./utils";
import { TradeAlliance } from "../../my-api-client";

export default function WarDeclarations() {
  const user = useUser();
  return (
    <Stack
      sx={{
        textAlign: "center",
      }}
      spacing={2}
    >
      <DeclarationModal
        listCall={apiClient().allWarsList.bind(apiClient())}
        allianceCreate={apiClient().allWarsCreate.bind(apiClient())}
        allianceTypeTitle={"Declare War"}
        allianceTypeText={"declare war against"}
        disabled={permissionService.canInitiateWar(user)}
      />
      <Alliances
        listCall={apiClient().allWarsList.bind(apiClient())}
        buttonMapSend={getButtonSend(user, "War", "War")}
        buttonMapReceive={(_alliance: TradeAlliance) => {
          return {};
        }}
        customStatusMap={{
          SENDER_ACCEPTED: "Delivered",
        }}
      ></Alliances>
    </Stack>
  );
}
