import { CSSProperties } from "react";

interface NationImageProps {
  src: string | undefined | null;
  style?: CSSProperties;
  width?: string;
  height?: string;
  css?: any;
}

export default function NationImage({ src, ...props }: NationImageProps) {
  return (
    src && (
      <img
        alt="flag"
        src={src || ""}
        width="75"
        height={"50"}
        style={{ border: "1px solid black" }}
        {...props}
      />
    )
  );
}
