/** @jsxImportSource @emotion/react */
import { TradeAlliance } from "../../my-api-client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import Typography from "@mui/material/Typography";
import QuillViewer from "../Editor/QuillViewer.tsx";
import { useState } from "react";

interface DeclarationViewProps {
  item: TradeAlliance;
}

export default function DeclarationView({ item }: DeclarationViewProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        sx={{ padding: 0 }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <ReceiptLongOutlinedIcon />
        <Typography variant={"body2"}>View Document</Typography>
      </Button>
      <Dialog
        open={open}
        fullWidth
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Declaration</DialogTitle>
        <DialogContent>
          <QuillViewer value={item.declaration || ""} />

          {/* Signature Area */}
          <Box sx={{ mt: 4, borderTop: 1, borderColor: "divider", pt: 2 }}>
            <Typography variant="body1" component="div" sx={{ mb: 2 }}>
              <strong>Signatures:</strong>
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box>
                  Proposed By:{" "}
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Cedarville Cursive",
                      mb: 1,
                      display: "inline",
                    }}
                  >
                    {item.proposingUser}
                  </Typography>
                </Box>

                <Box>
                  Approved By:{" "}
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Cedarville Cursive",
                      mb: 1,
                      display: "inline",
                    }}
                  >
                    {item.status != "SENDER_REJECTED" && item.confirmationUser}
                  </Typography>
                </Box>
                {item.opposingUser && (
                  <Box>
                    Accepted By:{" "}
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Cedarville Cursive",
                        display: "inline",
                      }}
                    >
                      {item.opposingUser}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
