import Diplomacy from "../components/Diplomacy";


function DiplomacyPage() {
  return (
    <>
      <Diplomacy />
    </>
  );
}

export default DiplomacyPage;
