import useSWR, { SWRResponse } from "swr";
import { Fetcher } from "swr";
import { useItems } from "./useItems"; // Assuming this is your custom hook for fetching items
import { Purchase } from "../my-api-client";
import { apiClient } from "../services/apiClient.ts";
import { useMemo } from "react";
import { PurchaseItem } from "../types/PurchaseItem.ts";

export const usePurchases = (): SWRResponse<PurchaseItem[], Error> => {
  const { data: items } = useItems(); // Fetch items separately

  const purchasesFetcher: Fetcher<Purchase[]> = () =>
    apiClient()
      .allPurchasesList()
      .then((res) => res.data);

  const result = useSWR<Purchase[], Error>(
    apiClient().allPurchasesList.name,
    purchasesFetcher,
  );

  const purchasesWithItems = useMemo(() => {
    if (items == undefined || result.data == undefined) {
      return undefined;
    }
    const itemsMap = items
      ? new Map(items.map((item) => [item.id, item]))
      : new Map();
    return (
      result.data?.map((purchase) => ({
        ...purchase,
        item: { ...itemsMap.get(purchase.itemId) }, // Attach the item if found
      })) ?? []
    );
  }, [items, result.data]);

  // @ts-ignore
  return { ...result, data: purchasesWithItems };
};
