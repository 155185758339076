/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ResourceInput from "../ResourceInput";
import GeoButton from "../GeoButton";
import { permissionService } from "../../services/permissionService.ts";
import { myMutate, useUser } from "../../hooks/useUser.ts";
import {
  Nations,
  OfferingResourceRequest,
  ReceivingResourceRequest,
  Resource,
  TradeAlliance,
  TradeRequest,
} from "../../my-api-client";
import { apiClient } from "../../services/apiClient.ts";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import useSWR, { Fetcher } from "swr";
import { css } from "@emotion/react";
import { useNations } from "../../hooks/useNations.ts";
import NationFlag from "../Flag";
import SyncAltIcon from "@mui/icons-material/SyncAlt";

type NestedDictionary = { [key: string]: Resource };

interface TradeFormProps {
  handleClose: () => void;
}

const TradeForm = ({ handleClose }: TradeFormProps) => {
  const user = useUser();
  const [saving, setSaving] = useState(false);
  const [selected, setSelected] = useState<Nations>();
  const [resources, setResources] = useState<{
    [key: number]: NestedDictionary;
  }>({});
  const { register, handleSubmit, reset } = useForm();
  const [errors, setErrors] = useState("");
  const { data: nations } = useNations();

  const tradeAlliancesFetcher: Fetcher<TradeAlliance[]> = () => {
    return apiClient()
      .allTradeAlliancesList()
      .then((res) => {
        return res.data;
      });
  };

  const { data: tradeAlliances } = useSWR<TradeAlliance[], Error>(
    apiClient().allTradeAlliancesList.name,
    tradeAlliancesFetcher,
  );

  const teamDict = useMemo(() => {
    const dict: { [key: number]: Nations } = {};
    if (nations) {
      for (let i = 0; i < nations.length; i++) {
        const nation = nations[i];
        dict[nation.id] = nation;
      }
    }
    return dict;
  }, [nations]);
  const myTeam = teamDict[user.nation.id];

  useEffect(() => {
    if (!selected?.id && nations && user && user.nation) {
      let autoSelected: Nations | undefined;
      for (let i = 0; i < nations.length; i++) {
        const nation = nations[i];
        if (
          nation.id != user.nation.id &&
          (!autoSelected || nation.hasTradePort)
        ) {
          autoSelected = nation;
        }
      }

      if (!selected?.id && autoSelected) {
        setSelected(autoSelected);
      } else {
        console.warn("Selected is not set");
      }
    }
  }, [nations, selected, user]);

  useEffect(() => {
    if (!selected?.id) {
      return;
    }
    const resources2: { [key: number]: NestedDictionary } = {};
    teamDict[user.nation.id].resources.forEach((resource) => {
      resources2[resource.resourceType.id] = { myTeam: resource };
    });
    selected.resources.forEach((resource) => {
      resources2[resource.resourceType.id] = {
        ...resources2[resource.resourceType.id],
        selected: resource,
      };
    });

    setResources(resources2);
  }, [selected, teamDict, user.nation.id]);

  const onSubmit = async (data: any) => {
    const offeringResources: Array<OfferingResourceRequest> = [];
    const receivingResources: Array<ReceivingResourceRequest> = [];
    const postData: TradeRequest = {
      offeringNationId: myTeam?.id || 0,
      receivingNationId: selected?.id || 0,
      offeringResources: offeringResources,
      receivingResources: receivingResources,
    };
    let atLeast1 = false;
    atLeast1 = false;
    for (const key in data.resources1) {
      const resource = data.resources1[key];
      if (parseInt(resource) > 0) {
        atLeast1 = true;
        offeringResources.push({
          total: parseInt(resource),
          resourceTypeId: parseInt(key),
        });
      }
    }
    for (const key in data.resources2) {
      const resource = data.resources2[key];
      if (parseInt(resource) > 0) {
        atLeast1 = true;
        receivingResources.push({
          total: parseInt(resource),
          resourceTypeId: parseInt(key),
        });
      }
    }

    if (!atLeast1) {
      setErrors("Must select a resource to trade");
      return;
    } else {
      setErrors("");
    }
    setSaving(true);
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );

    apiClient()
      .allTradesCreate(postData)
      .then((res) => {
        myMutate(apiClient().allTradesList.name);
        reset();
        enqueueSnackbar("Trade Submitted", { variant: "success" });
        handleClose();
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error submitting trade: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);
        closeSnackbar(snack);
      });
  };

  const onSelectTeam = (event: any) => {
    setSelected(teamDict[event.target.value]);
  };

  if (!myTeam || !teamDict[myTeam.id]) {
    return <></>;
  }

  return (
    <>
      {/*<Typography gutterBottom variant="h6" component="div" id={"trade_header"}>*/}
      {/*    Request Trade*/}
      {/*    <GeoTooltip title={"Trade resources with other nations.  A trading port is required before making any trades"} />*/}
      {/*</Typography>*/}
      <form
        onSubmit={handleSubmit(onSubmit)}
        css={css`
          max-width: 100%;
        `}
      >
        <Stack direction="column" spacing={2} justifyContent="center">
          <Stack direction="row" spacing={2}>
            <Select
              label="Nation"
              {...register("Nation")}
              disabled
              value={myTeam.id}
              sx={{
                height: 60,
                width: "calc(50% - 26px)",
              }}
            >
              {Object.values(teamDict).map((nation) => {
                return (
                  <MenuItem value={nation.id} key={"menu 1" + nation.id}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <NationFlag nationId={nation.id} />
                      &nbsp;{nation.name}
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
            <Box
              css={css`
                align-items: center;
                display: flex;
              `}
              sx={{
                height: 60,
              }}
            >
              <SyncAltIcon width="20" height="20" />
            </Box>
            <Select
              label=""
              onChange={onSelectTeam}
              value={selected?.id || ""}
              sx={{
                height: 60,
                width: "calc(50% - 26px)",
              }}
            >
              {Object.values(teamDict)
                .filter((nation) => {
                  return nation.id !== myTeam.id;
                })
                .map((nation) => {
                  return (
                    <MenuItem value={nation.id} key={"menu 2" + nation.id}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <NationFlag nationId={nation.id} />
                        &nbsp;{nation.name}
                      </Box>
                    </MenuItem>
                  );
                })}
            </Select>
          </Stack>

          {Object.values(resources)
            .filter(
              (resource) =>
                resource.myTeam != undefined && resource.selected != undefined,
            )
            .map((resource) => {
              return (
                <Stack
                  direction="row"
                  spacing={2}
                  key={"Resource " + resource.myTeam.id}
                >
                  <ResourceInput
                    key={"input1" + resource.myTeam.resourceType.id}
                    resource={resource.myTeam}
                    register={register}
                    name="resources1"
                  ></ResourceInput>
                  <Box
                    css={css`
                      align-items: center;
                      display: flex;
                    `}
                    sx={{
                      height: 60,
                    }}
                  >
                    <img
                      width="20"
                      height="20"
                      alt="image"
                      src={`${import.meta.env.VITE_CDN}${
                        resource.myTeam.resourceType.icon
                      }`}
                    />
                  </Box>
                  <ResourceInput
                    key={"input2" + resource.selected.resourceType.id}
                    resource={resource.selected}
                    register={register}
                    name="resources2"
                  ></ResourceInput>
                </Stack>
              );
            })}
          <Box
            sx={{
              height: 20,
              width: "200px",
            }}
          >
            Shipping Costs
          </Box>
          <Stack direction="row" spacing={2}>
            <TextField
              sx={{
                height: 60,
                width: "calc(50% - 26px)",
              }}
              variant="outlined"
              type="number"
              defaultValue="1"
              label={`Oil`}
              InputProps={{
                inputProps: {
                  disabled: true,
                },
              }}
            />
            <Box
              css={css`
                align-items: center;
                display: flex;
              `}
              sx={{
                height: 60,
              }}
            >
              <img
                width="20"
                height="20"
                alt="image"
                src={`${import.meta.env.VITE_CDN}/images/Oil.png`}
              />
            </Box>
            <TextField
              sx={{
                height: 60,
                width: "calc(50% - 26px)",
              }}
              variant="outlined"
              type="number"
              defaultValue="0"
              label={`Oil`}
              InputProps={{
                inputProps: {
                  disabled: true,
                },
              }}
            />
          </Stack>

          {tradeAlliances?.find(
            (alliance) =>
              alliance.status == "RECEIVER_ACCEPTED" &&
              (alliance.offeringNationId === selected?.id ||
                alliance.receivingNationId === selected?.id),
          ) == undefined && (
            <Typography color="warning.main">
              Warning: You do not have an active trade agreement with this
              nation
            </Typography>
          )}

          {!selected?.hasTradePort && (
            <Typography color="error.main">
              Error: {selected?.name} does not have a trade port{" "}
            </Typography>
          )}
          <Stack direction="row" spacing={2} justifyContent="right">
            {errors && <div>{errors}</div>}
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <GeoButton
              variant="outlined"
              type="submit"
              title={
                !myTeam.hasTradePort || !selected?.hasTradePort
                  ? "MUST BUILD A TRADE PORT"
                  : permissionService.canInitiateTrades(user).message
              }
              disabled={
                !myTeam.hasTradePort ||
                !selected?.hasTradePort ||
                !permissionService.canInitiateTrades(user).success ||
                saving
              }
            >
              Request Trade
            </GeoButton>
          </Stack>
        </Stack>
      </form>
    </>
  );
};

export default TradeForm;
