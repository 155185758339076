
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "../components/link";
import { Container } from "@mui/material";

const About = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: "20px" }}>
      <Typography variant="h4" gutterBottom>
        About Me
      </Typography>
      <Typography variant="body1" paragraph>
        Hi, I'm Justin, the creator of Geostratapp.com. I am passionate about
        software development and learning, and I've combined these interests to
        develop an engaging, educational platform for students.
      </Typography>
      <Typography variant="body1" paragraph>
        My journey into software development began studying computer science in
        college. Over the years, I've honed my skills in different frontend and
        backend technologies, which have been instrumental in building this
        website.
      </Typography>
      <Typography variant="body1" paragraph>
        The idea for GeoStrat came from a friend who played this very game
        offline with her students. This inspired me to create a digital version
        of the game, which I hope will be useful for teachers and students
        alike.
      </Typography>
      <Typography variant="body1" paragraph>
        My goal is to make learning geography fun and interactive for students,
        using the power of modern web technologies. I believe that a strong
        understanding of geography helps students gain a broader perspective of
        the world and its diverse cultures.
      </Typography>
      <Typography variant="body1" paragraph>
        Thank you for visiting, and I hope you find this website both
        educational and enjoyable!
      </Typography>
      <Box mt={4}>
        <Typography variant="h6">Contact Me</Typography>
        <Typography variant="body1">
          If you have any questions or feedback, feel free to reach out{" "}
          <Link href={"/contact"}>here</Link>.
        </Typography>
      </Box>
    </Container>
  );
};

export default About;
