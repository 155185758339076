import Tab from "@mui/material/Tab";

import Development from "./index";
import Pending from "./pending";
import { Badge, Box } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Approved from "./approved.tsx";
import Declined from "./declined.tsx";
import Military from "./military.tsx";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { useNotifs } from "../../hooks/useNotifs.ts";

export default function BasicTabs() {
  const [queryParams, setQueryParams] = useSearchParams();
  const [value, setValue] = useState("infrastructure");

  useEffect(() => {
    const tab = queryParams.get("tab") || "infrastructure";
    // @ts-ignore
    setValue(tab);
  }, [queryParams]);

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setQueryParams({ tab: newValue });
  };

  const notifs = useNotifs();

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="development sections"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab
              label="Infrastructure"
              value={"infrastructure"}
              className={"infrastructure_header"}
            />
            <Tab
              label="Military"
              value={"military"}
              className={"military_header"}
            />
            <Tab
              label={
                <Badge
                  badgeContent={
                    (notifs?.data?.militaryPurchases || 0) +
                    (notifs?.data?.purchases || 0)
                  }
                  color="primary"
                >
                  Pending&nbsp;&nbsp;&nbsp;
                </Badge>
              }
              value={"pending"}
              className={"pending_header"}
            />
            <Tab label="Approved" value={"approved"} />
            <Tab label="Declined" value={"declined"} />
          </TabList>
        </Box>
        <TabPanel value={"infrastructure"}>
          <Development />
        </TabPanel>
        <TabPanel value={"military"}>
          <Military />
        </TabPanel>
        <TabPanel value={"pending"}>
          <Pending />
        </TabPanel>
        <TabPanel value={"approved"}>
          <Approved />
        </TabPanel>
        <TabPanel value={"declined"}>
          <Declined />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
