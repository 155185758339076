import React from "react";
import { Box, Card, CardContent, Divider, Stack } from "@mui/material";

import {NationStat} from "../../my-api-client";
import Typography from "@mui/material/Typography";
import NationPowerCardsDisplay from "./nationPowerCardsDisplay.tsx";
import GeoWrapperTooltip from "../GeoWrapperTooltip";
import { Bolt } from "@mui/icons-material";

interface PowerPointsPanelProps {
    stats?: NationStat;
}

const PowerPointsPanel: React.FC<PowerPointsPanelProps> = ({ stats }) => {

    return (
        <Card sx={{height: "100%"}}>
            <CardContent>
                <Stack gap={3} p={2}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Bolt  />
                        <Typography variant="h6">
                            Power Points
                        </Typography>
                    </Box>
                    <Divider sx={{ mt: 1, width: "100%", margin: "auto" }} />
                    {stats && <NationPowerCardsDisplay nationStat={stats} />}
                    <Box>
                        <GeoWrapperTooltip
                            title="The points from all the infrastructure controlled on the map"
                            placement="top"
                        >
                            Points From Infrastructure: {stats?.buildingPoints}
                        </GeoWrapperTooltip>
                    </Box>
                    <Box>
                        Total Power Points: {stats?.powerPoints}
                    </Box>
                </Stack>

            </CardContent></Card>
    );
};

export default PowerPointsPanel;
