import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Typography from "@mui/material/Typography";

export default function QuillViewer(props: ReactQuill.ReactQuillProps) {
  if ((props?.value as string)?.includes("<p")) {
    // in props.value look for any links that start with https://docs.google.com and wrap with <a> tag
    const value = (props.value as string).replace(
      /https:\/\/docs.google.com\/[^\s<]+/g,
      (url) =>
        `<a href="${url}" rel="noopener noreferrer" target="_blank">${url}</a>`,
    );

    return (
      <ReactQuill readOnly={true} theme={"bubble"} {...props} value={value} />
    );
  }

  return (
    <Typography variant="body1" paragraph sx={{ whiteSpace: "pre-wrap" }}>
      {(props?.value as string)?.replace(/\n{3,}/g, "\n\n")}
    </Typography>
  );
}
