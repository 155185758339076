/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { myMutate, useUser } from "../../hooks/useUser";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { permissionService } from "../../services/permissionService";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { apiClient } from "../../services/apiClient";
import { Nations } from "../../my-api-client";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import EditNoteIcon from "@mui/icons-material/EditNote";
import QuillEditor from "../Editor/quill_editor.tsx";
import QuillViewer from "../Editor/QuillViewer.tsx";
import Box from "@mui/material/Box";

export default function Constitution({ nation }: { nation: Nations }) {
  const [saving, setSaving] = useState(false);
  const [originalConstitution, setOriginalConstitution] = useState(
    nation.constitution || "",
  );
  const [constitution, setConstitution] = useState(nation.constitution || "");
  const [editing, setEditing] = useState(false);
  const user = useUser();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  const save = async () => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    await apiClient()
      .allNationsPartialUpdate(nation.id, { constitution: constitution })
      .then((res) => {
        enqueueSnackbar("Constitution Saved", {
          variant: "success",
        });
        myMutate(apiClient().allNationsList.name);
        setOriginalConstitution(res.data.constitution || "");
        setEditing(false);
        return res.data;
      })
      .catch((e) => {
        enqueueSnackbar("Error saving constitution: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        closeSnackbar(snack);
        setSaving(false);
      });
  };

  const cancel = async () => {
    setConstitution(nation.constitution || "");
    setEditing(false);
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1>{nation.name}'s Constitution</h1>
          {permissionService.canEditConstitution(user, nation.id).success ? (
            <Box
              display="flex"
              justifyContent="flex-end"
              mt={0}
              mb={0}
              sx={{ display: "inline" }}
            >
              <IconButton
                color="bland"
                sx={{ mt: 0 }}
                onClick={() => {
                  setEditing(true);
                }}
              >
                <EditNoteIcon />
              </IconButton>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>

      <Box sx={{ maxWidth: "800px" }}>
        <QuillViewer value={originalConstitution} />
      </Box>

      <Dialog
        open={editing}
        onClose={cancel}
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen={fullScreen}
      >
        <DialogTitle>{nation.name}'s Constitution</DialogTitle>
        <DialogContent
          style={{
            height: "90vh",
          }}
        >
          <DialogContentText></DialogContentText>
          <QuillEditor
            style={{
              height: `calc(100% - ${small ? "68" : "42"}px)`,
              width: "100%",
              maxWidth: "100%",
            }}
            value={constitution}
            onChange={setConstitution}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={cancel} disabled={saving}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={save} disabled={saving}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
