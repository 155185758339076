export default function useLogoutZulip() {
  const logout = () => {
    // @ts-ignore
    const zToken = window.zToken;
    if (zToken) {
      return fetch(zToken.site + "/logout/", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }).then(() => {
        //console.log("res", res);
      });
    } else {
      return Promise.resolve();
    }
  };

  return logout;
}
