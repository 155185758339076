import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useUser } from "../../hooks/useUser";
import {
  Box,
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import useLogout from "../../hooks/useLogout.tsx";
import { ContactSupport, Feed, Home, Login } from "@mui/icons-material";
import RouterLink from "../RouterLink/index.tsx";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;

interface NavItem {
  name: string;
  Icon: any;
  link: string;
  condition: boolean;
}

export default function LandingHeader(props: Props) {
  const user = useUser();
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const logout = useLogout();

  useEffect(() => {
    setNavItems([
      {
        name: "Home",
        Icon: Home,
        link: "/home",
        condition: true,
      },
      {
        name: "Contact",
        link: "/contact",
        Icon: ContactSupport,
        condition: true,
      },
      {
        name: "Go To App",
        link: "/app/news",
        Icon: Feed,
        condition: !!user,
      },
      {
        name: "Login",
        Icon: Login,
        link: "/login",
        condition: !user,
      },
      /*{
        name: "Sign Up",
        link: "/signup",
        condition: !user,
      }*/
    ]);
  }, [user]);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        sx={{
          py: 2,
          display: "flex",
          justifyContent: "center",
          backgroundColor: "primary.main",
          color: "#fff",
        }}
      >
        <Box
          sx={{
            borderRadius: "50%",
            backgroundColor: "#fff",
            height: "32px",
            width: "32px",
          }}
        >
          <img alt="Logo" src="/logo.png" height="32" width="32" />
        </Box>
        <Typography variant="h6" ml={1}>
          GeoStrat
        </Typography>
      </Box>
      <Divider />
      <List>
        {navItems
          .filter((item) => item.condition)
          .map(({ Icon, link, name }) => (
            <ListItem key={name}>
              <ListItemButton
                sx={{ textAlign: "left" }}
                component={RouterLink}
                to={link}
              >
                <ListItemIcon>{!!Icon && <Icon />}</ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
        {user && (
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "left" }}
              onClick={() => {
                logout();
              }}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", height: "64px" }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                backgroundColor: "#fff",
                height: "32px",
                width: "32px",
              }}
            >
              <img alt="Logo" src="/logo.png" height="32" width="32" />
            </Box>
            <Typography
              ml={1}
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              GeoStrat
            </Typography>
          </Box>

          <Box sx={{ display: { xs: "none", sm: "block" }, height: "32px" }}>
            {navItems
              .filter((item) => item.condition)
              .map((item) => (
                <Button
                  key={item.name}
                  sx={{ color: "#fff" }}
                  component={RouterLink}
                  to={item.link}
                >
                  {item.name}
                </Button>
              ))}
            {user && (
              <Button
                sx={{ color: "#fff" }}
                onClick={() => {
                  logout();
                }}
              >
                Log Out
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
