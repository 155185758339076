/* tslint:disable */
/* eslint-disable */
/**
 * Your Project API
 * Your project description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AdminArticle
 */
export interface AdminArticle {
    /**
     * 
     * @type {number}
     * @memberof AdminArticle
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminArticle
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminArticle
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdminArticle
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof AdminArticle
     */
    'createdBy'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminArticle
     */
    'comments': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof AdminArticle
     */
    'classNameId': number;
    /**
     * 
     * @type {number}
     * @memberof AdminArticle
     */
    'className': number;
    /**
     * 
     * @type {number}
     * @memberof AdminArticle
     */
    'nation': number;
}
/**
 * 
 * @export
 * @interface AdminArticleRequest
 */
export interface AdminArticleRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminArticleRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AdminArticleRequest
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof AdminArticleRequest
     */
    'createdBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminArticleRequest
     */
    'className': number;
    /**
     * 
     * @type {number}
     * @memberof AdminArticleRequest
     */
    'nation': number;
}
/**
 * 
 * @export
 * @interface AdminClassName
 */
export interface AdminClassName {
    /**
     * 
     * @type {number}
     * @memberof AdminClassName
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminClassName
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminClassName
     */
    'bordersEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminClassName
     */
    'isDemoClass': boolean;
}
/**
 * 
 * @export
 * @interface AdminClassNameRequest
 */
export interface AdminClassNameRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminClassNameRequest
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminClassNameRequest
     */
    'bordersEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface AdminComment
 */
export interface AdminComment {
    /**
     * 
     * @type {number}
     * @memberof AdminComment
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminComment
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof AdminComment
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof AdminComment
     */
    'createdBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminComment
     */
    'article': number;
    /**
     * 
     * @type {number}
     * @memberof AdminComment
     */
    'nationId': number;
}
/**
 * 
 * @export
 * @interface AdminCommentRequest
 */
export interface AdminCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCommentRequest
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof AdminCommentRequest
     */
    'createdBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminCommentRequest
     */
    'article': number;
}
/**
 * 
 * @export
 * @interface AdminItem
 */
export interface AdminItem {
    /**
     * 
     * @type {number}
     * @memberof AdminItem
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminItem
     */
    'image': string;
    /**
     * 
     * @type {AdminItemPrice}
     * @memberof AdminItem
     */
    'itemPrice': AdminItemPrice;
    /**
     * 
     * @type {number}
     * @memberof AdminItem
     */
    'createdBy': number;
    /**
     * 
     * @type {string}
     * @memberof AdminItem
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminItem
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AdminItemPrice
 */
export interface AdminItemPrice {
    /**
     * 
     * @type {number}
     * @memberof AdminItemPrice
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AdminItemPrice
     */
    'agCost': number;
    /**
     * 
     * @type {number}
     * @memberof AdminItemPrice
     */
    'lumberCost': number;
    /**
     * 
     * @type {number}
     * @memberof AdminItemPrice
     */
    'oilCost': number;
    /**
     * 
     * @type {number}
     * @memberof AdminItemPrice
     */
    'mineralCost': number;
    /**
     * 
     * @type {number}
     * @memberof AdminItemPrice
     */
    'dailyAgCost': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminItemPrice
     */
    'dailyLumberCost': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminItemPrice
     */
    'dailyOilCost': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminItemPrice
     */
    'dailyMineralCost': number | null;
}
/**
 * 
 * @export
 * @interface AdminNations
 */
export interface AdminNations {
    /**
     * 
     * @type {number}
     * @memberof AdminNations
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminNations
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AdminNations
     */
    'className': number;
    /**
     * 
     * @type {number}
     * @memberof AdminNations
     */
    'classNameId': number;
    /**
     * 
     * @type {string}
     * @memberof AdminNations
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AdminNations
     */
    'constitution'?: string | null;
    /**
     * 
     * @type {Array<AdminResource>}
     * @memberof AdminNations
     */
    'resources': Array<AdminResource>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminNations
     */
    'isWorldBank': boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminNations
     */
    'resourceType': number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminNations
     */
    'population'?: number;
}
/**
 * 
 * @export
 * @interface AdminNationsRequest
 */
export interface AdminNationsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminNationsRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AdminNationsRequest
     */
    'classNameId': number;
    /**
     * 
     * @type {string}
     * @memberof AdminNationsRequest
     */
    'constitution'?: string | null;
    /**
     * 
     * @type {Array<AdminResourceRequest>}
     * @memberof AdminNationsRequest
     */
    'resources': Array<AdminResourceRequest>;
    /**
     * 
     * @type {number}
     * @memberof AdminNationsRequest
     */
    'population'?: number;
}
/**
 * 
 * @export
 * @interface AdminResource
 */
export interface AdminResource {
    /**
     * 
     * @type {number}
     * @memberof AdminResource
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AdminResource
     */
    'total': number;
    /**
     * 
     * @type {AdminResourceType}
     * @memberof AdminResource
     */
    'resourceType': AdminResourceType;
}
/**
 * 
 * @export
 * @interface AdminResourceRequest
 */
export interface AdminResourceRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminResourceRequest
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AdminResourceRequest
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface AdminResourceType
 */
export interface AdminResourceType {
    /**
     * 
     * @type {number}
     * @memberof AdminResourceType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminResourceType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminResourceType
     */
    'icon': string;
}
/**
 * 
 * @export
 * @interface AdminResourceTypeRequest
 */
export interface AdminResourceTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminResourceTypeRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AdminRole
 */
export interface AdminRole {
    /**
     * 
     * @type {number}
     * @memberof AdminRole
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminRole
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminRole
     */
    'description': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof AdminRole
     */
    'permissions': { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface AdminTrade
 */
export interface AdminTrade {
    /**
     * 
     * @type {number}
     * @memberof AdminTrade
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AdminTrade
     */
    'offeringNation': number;
    /**
     * 
     * @type {string}
     * @memberof AdminTrade
     */
    'className': string;
    /**
     * 
     * @type {number}
     * @memberof AdminTrade
     */
    'receivingNation': number;
    /**
     * 
     * @type {string}
     * @memberof AdminTrade
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof AdminTrade
     */
    'createdBy': number;
    /**
     * 
     * @type {Array<OfferingResource>}
     * @memberof AdminTrade
     */
    'offeringResources': Array<OfferingResource>;
    /**
     * 
     * @type {Array<ReceivingResource>}
     * @memberof AdminTrade
     */
    'receivingResources': Array<ReceivingResource>;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof AdminTrade
     */
    'status': AdminTradeStatusEnum;
}

export const AdminTradeStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type AdminTradeStatusEnum = typeof AdminTradeStatusEnum[keyof typeof AdminTradeStatusEnum];

/**
 * 
 * @export
 * @interface AdminTradeRequest
 */
export interface AdminTradeRequest {
    /**
     * 
     * @type {Array<OfferingResourceRequest>}
     * @memberof AdminTradeRequest
     */
    'offeringResources': Array<OfferingResourceRequest>;
    /**
     * 
     * @type {Array<ReceivingResourceRequest>}
     * @memberof AdminTradeRequest
     */
    'receivingResources': Array<ReceivingResourceRequest>;
}
/**
 * 
 * @export
 * @interface AdminUserClass
 */
export interface AdminUserClass {
    /**
     * 
     * @type {number}
     * @memberof AdminUserClass
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AdminUserClass
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof AdminUserClass
     */
    'nation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUserClass
     */
    'imageUrl': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminUserClass
     */
    'roles'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof AdminUserClass
     */
    'className'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUserClass
     */
    'fullName': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserClass
     */
    'isAdmin': boolean;
}
/**
 * 
 * @export
 * @interface AdminUserClassRequest
 */
export interface AdminUserClassRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminUserClassRequest
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof AdminUserClassRequest
     */
    'nation'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdminUserClassRequest
     */
    'roles'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof AdminUserClassRequest
     */
    'className'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUserClassRequest
     */
    'fullName': string;
}
/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'content': string;
    /**
     * 
     * @type {MinimalUser}
     * @memberof Article
     */
    'createdBy': MinimalUser;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof Article
     */
    'comments': Array<Comment>;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'classNameId': number;
}
/**
 * 
 * @export
 * @interface ArticleRequest
 */
export interface ArticleRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticleRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleRequest
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface Attack
 */
export interface Attack {
    /**
     * 
     * @type {number}
     * @memberof Attack
     */
    'id': number;
    /**
     * 
     * @type {Nation}
     * @memberof Attack
     */
    'offeringNation': Nation;
    /**
     * 
     * @type {Nation}
     * @memberof Attack
     */
    'receivingNation': Nation;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof Attack
     */
    'status'?: AttackStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Attack
     */
    'offeringPurchase': number;
    /**
     * 
     * @type {AttackPurchase}
     * @memberof Attack
     */
    'targetPurchase': AttackPurchase;
    /**
     * 
     * @type {number}
     * @memberof Attack
     */
    'offeringPurchaseId': number;
    /**
     * 
     * @type {number}
     * @memberof Attack
     */
    'targetPurchaseId': number;
    /**
     * 
     * @type {number}
     * @memberof Attack
     */
    'attackerDiceLost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Attack
     */
    'defenderDiceLost'?: number;
    /**
     * 
     * @type {string}
     * @memberof Attack
     */
    'attackerDice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attack
     */
    'defenderDice'?: string | null;
}

export const AttackStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type AttackStatusEnum = typeof AttackStatusEnum[keyof typeof AttackStatusEnum];

/**
 * 
 * @export
 * @interface AttackPurchase
 */
export interface AttackPurchase {
    /**
     * 
     * @type {number}
     * @memberof AttackPurchase
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AttackPurchase
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof AttackPurchase
     */
    'y': number;
}
/**
 * 
 * @export
 * @interface AttackRequest
 */
export interface AttackRequest {
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof AttackRequest
     */
    'status'?: AttackRequestStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AttackRequest
     */
    'offeringPurchaseId': number;
    /**
     * 
     * @type {number}
     * @memberof AttackRequest
     */
    'targetPurchaseId': number;
    /**
     * 
     * @type {number}
     * @memberof AttackRequest
     */
    'attackerDiceLost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AttackRequest
     */
    'defenderDiceLost'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttackRequest
     */
    'attackerDice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttackRequest
     */
    'defenderDice'?: string | null;
}

export const AttackRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type AttackRequestStatusEnum = typeof AttackRequestStatusEnum[keyof typeof AttackRequestStatusEnum];

/**
 * 
 * @export
 * @interface ClassName
 */
export interface ClassName {
    /**
     * 
     * @type {number}
     * @memberof ClassName
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ClassName
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ClassName
     */
    'bordersEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClassName
     */
    'isDemoClass'?: boolean;
}
/**
 * 
 * @export
 * @interface ClassNameRequest
 */
export interface ClassNameRequest {
    /**
     * 
     * @type {string}
     * @memberof ClassNameRequest
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ClassNameRequest
     */
    'isDemoClass'?: boolean;
}
/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'createdAt': string;
    /**
     * 
     * @type {MinimalUser}
     * @memberof Comment
     */
    'createdBy': MinimalUser;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'article': number;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'nationId': number;
}
/**
 * 
 * @export
 * @interface CommentRequest
 */
export interface CommentRequest {
    /**
     * 
     * @type {string}
     * @memberof CommentRequest
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof CommentRequest
     */
    'article': number;
}
/**
 * 
 * @export
 * @interface Email
 */
export interface Email {
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface EmailRequest
 */
export interface EmailRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface Invite
 */
export interface Invite {
    /**
     * 
     * @type {number}
     * @memberof Invite
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof Invite
     */
    'used'?: boolean;
}
/**
 * 
 * @export
 * @interface InviteRequest
 */
export interface InviteRequest {
    /**
     * 
     * @type {string}
     * @memberof InviteRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof InviteRequest
     */
    'used'?: boolean;
}
/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'image': string;
    /**
     * 
     * @type {ItemPrice}
     * @memberof Item
     */
    'itemPrice': ItemPrice;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'createdBy': number;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'dice': number;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'movementRange': number;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'attackRange': number;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isMilitary': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isHumanResource': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isLand': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isOcean': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isWell': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isOceanOil': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isFish': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isMineral': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isLumber': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isAg': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isRiver': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isCity': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isLandOil': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'isShoreline': boolean;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'ag': number;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'lumber': number;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'oil': number;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'mineral': number;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'points': number;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'water': number;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'housing': number;
}
/**
 * 
 * @export
 * @interface ItemPrice
 */
export interface ItemPrice {
    /**
     * 
     * @type {number}
     * @memberof ItemPrice
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ItemPrice
     */
    'agCost': number;
    /**
     * 
     * @type {number}
     * @memberof ItemPrice
     */
    'lumberCost': number;
    /**
     * 
     * @type {number}
     * @memberof ItemPrice
     */
    'oilCost': number;
    /**
     * 
     * @type {number}
     * @memberof ItemPrice
     */
    'mineralCost': number;
    /**
     * 
     * @type {number}
     * @memberof ItemPrice
     */
    'dailyAgCost': number | null;
    /**
     * 
     * @type {number}
     * @memberof ItemPrice
     */
    'dailyLumberCost': number | null;
    /**
     * 
     * @type {number}
     * @memberof ItemPrice
     */
    'dailyOilCost': number | null;
    /**
     * 
     * @type {number}
     * @memberof ItemPrice
     */
    'dailyMineralCost': number | null;
}
/**
 * 
 * @export
 * @interface LoginserializerRequest
 */
export interface LoginserializerRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginserializerRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof LoginserializerRequest
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface MilitaryAlliance
 */
export interface MilitaryAlliance {
    /**
     * 
     * @type {number}
     * @memberof MilitaryAlliance
     */
    'id': number;
    /**
     * 
     * @type {Nation}
     * @memberof MilitaryAlliance
     */
    'offeringNation': Nation;
    /**
     * 
     * @type {Nation}
     * @memberof MilitaryAlliance
     */
    'receivingNation': Nation;
    /**
     * 
     * @type {number}
     * @memberof MilitaryAlliance
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof MilitaryAlliance
     */
    'receivingNationId': number;
    /**
     * 
     * @type {string}
     * @memberof MilitaryAlliance
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof MilitaryAlliance
     */
    'createdBy': number;
    /**
     * 
     * @type {string}
     * @memberof MilitaryAlliance
     */
    'updatedAt': string;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof MilitaryAlliance
     */
    'status'?: MilitaryAllianceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MilitaryAlliance
     */
    'declaration'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MilitaryAlliance
     */
    'isSecret'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MilitaryAlliance
     */
    'proposingUser': string;
    /**
     * 
     * @type {string}
     * @memberof MilitaryAlliance
     */
    'confirmationUser': string;
    /**
     * 
     * @type {string}
     * @memberof MilitaryAlliance
     */
    'opposingUser': string;
}

export const MilitaryAllianceStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type MilitaryAllianceStatusEnum = typeof MilitaryAllianceStatusEnum[keyof typeof MilitaryAllianceStatusEnum];

/**
 * 
 * @export
 * @interface MilitaryAllianceRequest
 */
export interface MilitaryAllianceRequest {
    /**
     * 
     * @type {number}
     * @memberof MilitaryAllianceRequest
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof MilitaryAllianceRequest
     */
    'receivingNationId': number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof MilitaryAllianceRequest
     */
    'status'?: MilitaryAllianceRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MilitaryAllianceRequest
     */
    'declaration'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MilitaryAllianceRequest
     */
    'isSecret'?: boolean;
}

export const MilitaryAllianceRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type MilitaryAllianceRequestStatusEnum = typeof MilitaryAllianceRequestStatusEnum[keyof typeof MilitaryAllianceRequestStatusEnum];

/**
 * 
 * @export
 * @interface MinimalUser
 */
export interface MinimalUser {
    /**
     * 
     * @type {number}
     * @memberof MinimalUser
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MinimalUser
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof MinimalUser
     */
    'imageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof MinimalUser
     */
    'nation'?: number | null;
}
/**
 * 
 * @export
 * @interface MinimalUserRequest
 */
export interface MinimalUserRequest {
    /**
     * 
     * @type {string}
     * @memberof MinimalUserRequest
     */
    'fullName': string;
    /**
     * 
     * @type {number}
     * @memberof MinimalUserRequest
     */
    'nation'?: number | null;
}
/**
 * 
 * @export
 * @interface Nation
 */
export interface Nation {
    /**
     * 
     * @type {number}
     * @memberof Nation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Nation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Nation
     */
    'imageUrl': string;
}
/**
 * 
 * @export
 * @interface NationStat
 */
export interface NationStat {
    /**
     * 
     * @type {number}
     * @memberof NationStat
     */
    'id': number;
    /**
     * 
     * @type {Array<Stat>}
     * @memberof NationStat
     */
    'stats': Array<Stat>;
    /**
     * 
     * @type {Array<PowerCard>}
     * @memberof NationStat
     */
    'powerCards': Array<PowerCard>;
    /**
     * 
     * @type {number}
     * @memberof NationStat
     */
    'buildingPoints': number;
    /**
     * 
     * @type {number}
     * @memberof NationStat
     */
    'powerPoints': number;
    /**
     * 
     * @type {Array<ResourceStat>}
     * @memberof NationStat
     */
    'production': Array<ResourceStat>;
    /**
     * 
     * @type {Array<ResourceStat>}
     * @memberof NationStat
     */
    'consumption': Array<ResourceStat>;
}
/**
 * 
 * @export
 * @interface NationUserClass
 */
export interface NationUserClass {
    /**
     * 
     * @type {number}
     * @memberof NationUserClass
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof NationUserClass
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof NationUserClass
     */
    'imageUrl': string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof NationUserClass
     */
    'roles': Array<Role>;
    /**
     * 
     * @type {boolean}
     * @memberof NationUserClass
     */
    'isAdmin': boolean;
    /**
     * 
     * @type {number}
     * @memberof NationUserClass
     */
    'account': number;
}
/**
 * 
 * @export
 * @interface NationUserClassRequest
 */
export interface NationUserClassRequest {
    /**
     * 
     * @type {string}
     * @memberof NationUserClassRequest
     */
    'fullName': string;
    /**
     * 
     * @type {File}
     * @memberof NationUserClassRequest
     */
    'profilePicture'?: File | null;
}
/**
 * 
 * @export
 * @interface Nations
 */
export interface Nations {
    /**
     * 
     * @type {number}
     * @memberof Nations
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Nations
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Nations
     */
    'className': number;
    /**
     * 
     * @type {number}
     * @memberof Nations
     */
    'classNameId': number;
    /**
     * 
     * @type {string}
     * @memberof Nations
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Nations
     */
    'constitution'?: string | null;
    /**
     * 
     * @type {Array<NationUserClass>}
     * @memberof Nations
     */
    'users': Array<NationUserClass>;
    /**
     * 
     * @type {Array<Resource>}
     * @memberof Nations
     */
    'resources': Array<Resource>;
    /**
     * 
     * @type {string}
     * @memberof Nations
     */
    'borders'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Nations
     */
    'hasTradePort'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Nations
     */
    'hasMilitaryBase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Nations
     */
    'hasVillage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Nations
     */
    'hasCity'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Nations
     */
    'hasMegalopolis'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Nations
     */
    'resourceTypeId': number | null;
}
/**
 * 
 * @export
 * @interface NationsRequest
 */
export interface NationsRequest {
    /**
     * 
     * @type {string}
     * @memberof NationsRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof NationsRequest
     */
    'classNameId': number;
    /**
     * 
     * @type {File}
     * @memberof NationsRequest
     */
    'flag'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof NationsRequest
     */
    'constitution'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NationsRequest
     */
    'borders'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NationsRequest
     */
    'hasTradePort'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NationsRequest
     */
    'hasMilitaryBase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NationsRequest
     */
    'hasVillage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NationsRequest
     */
    'hasCity'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NationsRequest
     */
    'hasMegalopolis'?: boolean;
}
/**
 * 
 * @export
 * @interface NationsStat
 */
export interface NationsStat {
    /**
     * 
     * @type {Array<NationStat>}
     * @memberof NationsStat
     */
    'nations': Array<NationStat>;
}
/**
 * 
 * @export
 * @interface Notif
 */
export interface Notif {
    /**
     * 
     * @type {number}
     * @memberof Notif
     */
    'trades': number;
    /**
     * 
     * @type {number}
     * @memberof Notif
     */
    'tradeAlliances': number;
    /**
     * 
     * @type {number}
     * @memberof Notif
     */
    'militaryAlliances': number;
    /**
     * 
     * @type {number}
     * @memberof Notif
     */
    'wars': number;
    /**
     * 
     * @type {number}
     * @memberof Notif
     */
    'subpoenas': number;
    /**
     * 
     * @type {number}
     * @memberof Notif
     */
    'warnings': number;
    /**
     * 
     * @type {number}
     * @memberof Notif
     */
    'purchases': number;
    /**
     * 
     * @type {number}
     * @memberof Notif
     */
    'militaryPurchases': number;
    /**
     * 
     * @type {number}
     * @memberof Notif
     */
    'placements': number;
    /**
     * 
     * @type {number}
     * @memberof Notif
     */
    'militaryPlacements': number;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'modelName': string;
    /**
     * 
     * @type {Nation}
     * @memberof Notification
     */
    'offeringNation': Nation;
    /**
     * 
     * @type {Nation}
     * @memberof Notification
     */
    'receivingNation': Nation;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'modelRef': string;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'className': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'createdBy': number;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'isRead': boolean;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof Notification
     */
    'notificationStatus': NotificationNotificationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'notificationText': string | null;
}

export const NotificationNotificationStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type NotificationNotificationStatusEnum = typeof NotificationNotificationStatusEnum[keyof typeof NotificationNotificationStatusEnum];

/**
 * 
 * @export
 * @interface NotificationRequest
 */
export interface NotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationRequest
     */
    'modelName': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRequest
     */
    'modelRef': string;
    /**
     * 
     * @type {number}
     * @memberof NotificationRequest
     */
    'className': number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof NotificationRequest
     */
    'notificationStatus': NotificationRequestNotificationStatusEnum;
}

export const NotificationRequestNotificationStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type NotificationRequestNotificationStatusEnum = typeof NotificationRequestNotificationStatusEnum[keyof typeof NotificationRequestNotificationStatusEnum];

/**
 * 
 * @export
 * @interface OfferingResource
 */
export interface OfferingResource {
    /**
     * 
     * @type {number}
     * @memberof OfferingResource
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OfferingResource
     */
    'total': number;
    /**
     * 
     * @type {ResourceType}
     * @memberof OfferingResource
     */
    'resourceType': ResourceType;
    /**
     * 
     * @type {number}
     * @memberof OfferingResource
     */
    'resourceTypeId': number;
}
/**
 * 
 * @export
 * @interface OfferingResourceRequest
 */
export interface OfferingResourceRequest {
    /**
     * 
     * @type {number}
     * @memberof OfferingResourceRequest
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof OfferingResourceRequest
     */
    'resourceTypeId': number;
}
/**
 * 
 * @export
 * @interface PaginatedAdminArticleList
 */
export interface PaginatedAdminArticleList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAdminArticleList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminArticleList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminArticleList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<AdminArticle>}
     * @memberof PaginatedAdminArticleList
     */
    'results'?: Array<AdminArticle>;
}
/**
 * 
 * @export
 * @interface PaginatedAdminClassNameList
 */
export interface PaginatedAdminClassNameList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAdminClassNameList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminClassNameList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminClassNameList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<AdminClassName>}
     * @memberof PaginatedAdminClassNameList
     */
    'results'?: Array<AdminClassName>;
}
/**
 * 
 * @export
 * @interface PaginatedAdminCommentList
 */
export interface PaginatedAdminCommentList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAdminCommentList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminCommentList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminCommentList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<AdminComment>}
     * @memberof PaginatedAdminCommentList
     */
    'results'?: Array<AdminComment>;
}
/**
 * 
 * @export
 * @interface PaginatedAdminItemList
 */
export interface PaginatedAdminItemList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAdminItemList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminItemList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminItemList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<AdminItem>}
     * @memberof PaginatedAdminItemList
     */
    'results'?: Array<AdminItem>;
}
/**
 * 
 * @export
 * @interface PaginatedAdminNationsList
 */
export interface PaginatedAdminNationsList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAdminNationsList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminNationsList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminNationsList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<AdminNations>}
     * @memberof PaginatedAdminNationsList
     */
    'results'?: Array<AdminNations>;
}
/**
 * 
 * @export
 * @interface PaginatedAdminRoleList
 */
export interface PaginatedAdminRoleList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAdminRoleList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminRoleList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminRoleList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<AdminRole>}
     * @memberof PaginatedAdminRoleList
     */
    'results'?: Array<AdminRole>;
}
/**
 * 
 * @export
 * @interface PaginatedAdminTradeList
 */
export interface PaginatedAdminTradeList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAdminTradeList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminTradeList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminTradeList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<AdminTrade>}
     * @memberof PaginatedAdminTradeList
     */
    'results'?: Array<AdminTrade>;
}
/**
 * 
 * @export
 * @interface PaginatedAdminUserClassList
 */
export interface PaginatedAdminUserClassList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedAdminUserClassList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminUserClassList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedAdminUserClassList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<AdminUserClass>}
     * @memberof PaginatedAdminUserClassList
     */
    'results'?: Array<AdminUserClass>;
}
/**
 * 
 * @export
 * @interface PaginatedMilitaryAllianceList
 */
export interface PaginatedMilitaryAllianceList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedMilitaryAllianceList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMilitaryAllianceList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMilitaryAllianceList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<MilitaryAlliance>}
     * @memberof PaginatedMilitaryAllianceList
     */
    'results'?: Array<MilitaryAlliance>;
}
/**
 * 
 * @export
 * @interface PaginatedNotificationList
 */
export interface PaginatedNotificationList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedNotificationList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNotificationList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedNotificationList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof PaginatedNotificationList
     */
    'results'?: Array<Notification>;
}
/**
 * 
 * @export
 * @interface PaginatedPermissionList
 */
export interface PaginatedPermissionList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedPermissionList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPermissionList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPermissionList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof PaginatedPermissionList
     */
    'results'?: Array<Permission>;
}
/**
 * 
 * @export
 * @interface PaginatedPopulationChangeEventList
 */
export interface PaginatedPopulationChangeEventList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedPopulationChangeEventList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPopulationChangeEventList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPopulationChangeEventList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<PopulationChangeEvent>}
     * @memberof PaginatedPopulationChangeEventList
     */
    'results'?: Array<PopulationChangeEvent>;
}
/**
 * 
 * @export
 * @interface PaginatedPurchaseList
 */
export interface PaginatedPurchaseList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedPurchaseList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPurchaseList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedPurchaseList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Purchase>}
     * @memberof PaginatedPurchaseList
     */
    'results'?: Array<Purchase>;
}
/**
 * 
 * @export
 * @interface PaginatedResourceList
 */
export interface PaginatedResourceList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedResourceList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedResourceList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedResourceList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Resource>}
     * @memberof PaginatedResourceList
     */
    'results'?: Array<Resource>;
}
/**
 * 
 * @export
 * @interface PaginatedResourceTypeList
 */
export interface PaginatedResourceTypeList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedResourceTypeList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedResourceTypeList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedResourceTypeList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ResourceType>}
     * @memberof PaginatedResourceTypeList
     */
    'results'?: Array<ResourceType>;
}
/**
 * 
 * @export
 * @interface PaginatedRolePermissionsList
 */
export interface PaginatedRolePermissionsList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedRolePermissionsList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedRolePermissionsList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedRolePermissionsList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<RolePermissions>}
     * @memberof PaginatedRolePermissionsList
     */
    'results'?: Array<RolePermissions>;
}
/**
 * 
 * @export
 * @interface PaginatedSubpoenaList
 */
export interface PaginatedSubpoenaList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedSubpoenaList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSubpoenaList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSubpoenaList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Subpoena>}
     * @memberof PaginatedSubpoenaList
     */
    'results'?: Array<Subpoena>;
}
/**
 * 
 * @export
 * @interface PaginatedTradeAllianceList
 */
export interface PaginatedTradeAllianceList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedTradeAllianceList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedTradeAllianceList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedTradeAllianceList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<TradeAlliance>}
     * @memberof PaginatedTradeAllianceList
     */
    'results'?: Array<TradeAlliance>;
}
/**
 * 
 * @export
 * @interface PaginatedWarList
 */
export interface PaginatedWarList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedWarList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedWarList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedWarList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<War>}
     * @memberof PaginatedWarList
     */
    'results'?: Array<War>;
}
/**
 * 
 * @export
 * @interface PaginatedWarningList
 */
export interface PaginatedWarningList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedWarningList
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedWarningList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedWarningList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Warning>}
     * @memberof PaginatedWarningList
     */
    'results'?: Array<Warning>;
}
/**
 * 
 * @export
 * @interface PasswordToken
 */
export interface PasswordToken {
    /**
     * 
     * @type {string}
     * @memberof PasswordToken
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordToken
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface PasswordTokenRequest
 */
export interface PasswordTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordTokenRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordTokenRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface PatchedAdminArticleRequest
 */
export interface PatchedAdminArticleRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedAdminArticleRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedAdminArticleRequest
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedAdminArticleRequest
     */
    'createdBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedAdminArticleRequest
     */
    'className'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedAdminArticleRequest
     */
    'nation'?: number;
}
/**
 * 
 * @export
 * @interface PatchedAdminClassNameRequest
 */
export interface PatchedAdminClassNameRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedAdminClassNameRequest
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedAdminClassNameRequest
     */
    'bordersEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchedAdminCommentRequest
 */
export interface PatchedAdminCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedAdminCommentRequest
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedAdminCommentRequest
     */
    'createdBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedAdminCommentRequest
     */
    'article'?: number;
}
/**
 * 
 * @export
 * @interface PatchedAdminNationsRequest
 */
export interface PatchedAdminNationsRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedAdminNationsRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedAdminNationsRequest
     */
    'classNameId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedAdminNationsRequest
     */
    'constitution'?: string | null;
    /**
     * 
     * @type {Array<AdminResourceRequest>}
     * @memberof PatchedAdminNationsRequest
     */
    'resources'?: Array<AdminResourceRequest>;
    /**
     * 
     * @type {number}
     * @memberof PatchedAdminNationsRequest
     */
    'population'?: number;
}
/**
 * 
 * @export
 * @interface PatchedAdminTradeRequest
 */
export interface PatchedAdminTradeRequest {
    /**
     * 
     * @type {Array<OfferingResourceRequest>}
     * @memberof PatchedAdminTradeRequest
     */
    'offeringResources'?: Array<OfferingResourceRequest>;
    /**
     * 
     * @type {Array<ReceivingResourceRequest>}
     * @memberof PatchedAdminTradeRequest
     */
    'receivingResources'?: Array<ReceivingResourceRequest>;
}
/**
 * 
 * @export
 * @interface PatchedAdminUserClassRequest
 */
export interface PatchedAdminUserClassRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedAdminUserClassRequest
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedAdminUserClassRequest
     */
    'nation'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedAdminUserClassRequest
     */
    'roles'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PatchedAdminUserClassRequest
     */
    'className'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAdminUserClassRequest
     */
    'fullName'?: string;
}
/**
 * 
 * @export
 * @interface PatchedArticleRequest
 */
export interface PatchedArticleRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedArticleRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedArticleRequest
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface PatchedMilitaryAllianceRequest
 */
export interface PatchedMilitaryAllianceRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedMilitaryAllianceRequest
     */
    'offeringNationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedMilitaryAllianceRequest
     */
    'receivingNationId'?: number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof PatchedMilitaryAllianceRequest
     */
    'status'?: PatchedMilitaryAllianceRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedMilitaryAllianceRequest
     */
    'declaration'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedMilitaryAllianceRequest
     */
    'isSecret'?: boolean;
}

export const PatchedMilitaryAllianceRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type PatchedMilitaryAllianceRequestStatusEnum = typeof PatchedMilitaryAllianceRequestStatusEnum[keyof typeof PatchedMilitaryAllianceRequestStatusEnum];

/**
 * 
 * @export
 * @interface PatchedNationsRequest
 */
export interface PatchedNationsRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedNationsRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedNationsRequest
     */
    'classNameId'?: number;
    /**
     * 
     * @type {File}
     * @memberof PatchedNationsRequest
     */
    'flag'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedNationsRequest
     */
    'constitution'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedNationsRequest
     */
    'borders'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNationsRequest
     */
    'hasTradePort'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNationsRequest
     */
    'hasMilitaryBase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNationsRequest
     */
    'hasVillage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNationsRequest
     */
    'hasCity'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedNationsRequest
     */
    'hasMegalopolis'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchedPopulationChangeEventRequest
 */
export interface PatchedPopulationChangeEventRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedPopulationChangeEventRequest
     */
    'nation'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedPopulationChangeEventRequest
     */
    'populationChange'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedPopulationChangeEventRequest
     */
    'event'?: string;
}
/**
 * 
 * @export
 * @interface PatchedPurchaseRequest
 */
export interface PatchedPurchaseRequest {
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof PatchedPurchaseRequest
     */
    'status'?: PatchedPurchaseRequestStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PatchedPurchaseRequest
     */
    'itemId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedPurchaseRequest
     */
    'createdBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedPurchaseRequest
     */
    'offeringNationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedPurchaseRequest
     */
    'paymentCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedPurchaseRequest
     */
    'dice'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedPurchaseRequest
     */
    'name'?: string | null;
}

export const PatchedPurchaseRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type PatchedPurchaseRequestStatusEnum = typeof PatchedPurchaseRequestStatusEnum[keyof typeof PatchedPurchaseRequestStatusEnum];

/**
 * 
 * @export
 * @interface PatchedResourceRequest
 */
export interface PatchedResourceRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedResourceRequest
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface PatchedSubpoenaRequest
 */
export interface PatchedSubpoenaRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedSubpoenaRequest
     */
    'offeringNationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedSubpoenaRequest
     */
    'receivingNationId'?: number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof PatchedSubpoenaRequest
     */
    'status'?: PatchedSubpoenaRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedSubpoenaRequest
     */
    'declaration'?: string | null;
}

export const PatchedSubpoenaRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type PatchedSubpoenaRequestStatusEnum = typeof PatchedSubpoenaRequestStatusEnum[keyof typeof PatchedSubpoenaRequestStatusEnum];

/**
 * 
 * @export
 * @interface PatchedTradeAllianceRequest
 */
export interface PatchedTradeAllianceRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedTradeAllianceRequest
     */
    'offeringNationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedTradeAllianceRequest
     */
    'receivingNationId'?: number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof PatchedTradeAllianceRequest
     */
    'status'?: PatchedTradeAllianceRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedTradeAllianceRequest
     */
    'declaration'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedTradeAllianceRequest
     */
    'isSecret'?: boolean;
}

export const PatchedTradeAllianceRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type PatchedTradeAllianceRequestStatusEnum = typeof PatchedTradeAllianceRequestStatusEnum[keyof typeof PatchedTradeAllianceRequestStatusEnum];

/**
 * 
 * @export
 * @interface PatchedUserClassRequest
 */
export interface PatchedUserClassRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedUserClassRequest
     */
    'fullName'?: string;
    /**
     * 
     * @type {File}
     * @memberof PatchedUserClassRequest
     */
    'profilePicture'?: File | null;
}
/**
 * 
 * @export
 * @interface PatchedWarRequest
 */
export interface PatchedWarRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedWarRequest
     */
    'offeringNationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedWarRequest
     */
    'receivingNationId'?: number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof PatchedWarRequest
     */
    'status'?: PatchedWarRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedWarRequest
     */
    'declaration'?: string | null;
}

export const PatchedWarRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type PatchedWarRequestStatusEnum = typeof PatchedWarRequestStatusEnum[keyof typeof PatchedWarRequestStatusEnum];

/**
 * 
 * @export
 * @interface PatchedWarningRequest
 */
export interface PatchedWarningRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedWarningRequest
     */
    'offeringNationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedWarningRequest
     */
    'receivingNationId'?: number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof PatchedWarningRequest
     */
    'status'?: PatchedWarningRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedWarningRequest
     */
    'declaration'?: string | null;
}

export const PatchedWarningRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type PatchedWarningRequestStatusEnum = typeof PatchedWarningRequestStatusEnum[keyof typeof PatchedWarningRequestStatusEnum];

/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {number}
     * @memberof Permission
     */
    'id': number;
    /**
     * * `CAN_UPLOAD_FLAG` - Can Upload Flag * `CAN_EDIT_CONSTITUTION` - Can Edit Constitution * `CAN_INITIATE_TRADES` - Can Initiate Trades * `CAN_APPROVE_OUTGOING_TRADES` - Can Approve Outgoing Trades * `CAN_ACCEPT_INCOMING_TRADES` - Can Accept Incoming Trades * `CAN_PUBLISH_NEWS` - Can Publish News * `CAN_COMMENT` - Can Comment On News * `CAN_PURCHASE_INFRASTRUCTURE` - Can Purchase Infrastructure * `CAN_APPROVE_INFRASTRUCTURE` - Can Approve infrastructure purchase * `CAN_PURCHASE_MILITARY` - Can Purchase Military * `CAN_APPROVE_MILITARY` - Can Approve military purchase * `CAN_PLACE_INFRASTRUCTURE` - Can Place Infrastructure * `CAN_CLAIM_TERRITORY` - Can Claim Territory * `CAN_APPROVE_INFRASTRUCTURE_PLACEMENT` - Can Approve infrastructure placement * `CAN_PLACE_MILITARY` - Can Place Military * `CAN_APPROVE_MILITARY_PLACEMENT` - Can Approve military placement * `CAN_INITIATE_TRADE_ALLIANCE` - Can initiate trade alliance * `CAN_APPROVE_OUTGOING_TRADE_ALLIANCE` - Can Approve outgoing trade alliance * `CAN_ACCEPT_INCOMING_TRADE_ALLIANCE` - Can Approve incoming trade alliance * `CAN_INITIATE_MILITARY_ALLIANCE` - Can initiate military alliance * `CAN_APPROVE_OUTGOING_MILITARY_ALLIANCE` - Can Approve outgoing military alliance * `CAN_ACCEPT_INCOMING_MILITARY_ALLIANCE` - Can Approve incoming military alliance * `CAN_INITIATE_WARNING` - Can initiate warning * `CAN_APPROVE_WARNING` - Can Approve warning * `CAN_INITIATE_WAR` - Can initiate war * `CAN_APPROVE_WAR` - Can Approve war * `CAN_INITIATE_SUBPOENA` - Can initiate subpoena * `CAN_APPROVE_SUBPOENA` - Can Approve subpoena * `CAN_EDIT_PURCHASE_NAME` - Can Edit Purchase Name
     * @type {string}
     * @memberof Permission
     */
    'permission': PermissionPermissionEnum;
}

export const PermissionPermissionEnum = {
    UploadFlag: 'CAN_UPLOAD_FLAG',
    EditConstitution: 'CAN_EDIT_CONSTITUTION',
    InitiateTrades: 'CAN_INITIATE_TRADES',
    ApproveOutgoingTrades: 'CAN_APPROVE_OUTGOING_TRADES',
    AcceptIncomingTrades: 'CAN_ACCEPT_INCOMING_TRADES',
    PublishNews: 'CAN_PUBLISH_NEWS',
    Comment: 'CAN_COMMENT',
    PurchaseInfrastructure: 'CAN_PURCHASE_INFRASTRUCTURE',
    ApproveInfrastructure: 'CAN_APPROVE_INFRASTRUCTURE',
    PurchaseMilitary: 'CAN_PURCHASE_MILITARY',
    ApproveMilitary: 'CAN_APPROVE_MILITARY',
    PlaceInfrastructure: 'CAN_PLACE_INFRASTRUCTURE',
    ClaimTerritory: 'CAN_CLAIM_TERRITORY',
    ApproveInfrastructurePlacement: 'CAN_APPROVE_INFRASTRUCTURE_PLACEMENT',
    PlaceMilitary: 'CAN_PLACE_MILITARY',
    ApproveMilitaryPlacement: 'CAN_APPROVE_MILITARY_PLACEMENT',
    InitiateTradeAlliance: 'CAN_INITIATE_TRADE_ALLIANCE',
    ApproveOutgoingTradeAlliance: 'CAN_APPROVE_OUTGOING_TRADE_ALLIANCE',
    AcceptIncomingTradeAlliance: 'CAN_ACCEPT_INCOMING_TRADE_ALLIANCE',
    InitiateMilitaryAlliance: 'CAN_INITIATE_MILITARY_ALLIANCE',
    ApproveOutgoingMilitaryAlliance: 'CAN_APPROVE_OUTGOING_MILITARY_ALLIANCE',
    AcceptIncomingMilitaryAlliance: 'CAN_ACCEPT_INCOMING_MILITARY_ALLIANCE',
    InitiateWarning: 'CAN_INITIATE_WARNING',
    ApproveWarning: 'CAN_APPROVE_WARNING',
    InitiateWar: 'CAN_INITIATE_WAR',
    ApproveWar: 'CAN_APPROVE_WAR',
    InitiateSubpoena: 'CAN_INITIATE_SUBPOENA',
    ApproveSubpoena: 'CAN_APPROVE_SUBPOENA',
    EditPurchaseName: 'CAN_EDIT_PURCHASE_NAME'
} as const;

export type PermissionPermissionEnum = typeof PermissionPermissionEnum[keyof typeof PermissionPermissionEnum];

/**
 * 
 * @export
 * @interface PopulationChangeEvent
 */
export interface PopulationChangeEvent {
    /**
     * 
     * @type {number}
     * @memberof PopulationChangeEvent
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PopulationChangeEvent
     */
    'nation': number;
    /**
     * 
     * @type {number}
     * @memberof PopulationChangeEvent
     */
    'populationChange'?: number;
    /**
     * 
     * @type {string}
     * @memberof PopulationChangeEvent
     */
    'event': string;
    /**
     * 
     * @type {string}
     * @memberof PopulationChangeEvent
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof PopulationChangeEvent
     */
    'createdBy': number;
}
/**
 * 
 * @export
 * @interface PopulationChangeEventRequest
 */
export interface PopulationChangeEventRequest {
    /**
     * 
     * @type {number}
     * @memberof PopulationChangeEventRequest
     */
    'nation': number;
    /**
     * 
     * @type {number}
     * @memberof PopulationChangeEventRequest
     */
    'populationChange'?: number;
    /**
     * 
     * @type {string}
     * @memberof PopulationChangeEventRequest
     */
    'event': string;
}
/**
 * 
 * @export
 * @interface PowerCard
 */
export interface PowerCard {
    /**
     * 
     * @type {string}
     * @memberof PowerCard
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PowerCard
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof PowerCard
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface Purchase
 */
export interface Purchase {
    /**
     * 
     * @type {number}
     * @memberof Purchase
     */
    'id': number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof Purchase
     */
    'status'?: PurchaseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Purchase
     */
    'itemId': number;
    /**
     * 
     * @type {string}
     * @memberof Purchase
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof Purchase
     */
    'createdBy'?: number;
    /**
     * 
     * @type {Nation}
     * @memberof Purchase
     */
    'offeringNation': Nation;
    /**
     * 
     * @type {number}
     * @memberof Purchase
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof Purchase
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof Purchase
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof Purchase
     */
    'paymentCount'?: number;
    /**
     * 
     * @type {Nation}
     * @memberof Purchase
     */
    'controlledBy': Nation;
    /**
     * 
     * @type {boolean}
     * @memberof Purchase
     */
    'canMove': boolean;
    /**
     * 
     * @type {Attack}
     * @memberof Purchase
     */
    'currentAttack': Attack | null;
    /**
     * 
     * @type {boolean}
     * @memberof Purchase
     */
    'canAttack': boolean;
    /**
     * 
     * @type {number}
     * @memberof Purchase
     */
    'dice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Purchase
     */
    'population': number;
    /**
     * 
     * @type {string}
     * @memberof Purchase
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Purchase
     */
    'proposingUser': string;
    /**
     * 
     * @type {string}
     * @memberof Purchase
     */
    'confirmationUser': string;
    /**
     * 
     * @type {number}
     * @memberof Purchase
     */
    'opposingUser': number | null;
}

export const PurchaseStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type PurchaseStatusEnum = typeof PurchaseStatusEnum[keyof typeof PurchaseStatusEnum];

/**
 * 
 * @export
 * @interface PurchaseRequest
 */
export interface PurchaseRequest {
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof PurchaseRequest
     */
    'status'?: PurchaseRequestStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequest
     */
    'itemId': number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequest
     */
    'createdBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequest
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequest
     */
    'paymentCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseRequest
     */
    'dice'?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseRequest
     */
    'name'?: string | null;
}

export const PurchaseRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type PurchaseRequestStatusEnum = typeof PurchaseRequestStatusEnum[keyof typeof PurchaseRequestStatusEnum];

/**
 * 
 * @export
 * @interface ReceivingResource
 */
export interface ReceivingResource {
    /**
     * 
     * @type {number}
     * @memberof ReceivingResource
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ReceivingResource
     */
    'total': number;
    /**
     * 
     * @type {ResourceType}
     * @memberof ReceivingResource
     */
    'resourceType': ResourceType;
    /**
     * 
     * @type {number}
     * @memberof ReceivingResource
     */
    'resourceTypeId': number;
}
/**
 * 
 * @export
 * @interface ReceivingResourceRequest
 */
export interface ReceivingResourceRequest {
    /**
     * 
     * @type {number}
     * @memberof ReceivingResourceRequest
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ReceivingResourceRequest
     */
    'resourceTypeId': number;
}
/**
 * 
 * @export
 * @interface Register
 */
export interface Register {
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'fullName': string;
}
/**
 * 
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'fullName': string;
}
/**
 * 
 * @export
 * @interface ResetToken
 */
export interface ResetToken {
    /**
     * 
     * @type {string}
     * @memberof ResetToken
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface ResetTokenRequest
 */
export interface ResetTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetTokenRequest
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {number}
     * @memberof Resource
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Resource
     */
    'total': number;
    /**
     * 
     * @type {ResourceType}
     * @memberof Resource
     */
    'resourceType': ResourceType;
}
/**
 * 
 * @export
 * @interface ResourceRequest
 */
export interface ResourceRequest {
    /**
     * 
     * @type {number}
     * @memberof ResourceRequest
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface ResourceStat
 */
export interface ResourceStat {
    /**
     * 
     * @type {string}
     * @memberof ResourceStat
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceStat
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ResourceStat
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface ResourceType
 */
export interface ResourceType {
    /**
     * 
     * @type {number}
     * @memberof ResourceType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResourceType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResourceType
     */
    'icon': string;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface RolePermissions
 */
export interface RolePermissions {
    /**
     * 
     * @type {number}
     * @memberof RolePermissions
     */
    'id': number;
    /**
     * * `CAN_UPLOAD_FLAG` - Can Upload Flag * `CAN_EDIT_CONSTITUTION` - Can Edit Constitution * `CAN_INITIATE_TRADES` - Can Initiate Trades * `CAN_APPROVE_OUTGOING_TRADES` - Can Approve Outgoing Trades * `CAN_ACCEPT_INCOMING_TRADES` - Can Accept Incoming Trades * `CAN_PUBLISH_NEWS` - Can Publish News * `CAN_COMMENT` - Can Comment On News * `CAN_PURCHASE_INFRASTRUCTURE` - Can Purchase Infrastructure * `CAN_APPROVE_INFRASTRUCTURE` - Can Approve infrastructure purchase * `CAN_PURCHASE_MILITARY` - Can Purchase Military * `CAN_APPROVE_MILITARY` - Can Approve military purchase * `CAN_PLACE_INFRASTRUCTURE` - Can Place Infrastructure * `CAN_CLAIM_TERRITORY` - Can Claim Territory * `CAN_APPROVE_INFRASTRUCTURE_PLACEMENT` - Can Approve infrastructure placement * `CAN_PLACE_MILITARY` - Can Place Military * `CAN_APPROVE_MILITARY_PLACEMENT` - Can Approve military placement * `CAN_INITIATE_TRADE_ALLIANCE` - Can initiate trade alliance * `CAN_APPROVE_OUTGOING_TRADE_ALLIANCE` - Can Approve outgoing trade alliance * `CAN_ACCEPT_INCOMING_TRADE_ALLIANCE` - Can Approve incoming trade alliance * `CAN_INITIATE_MILITARY_ALLIANCE` - Can initiate military alliance * `CAN_APPROVE_OUTGOING_MILITARY_ALLIANCE` - Can Approve outgoing military alliance * `CAN_ACCEPT_INCOMING_MILITARY_ALLIANCE` - Can Approve incoming military alliance * `CAN_INITIATE_WARNING` - Can initiate warning * `CAN_APPROVE_WARNING` - Can Approve warning * `CAN_INITIATE_WAR` - Can initiate war * `CAN_APPROVE_WAR` - Can Approve war * `CAN_INITIATE_SUBPOENA` - Can initiate subpoena * `CAN_APPROVE_SUBPOENA` - Can Approve subpoena * `CAN_EDIT_PURCHASE_NAME` - Can Edit Purchase Name
     * @type {string}
     * @memberof RolePermissions
     */
    'permission': RolePermissionsPermissionEnum;
}

export const RolePermissionsPermissionEnum = {
    UploadFlag: 'CAN_UPLOAD_FLAG',
    EditConstitution: 'CAN_EDIT_CONSTITUTION',
    InitiateTrades: 'CAN_INITIATE_TRADES',
    ApproveOutgoingTrades: 'CAN_APPROVE_OUTGOING_TRADES',
    AcceptIncomingTrades: 'CAN_ACCEPT_INCOMING_TRADES',
    PublishNews: 'CAN_PUBLISH_NEWS',
    Comment: 'CAN_COMMENT',
    PurchaseInfrastructure: 'CAN_PURCHASE_INFRASTRUCTURE',
    ApproveInfrastructure: 'CAN_APPROVE_INFRASTRUCTURE',
    PurchaseMilitary: 'CAN_PURCHASE_MILITARY',
    ApproveMilitary: 'CAN_APPROVE_MILITARY',
    PlaceInfrastructure: 'CAN_PLACE_INFRASTRUCTURE',
    ClaimTerritory: 'CAN_CLAIM_TERRITORY',
    ApproveInfrastructurePlacement: 'CAN_APPROVE_INFRASTRUCTURE_PLACEMENT',
    PlaceMilitary: 'CAN_PLACE_MILITARY',
    ApproveMilitaryPlacement: 'CAN_APPROVE_MILITARY_PLACEMENT',
    InitiateTradeAlliance: 'CAN_INITIATE_TRADE_ALLIANCE',
    ApproveOutgoingTradeAlliance: 'CAN_APPROVE_OUTGOING_TRADE_ALLIANCE',
    AcceptIncomingTradeAlliance: 'CAN_ACCEPT_INCOMING_TRADE_ALLIANCE',
    InitiateMilitaryAlliance: 'CAN_INITIATE_MILITARY_ALLIANCE',
    ApproveOutgoingMilitaryAlliance: 'CAN_APPROVE_OUTGOING_MILITARY_ALLIANCE',
    AcceptIncomingMilitaryAlliance: 'CAN_ACCEPT_INCOMING_MILITARY_ALLIANCE',
    InitiateWarning: 'CAN_INITIATE_WARNING',
    ApproveWarning: 'CAN_APPROVE_WARNING',
    InitiateWar: 'CAN_INITIATE_WAR',
    ApproveWar: 'CAN_APPROVE_WAR',
    InitiateSubpoena: 'CAN_INITIATE_SUBPOENA',
    ApproveSubpoena: 'CAN_APPROVE_SUBPOENA',
    EditPurchaseName: 'CAN_EDIT_PURCHASE_NAME'
} as const;

export type RolePermissionsPermissionEnum = typeof RolePermissionsPermissionEnum[keyof typeof RolePermissionsPermissionEnum];

/**
 * 
 * @export
 * @interface Stat
 */
export interface Stat {
    /**
     * 
     * @type {string}
     * @memberof Stat
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Stat
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof Stat
     */
    'displayValue': string;
    /**
     * 
     * @type {string}
     * @memberof Stat
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface Subpoena
 */
export interface Subpoena {
    /**
     * 
     * @type {number}
     * @memberof Subpoena
     */
    'id': number;
    /**
     * 
     * @type {Nation}
     * @memberof Subpoena
     */
    'offeringNation': Nation;
    /**
     * 
     * @type {Nation}
     * @memberof Subpoena
     */
    'receivingNation': Nation;
    /**
     * 
     * @type {number}
     * @memberof Subpoena
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof Subpoena
     */
    'receivingNationId': number;
    /**
     * 
     * @type {string}
     * @memberof Subpoena
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Subpoena
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Subpoena
     */
    'createdBy': number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof Subpoena
     */
    'status'?: SubpoenaStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Subpoena
     */
    'declaration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subpoena
     */
    'proposingUser': string;
    /**
     * 
     * @type {string}
     * @memberof Subpoena
     */
    'confirmationUser': string;
    /**
     * 
     * @type {string}
     * @memberof Subpoena
     */
    'opposingUser': string;
}

export const SubpoenaStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type SubpoenaStatusEnum = typeof SubpoenaStatusEnum[keyof typeof SubpoenaStatusEnum];

/**
 * 
 * @export
 * @interface SubpoenaRequest
 */
export interface SubpoenaRequest {
    /**
     * 
     * @type {number}
     * @memberof SubpoenaRequest
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof SubpoenaRequest
     */
    'receivingNationId': number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof SubpoenaRequest
     */
    'status'?: SubpoenaRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SubpoenaRequest
     */
    'declaration'?: string | null;
}

export const SubpoenaRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type SubpoenaRequestStatusEnum = typeof SubpoenaRequestStatusEnum[keyof typeof SubpoenaRequestStatusEnum];

/**
 * 
 * @export
 * @interface Suggestion
 */
export interface Suggestion {
    /**
     * 
     * @type {number}
     * @memberof Suggestion
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Suggestion
     */
    'suggestion': string;
    /**
     * 
     * @type {number}
     * @memberof Suggestion
     */
    'createdBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof Suggestion
     */
    'updatedBy'?: number;
}
/**
 * 
 * @export
 * @interface SuggestionRequest
 */
export interface SuggestionRequest {
    /**
     * 
     * @type {string}
     * @memberof SuggestionRequest
     */
    'suggestion': string;
    /**
     * 
     * @type {number}
     * @memberof SuggestionRequest
     */
    'createdBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof SuggestionRequest
     */
    'updatedBy'?: number;
}
/**
 * 
 * @export
 * @interface Trade
 */
export interface Trade {
    /**
     * 
     * @type {number}
     * @memberof Trade
     */
    'id': number;
    /**
     * 
     * @type {Nation}
     * @memberof Trade
     */
    'offeringNation': Nation;
    /**
     * 
     * @type {Nation}
     * @memberof Trade
     */
    'receivingNation': Nation;
    /**
     * 
     * @type {number}
     * @memberof Trade
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof Trade
     */
    'receivingNationId': number;
    /**
     * 
     * @type {string}
     * @memberof Trade
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Trade
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Trade
     */
    'createdBy': number;
    /**
     * 
     * @type {Array<OfferingResource>}
     * @memberof Trade
     */
    'offeringResources': Array<OfferingResource>;
    /**
     * 
     * @type {Array<ReceivingResource>}
     * @memberof Trade
     */
    'receivingResources': Array<ReceivingResource>;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof Trade
     */
    'status'?: TradeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Trade
     */
    'proposingUser': string;
    /**
     * 
     * @type {string}
     * @memberof Trade
     */
    'confirmationUser': string;
    /**
     * 
     * @type {string}
     * @memberof Trade
     */
    'opposingUser': string;
}

export const TradeStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type TradeStatusEnum = typeof TradeStatusEnum[keyof typeof TradeStatusEnum];

/**
 * 
 * @export
 * @interface TradeAlliance
 */
export interface TradeAlliance {
    /**
     * 
     * @type {number}
     * @memberof TradeAlliance
     */
    'id': number;
    /**
     * 
     * @type {Nation}
     * @memberof TradeAlliance
     */
    'offeringNation': Nation;
    /**
     * 
     * @type {Nation}
     * @memberof TradeAlliance
     */
    'receivingNation': Nation;
    /**
     * 
     * @type {number}
     * @memberof TradeAlliance
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof TradeAlliance
     */
    'receivingNationId': number;
    /**
     * 
     * @type {string}
     * @memberof TradeAlliance
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TradeAlliance
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof TradeAlliance
     */
    'createdBy': number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof TradeAlliance
     */
    'status'?: TradeAllianceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeAlliance
     */
    'declaration'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TradeAlliance
     */
    'isSecret'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeAlliance
     */
    'proposingUser': string;
    /**
     * 
     * @type {string}
     * @memberof TradeAlliance
     */
    'confirmationUser': string;
    /**
     * 
     * @type {string}
     * @memberof TradeAlliance
     */
    'opposingUser': string;
}

export const TradeAllianceStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type TradeAllianceStatusEnum = typeof TradeAllianceStatusEnum[keyof typeof TradeAllianceStatusEnum];

/**
 * 
 * @export
 * @interface TradeAllianceRequest
 */
export interface TradeAllianceRequest {
    /**
     * 
     * @type {number}
     * @memberof TradeAllianceRequest
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof TradeAllianceRequest
     */
    'receivingNationId': number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof TradeAllianceRequest
     */
    'status'?: TradeAllianceRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeAllianceRequest
     */
    'declaration'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TradeAllianceRequest
     */
    'isSecret'?: boolean;
}

export const TradeAllianceRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type TradeAllianceRequestStatusEnum = typeof TradeAllianceRequestStatusEnum[keyof typeof TradeAllianceRequestStatusEnum];

/**
 * 
 * @export
 * @interface TradeRequest
 */
export interface TradeRequest {
    /**
     * 
     * @type {number}
     * @memberof TradeRequest
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof TradeRequest
     */
    'receivingNationId': number;
    /**
     * 
     * @type {Array<OfferingResourceRequest>}
     * @memberof TradeRequest
     */
    'offeringResources': Array<OfferingResourceRequest>;
    /**
     * 
     * @type {Array<ReceivingResourceRequest>}
     * @memberof TradeRequest
     */
    'receivingResources': Array<ReceivingResourceRequest>;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof TradeRequest
     */
    'status'?: TradeRequestStatusEnum;
}

export const TradeRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type TradeRequestStatusEnum = typeof TradeRequestStatusEnum[keyof typeof TradeRequestStatusEnum];

/**
 * 
 * @export
 * @interface TradeStatus
 */
export interface TradeStatus {
    /**
     * 
     * @type {number}
     * @memberof TradeStatus
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TradeStatus
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof TradeStatus
     */
    'createdBy': number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof TradeStatus
     */
    'status'?: TradeStatusStatusEnum;
}

export const TradeStatusStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type TradeStatusStatusEnum = typeof TradeStatusStatusEnum[keyof typeof TradeStatusStatusEnum];

/**
 * 
 * @export
 * @interface Tutorial
 */
export interface Tutorial {
    /**
     * 
     * @type {string}
     * @memberof Tutorial
     */
    'section': string;
}
/**
 * 
 * @export
 * @interface TutorialRequest
 */
export interface TutorialRequest {
    /**
     * 
     * @type {string}
     * @memberof TutorialRequest
     */
    'section': string;
}
/**
 * 
 * @export
 * @interface UseinvitecodeserializerRequest
 */
export interface UseinvitecodeserializerRequest {
    /**
     * 
     * @type {string}
     * @memberof UseinvitecodeserializerRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface UserClass
 */
export interface UserClass {
    /**
     * 
     * @type {number}
     * @memberof UserClass
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserClass
     */
    'fullName': string;
    /**
     * 
     * @type {UserNation}
     * @memberof UserClass
     */
    'nation': UserNation;
    /**
     * 
     * @type {string}
     * @memberof UserClass
     */
    'imageUrl': string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof UserClass
     */
    'roles': Array<Role>;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UserClass
     */
    'permissions': { [key: string]: any; };
    /**
     * 
     * @type {ClassName}
     * @memberof UserClass
     */
    'className': ClassName;
    /**
     * 
     * @type {boolean}
     * @memberof UserClass
     */
    'isAdmin': boolean;
    /**
     * 
     * @type {number}
     * @memberof UserClass
     */
    'account': number;
}
/**
 * 
 * @export
 * @interface UserClasses
 */
export interface UserClasses {
    /**
     * 
     * @type {number}
     * @memberof UserClasses
     */
    'id': number;
    /**
     * 
     * @type {Account}
     * @memberof UserClasses
     */
    'account': Account;
}
/**
 * 
 * @export
 * @interface UserNation
 */
export interface UserNation {
    /**
     * 
     * @type {number}
     * @memberof UserNation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserNation
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UserNation
     */
    'className': number;
    /**
     * 
     * @type {boolean}
     * @memberof UserNation
     */
    'hasTradePort': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNation
     */
    'hasMilitaryBase': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNation
     */
    'hasVillage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNation
     */
    'hasCity': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNation
     */
    'hasMegalopolis': boolean;
}
/**
 * 
 * @export
 * @interface Waitlist
 */
export interface Waitlist {
    /**
     * 
     * @type {number}
     * @memberof Waitlist
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Waitlist
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface WaitlistRequest
 */
export interface WaitlistRequest {
    /**
     * 
     * @type {string}
     * @memberof WaitlistRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface War
 */
export interface War {
    /**
     * 
     * @type {number}
     * @memberof War
     */
    'id': number;
    /**
     * 
     * @type {Nation}
     * @memberof War
     */
    'offeringNation': Nation;
    /**
     * 
     * @type {Nation}
     * @memberof War
     */
    'receivingNation': Nation;
    /**
     * 
     * @type {number}
     * @memberof War
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof War
     */
    'receivingNationId': number;
    /**
     * 
     * @type {string}
     * @memberof War
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof War
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof War
     */
    'createdBy': number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof War
     */
    'status'?: WarStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof War
     */
    'declaration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof War
     */
    'proposingUser': string;
    /**
     * 
     * @type {string}
     * @memberof War
     */
    'confirmationUser': string;
    /**
     * 
     * @type {string}
     * @memberof War
     */
    'opposingUser': string;
}

export const WarStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type WarStatusEnum = typeof WarStatusEnum[keyof typeof WarStatusEnum];

/**
 * 
 * @export
 * @interface WarRequest
 */
export interface WarRequest {
    /**
     * 
     * @type {number}
     * @memberof WarRequest
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof WarRequest
     */
    'receivingNationId': number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof WarRequest
     */
    'status'?: WarRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WarRequest
     */
    'declaration'?: string | null;
}

export const WarRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type WarRequestStatusEnum = typeof WarRequestStatusEnum[keyof typeof WarRequestStatusEnum];

/**
 * 
 * @export
 * @interface Warning
 */
export interface Warning {
    /**
     * 
     * @type {number}
     * @memberof Warning
     */
    'id': number;
    /**
     * 
     * @type {Nation}
     * @memberof Warning
     */
    'offeringNation': Nation;
    /**
     * 
     * @type {Nation}
     * @memberof Warning
     */
    'receivingNation': Nation;
    /**
     * 
     * @type {number}
     * @memberof Warning
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof Warning
     */
    'receivingNationId': number;
    /**
     * 
     * @type {string}
     * @memberof Warning
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Warning
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof Warning
     */
    'createdBy': number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof Warning
     */
    'status'?: WarningStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Warning
     */
    'declaration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Warning
     */
    'proposingUser': string;
    /**
     * 
     * @type {string}
     * @memberof Warning
     */
    'confirmationUser': string;
    /**
     * 
     * @type {string}
     * @memberof Warning
     */
    'opposingUser': string;
}

export const WarningStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type WarningStatusEnum = typeof WarningStatusEnum[keyof typeof WarningStatusEnum];

/**
 * 
 * @export
 * @interface WarningRequest
 */
export interface WarningRequest {
    /**
     * 
     * @type {number}
     * @memberof WarningRequest
     */
    'offeringNationId': number;
    /**
     * 
     * @type {number}
     * @memberof WarningRequest
     */
    'receivingNationId': number;
    /**
     * * `CREATED` - Created * `SENDER_ACCEPTED` - Sender Accepted * `SENDER_REJECTED` - Sender Rejected * `SENDER_CANCELED` - Sender Canceled * `RECEIVER_ACCEPTED` - Receiver Accepted * `RECEIVER_DECLINED` - Receiver Declined * `RECEIVER_CANCELED` - Receiver Canceled * `PURCHASE_PLACED` - Purchase Placed * `PURCHASE_DESTROYED` - Purchase DESTROYED * `DELETED` - DELETED
     * @type {string}
     * @memberof WarningRequest
     */
    'status'?: WarningRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WarningRequest
     */
    'declaration'?: string | null;
}

export const WarningRequestStatusEnum = {
    Created: 'CREATED',
    SenderAccepted: 'SENDER_ACCEPTED',
    SenderRejected: 'SENDER_REJECTED',
    SenderCanceled: 'SENDER_CANCELED',
    ReceiverAccepted: 'RECEIVER_ACCEPTED',
    ReceiverDeclined: 'RECEIVER_DECLINED',
    ReceiverCanceled: 'RECEIVER_CANCELED',
    PurchasePlaced: 'PURCHASE_PLACED',
    PurchaseDestroyed: 'PURCHASE_DESTROYED',
    Deleted: 'DELETED'
} as const;

export type WarningRequestStatusEnum = typeof WarningRequestStatusEnum[keyof typeof WarningRequestStatusEnum];


/**
 * AllApi - axios parameter creator
 * @export
 */
export const AllApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ArticleRequest} articleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allArticlesCreate: async (articleRequest: ArticleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleRequest' is not null or undefined
            assertParamExists('allArticlesCreate', 'articleRequest', articleRequest)
            const localVarPath = `/all/articles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allArticlesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/articles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {PatchedArticleRequest} [patchedArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allArticlesPartialUpdate: async (id: number, patchedArticleRequest?: PatchedArticleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allArticlesPartialUpdate', 'id', id)
            const localVarPath = `/all/articles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedArticleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allArticlesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allArticlesRetrieve', 'id', id)
            const localVarPath = `/all/articles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allAttacksCancelCreate', 'id', id)
            const localVarPath = `/all/attacks/{id}/cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AttackRequest} attackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksCreate: async (attackRequest: AttackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attackRequest' is not null or undefined
            assertParamExists('allAttacksCreate', 'attackRequest', attackRequest)
            const localVarPath = `/all/attacks/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/attacks/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksReceiverAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allAttacksReceiverAcceptCreate', 'id', id)
            const localVarPath = `/all/attacks/{id}/receiver_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksReceiverCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allAttacksReceiverCancelCreate', 'id', id)
            const localVarPath = `/all/attacks/{id}/receiver_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksReceiverDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allAttacksReceiverDeclineCreate', 'id', id)
            const localVarPath = `/all/attacks/{id}/receiver_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allAttacksRetrieve', 'id', id)
            const localVarPath = `/all/attacks/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksSenderAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allAttacksSenderAcceptCreate', 'id', id)
            const localVarPath = `/all/attacks/{id}/sender_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksSenderCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allAttacksSenderCancelCreate', 'id', id)
            const localVarPath = `/all/attacks/{id}/sender_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksSenderDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allAttacksSenderDeclineCreate', 'id', id)
            const localVarPath = `/all/attacks/{id}/sender_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CommentRequest} commentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCommentsCreate: async (commentRequest: CommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentRequest' is not null or undefined
            assertParamExists('allCommentsCreate', 'commentRequest', commentRequest)
            const localVarPath = `/all/comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCommentsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCommentsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allCommentsRetrieve', 'id', id)
            const localVarPath = `/all/comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCompleteSessionCreate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allCompleteSessionCreate', 'id', id)
            const localVarPath = `/all/complete_session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accessToken access_token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allGoogleLoginCreate: async (accessToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessToken' is not null or undefined
            assertParamExists('allGoogleLoginCreate', 'accessToken', accessToken)
            const localVarPath = `/all/google_login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (accessToken !== undefined) {
                localVarQueryParameter['access_token'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InviteRequest} inviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allInviteCreate: async (inviteRequest: InviteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteRequest' is not null or undefined
            assertParamExists('allInviteCreate', 'inviteRequest', inviteRequest)
            const localVarPath = `/all/invite/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allInviteGetByCodeRetrieve: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('allInviteGetByCodeRetrieve', 'code', code)
            const localVarPath = `/all/invite/get_by_code/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allInviteList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/invite/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this invite.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allInviteRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allInviteRetrieve', 'id', id)
            const localVarPath = `/all/invite/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UseinvitecodeserializerRequest} useinvitecodeserializerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allInviteUseInviteCodeCreate: async (useinvitecodeserializerRequest: UseinvitecodeserializerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'useinvitecodeserializerRequest' is not null or undefined
            assertParamExists('allInviteUseInviteCodeCreate', 'useinvitecodeserializerRequest', useinvitecodeserializerRequest)
            const localVarPath = `/all/invite/use_invite_code/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(useinvitecodeserializerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allItemsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allItemsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allItemsRetrieve', 'id', id)
            const localVarPath = `/all/items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginserializerRequest} loginserializerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allLoginCreate: async (loginserializerRequest: LoginserializerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginserializerRequest' is not null or undefined
            assertParamExists('allLoginCreate', 'loginserializerRequest', loginserializerRequest)
            const localVarPath = `/all/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginserializerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allMilitaryAlliancesCancelCreate', 'id', id)
            const localVarPath = `/all/militaryAlliances/{id}/cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MilitaryAllianceRequest} militaryAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesCreate: async (militaryAllianceRequest: MilitaryAllianceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'militaryAllianceRequest' is not null or undefined
            assertParamExists('allMilitaryAlliancesCreate', 'militaryAllianceRequest', militaryAllianceRequest)
            const localVarPath = `/all/militaryAlliances/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(militaryAllianceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/militaryAlliances/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesReceiverAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allMilitaryAlliancesReceiverAcceptCreate', 'id', id)
            const localVarPath = `/all/militaryAlliances/{id}/receiver_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesReceiverCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allMilitaryAlliancesReceiverCancelCreate', 'id', id)
            const localVarPath = `/all/militaryAlliances/{id}/receiver_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesReceiverDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allMilitaryAlliancesReceiverDeclineCreate', 'id', id)
            const localVarPath = `/all/militaryAlliances/{id}/receiver_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allMilitaryAlliancesRetrieve', 'id', id)
            const localVarPath = `/all/militaryAlliances/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesSenderAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allMilitaryAlliancesSenderAcceptCreate', 'id', id)
            const localVarPath = `/all/militaryAlliances/{id}/sender_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesSenderCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allMilitaryAlliancesSenderCancelCreate', 'id', id)
            const localVarPath = `/all/militaryAlliances/{id}/sender_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesSenderDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allMilitaryAlliancesSenderDeclineCreate', 'id', id)
            const localVarPath = `/all/militaryAlliances/{id}/sender_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNationStatsRetrieve: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/nation_stats/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NationsRequest} nationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNationsCreate: async (nationsRequest: NationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nationsRequest' is not null or undefined
            assertParamExists('allNationsCreate', 'nationsRequest', nationsRequest)
            const localVarPath = `/all/nations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNationsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/nations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {PatchedNationsRequest} [patchedNationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNationsPartialUpdate: async (id: number, patchedNationsRequest?: PatchedNationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allNationsPartialUpdate', 'id', id)
            const localVarPath = `/all/nations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedNationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNationsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allNationsRetrieve', 'id', id)
            const localVarPath = `/all/nations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} borders borders
         * @param {number} id A unique integer value identifying this nation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNationsSaveBordersCreate: async (borders: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borders' is not null or undefined
            assertParamExists('allNationsSaveBordersCreate', 'borders', borders)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allNationsSaveBordersCreate', 'id', id)
            const localVarPath = `/all/nations/{id}/save_borders/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (borders !== undefined) {
                localVarQueryParameter['borders'] = borders;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotificationRequest} notificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNotificationsCreate: async (notificationRequest: NotificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationRequest' is not null or undefined
            assertParamExists('allNotificationsCreate', 'notificationRequest', notificationRequest)
            const localVarPath = `/all/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNotificationsList: async (page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNotificationsMarkAsReadCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allNotificationsMarkAsReadCreate', 'id', id)
            const localVarPath = `/all/notifications/{id}/mark_as_read/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNotificationsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allNotificationsRetrieve', 'id', id)
            const localVarPath = `/all/notifications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPermissionsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/permissions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPermissionsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPermissionsRetrieve', 'id', id)
            const localVarPath = `/all/permissions/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} depth depth
         * @param {number} x x
         * @param {number} y y
         * @param {number} [controlledBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesAdjacentList: async (depth: number, x: number, y: number, controlledBy?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'depth' is not null or undefined
            assertParamExists('allPurchasesAdjacentList', 'depth', depth)
            // verify required parameter 'x' is not null or undefined
            assertParamExists('allPurchasesAdjacentList', 'x', x)
            // verify required parameter 'y' is not null or undefined
            assertParamExists('allPurchasesAdjacentList', 'y', y)
            const localVarPath = `/all/purchases/adjacent/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (controlledBy !== undefined) {
                localVarQueryParameter['controlled_by'] = controlledBy;
            }

            if (depth !== undefined) {
                localVarQueryParameter['depth'] = depth;
            }

            if (x !== undefined) {
                localVarQueryParameter['x'] = x;
            }

            if (y !== undefined) {
                localVarQueryParameter['y'] = y;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesCancelCreate', 'id', id)
            const localVarPath = `/all/purchases/{id}/cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PurchaseRequest} purchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesCreate: async (purchaseRequest: PurchaseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseRequest' is not null or undefined
            assertParamExists('allPurchasesCreate', 'purchaseRequest', purchaseRequest)
            const localVarPath = `/all/purchases/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesDeletePurchaseCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesDeletePurchaseCreate', 'id', id)
            const localVarPath = `/all/purchases/{id}/delete_purchase/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesDestroyPurchaseCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesDestroyPurchaseCreate', 'id', id)
            const localVarPath = `/all/purchases/{id}/destroy_purchase/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [controlledBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesList: async (controlledBy?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/purchases/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (controlledBy !== undefined) {
                localVarQueryParameter['controlled_by'] = controlledBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesRebuildPurchaseCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesRebuildPurchaseCreate', 'id', id)
            const localVarPath = `/all/purchases/{id}/rebuild_purchase/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesReceiverAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesReceiverAcceptCreate', 'id', id)
            const localVarPath = `/all/purchases/{id}/receiver_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesReceiverCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesReceiverCancelCreate', 'id', id)
            const localVarPath = `/all/purchases/{id}/receiver_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesReceiverDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesReceiverDeclineCreate', 'id', id)
            const localVarPath = `/all/purchases/{id}/receiver_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesRetrieve', 'id', id)
            const localVarPath = `/all/purchases/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesSenderAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesSenderAcceptCreate', 'id', id)
            const localVarPath = `/all/purchases/{id}/sender_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesSenderCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesSenderCancelCreate', 'id', id)
            const localVarPath = `/all/purchases/{id}/sender_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesSenderDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesSenderDeclineCreate', 'id', id)
            const localVarPath = `/all/purchases/{id}/sender_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesSetNameCreate: async (id: number, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesSetNameCreate', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('allPurchasesSetNameCreate', 'name', name)
            const localVarPath = `/all/purchases/{id}/set_name/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {number} x x
         * @param {number} y y
         * @param {boolean} [adminMove] admin_move
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesSetPositionCreate: async (id: number, x: number, y: number, adminMove?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesSetPositionCreate', 'id', id)
            // verify required parameter 'x' is not null or undefined
            assertParamExists('allPurchasesSetPositionCreate', 'x', x)
            // verify required parameter 'y' is not null or undefined
            assertParamExists('allPurchasesSetPositionCreate', 'y', y)
            const localVarPath = `/all/purchases/{id}/set_position/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (adminMove !== undefined) {
                localVarQueryParameter['admin_move'] = adminMove;
            }

            if (x !== undefined) {
                localVarQueryParameter['x'] = x;
            }

            if (y !== undefined) {
                localVarQueryParameter['y'] = y;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {number} nationId nationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesTransferPurchaseCreate: async (id: number, nationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allPurchasesTransferPurchaseCreate', 'id', id)
            // verify required parameter 'nationId' is not null or undefined
            assertParamExists('allPurchasesTransferPurchaseCreate', 'nationId', nationId)
            const localVarPath = `/all/purchases/{id}/transfer_purchase/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (nationId !== undefined) {
                localVarQueryParameter['nationId'] = nationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allResourceTypesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/resourceTypes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allResourceTypesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allResourceTypesRetrieve', 'id', id)
            const localVarPath = `/all/resourceTypes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRolesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRolesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allRolesRetrieve', 'id', id)
            const localVarPath = `/all/roles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allSubpoenasCancelCreate', 'id', id)
            const localVarPath = `/all/subpoenas/{id}/cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubpoenaRequest} subpoenaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasCreate: async (subpoenaRequest: SubpoenaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subpoenaRequest' is not null or undefined
            assertParamExists('allSubpoenasCreate', 'subpoenaRequest', subpoenaRequest)
            const localVarPath = `/all/subpoenas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subpoenaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/subpoenas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasReceiverAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allSubpoenasReceiverAcceptCreate', 'id', id)
            const localVarPath = `/all/subpoenas/{id}/receiver_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasReceiverCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allSubpoenasReceiverCancelCreate', 'id', id)
            const localVarPath = `/all/subpoenas/{id}/receiver_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasReceiverDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allSubpoenasReceiverDeclineCreate', 'id', id)
            const localVarPath = `/all/subpoenas/{id}/receiver_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allSubpoenasRetrieve', 'id', id)
            const localVarPath = `/all/subpoenas/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasSenderAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allSubpoenasSenderAcceptCreate', 'id', id)
            const localVarPath = `/all/subpoenas/{id}/sender_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasSenderCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allSubpoenasSenderCancelCreate', 'id', id)
            const localVarPath = `/all/subpoenas/{id}/sender_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasSenderDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allSubpoenasSenderDeclineCreate', 'id', id)
            const localVarPath = `/all/subpoenas/{id}/sender_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SuggestionRequest} suggestionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSuggestionsCreate: async (suggestionRequest: SuggestionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'suggestionRequest' is not null or undefined
            assertParamExists('allSuggestionsCreate', 'suggestionRequest', suggestionRequest)
            const localVarPath = `/all/suggestions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suggestionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradeAlliancesCancelCreate', 'id', id)
            const localVarPath = `/all/tradeAlliances/{id}/cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TradeAllianceRequest} tradeAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesCreate: async (tradeAllianceRequest: TradeAllianceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradeAllianceRequest' is not null or undefined
            assertParamExists('allTradeAlliancesCreate', 'tradeAllianceRequest', tradeAllianceRequest)
            const localVarPath = `/all/tradeAlliances/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradeAllianceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/tradeAlliances/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesReceiverAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradeAlliancesReceiverAcceptCreate', 'id', id)
            const localVarPath = `/all/tradeAlliances/{id}/receiver_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesReceiverCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradeAlliancesReceiverCancelCreate', 'id', id)
            const localVarPath = `/all/tradeAlliances/{id}/receiver_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesReceiverDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradeAlliancesReceiverDeclineCreate', 'id', id)
            const localVarPath = `/all/tradeAlliances/{id}/receiver_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradeAlliancesRetrieve', 'id', id)
            const localVarPath = `/all/tradeAlliances/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesSenderAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradeAlliancesSenderAcceptCreate', 'id', id)
            const localVarPath = `/all/tradeAlliances/{id}/sender_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesSenderCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradeAlliancesSenderCancelCreate', 'id', id)
            const localVarPath = `/all/tradeAlliances/{id}/sender_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesSenderDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradeAlliancesSenderDeclineCreate', 'id', id)
            const localVarPath = `/all/tradeAlliances/{id}/sender_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeStatusList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/tradeStatus/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeStatusRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradeStatusRetrieve', 'id', id)
            const localVarPath = `/all/tradeStatus/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradesCancelCreate', 'id', id)
            const localVarPath = `/all/trades/{id}/cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TradeRequest} tradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesCreate: async (tradeRequest: TradeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradeRequest' is not null or undefined
            assertParamExists('allTradesCreate', 'tradeRequest', tradeRequest)
            const localVarPath = `/all/trades/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/trades/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesReceiverAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradesReceiverAcceptCreate', 'id', id)
            const localVarPath = `/all/trades/{id}/receiver_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesReceiverCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradesReceiverCancelCreate', 'id', id)
            const localVarPath = `/all/trades/{id}/receiver_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesReceiverDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradesReceiverDeclineCreate', 'id', id)
            const localVarPath = `/all/trades/{id}/receiver_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradesRetrieve', 'id', id)
            const localVarPath = `/all/trades/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesSenderAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradesSenderAcceptCreate', 'id', id)
            const localVarPath = `/all/trades/{id}/sender_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesSenderCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradesSenderCancelCreate', 'id', id)
            const localVarPath = `/all/trades/{id}/sender_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesSenderDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTradesSenderDeclineCreate', 'id', id)
            const localVarPath = `/all/trades/{id}/sender_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TutorialRequest} tutorialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTutorialCreate: async (tutorialRequest: TutorialRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tutorialRequest' is not null or undefined
            assertParamExists('allTutorialCreate', 'tutorialRequest', tutorialRequest)
            const localVarPath = `/all/tutorial/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tutorialRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTutorialList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/tutorial/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tutorial.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTutorialRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allTutorialRetrieve', 'id', id)
            const localVarPath = `/all/tutorial/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserClassesCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/userClasses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserClassesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/userClasses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserClassesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allUserClassesRetrieve', 'id', id)
            const localVarPath = `/all/userClasses/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserClassesSwitchAccountCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allUserClassesSwitchAccountCreate', 'id', id)
            const localVarPath = `/all/userClasses/{id}/switch_account/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserNotifsRetrieve: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/user_notifs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUsersList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {PatchedUserClassRequest} [patchedUserClassRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUsersPartialUpdate: async (id: number, patchedUserClassRequest?: PatchedUserClassRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allUsersPartialUpdate', 'id', id)
            const localVarPath = `/all/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUserClassRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUsersRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allUsersRetrieve', 'id', id)
            const localVarPath = `/all/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WaitlistRequest} waitlistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWaitlistsCreate: async (waitlistRequest: WaitlistRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'waitlistRequest' is not null or undefined
            assertParamExists('allWaitlistsCreate', 'waitlistRequest', waitlistRequest)
            const localVarPath = `/all/waitlists/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(waitlistRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarningsCancelCreate', 'id', id)
            const localVarPath = `/all/warnings/{id}/cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WarningRequest} warningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsCreate: async (warningRequest: WarningRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warningRequest' is not null or undefined
            assertParamExists('allWarningsCreate', 'warningRequest', warningRequest)
            const localVarPath = `/all/warnings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warningRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/warnings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsReceiverAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarningsReceiverAcceptCreate', 'id', id)
            const localVarPath = `/all/warnings/{id}/receiver_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsReceiverCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarningsReceiverCancelCreate', 'id', id)
            const localVarPath = `/all/warnings/{id}/receiver_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsReceiverDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarningsReceiverDeclineCreate', 'id', id)
            const localVarPath = `/all/warnings/{id}/receiver_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarningsRetrieve', 'id', id)
            const localVarPath = `/all/warnings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsSenderAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarningsSenderAcceptCreate', 'id', id)
            const localVarPath = `/all/warnings/{id}/sender_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsSenderCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarningsSenderCancelCreate', 'id', id)
            const localVarPath = `/all/warnings/{id}/sender_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsSenderDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarningsSenderDeclineCreate', 'id', id)
            const localVarPath = `/all/warnings/{id}/sender_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarsCancelCreate', 'id', id)
            const localVarPath = `/all/wars/{id}/cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WarRequest} warRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsCreate: async (warRequest: WarRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warRequest' is not null or undefined
            assertParamExists('allWarsCreate', 'warRequest', warRequest)
            const localVarPath = `/all/wars/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/all/wars/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsReceiverAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarsReceiverAcceptCreate', 'id', id)
            const localVarPath = `/all/wars/{id}/receiver_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsReceiverCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarsReceiverCancelCreate', 'id', id)
            const localVarPath = `/all/wars/{id}/receiver_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsReceiverDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarsReceiverDeclineCreate', 'id', id)
            const localVarPath = `/all/wars/{id}/receiver_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarsRetrieve', 'id', id)
            const localVarPath = `/all/wars/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsSenderAcceptCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarsSenderAcceptCreate', 'id', id)
            const localVarPath = `/all/wars/{id}/sender_accept/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsSenderCancelCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarsSenderCancelCreate', 'id', id)
            const localVarPath = `/all/wars/{id}/sender_cancel/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsSenderDeclineCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allWarsSenderDeclineCreate', 'id', id)
            const localVarPath = `/all/wars/{id}/sender_decline/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllApi - functional programming interface
 * @export
 */
export const AllApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ArticleRequest} articleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allArticlesCreate(articleRequest: ArticleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allArticlesCreate(articleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allArticlesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Article>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allArticlesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {PatchedArticleRequest} [patchedArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allArticlesPartialUpdate(id: number, patchedArticleRequest?: PatchedArticleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allArticlesPartialUpdate(id, patchedArticleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allArticlesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allArticlesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allAttacksCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attack>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allAttacksCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AttackRequest} attackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allAttacksCreate(attackRequest: AttackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attack>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allAttacksCreate(attackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allAttacksList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Attack>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allAttacksList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allAttacksReceiverAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attack>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allAttacksReceiverAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allAttacksReceiverCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attack>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allAttacksReceiverCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allAttacksReceiverDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attack>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allAttacksReceiverDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allAttacksRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attack>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allAttacksRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allAttacksSenderAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attack>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allAttacksSenderAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allAttacksSenderCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attack>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allAttacksSenderCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allAttacksSenderDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attack>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allAttacksSenderDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CommentRequest} commentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allCommentsCreate(commentRequest: CommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allCommentsCreate(commentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allCommentsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Comment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allCommentsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allCommentsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allCommentsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allCompleteSessionCreate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allCompleteSessionCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accessToken access_token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allGoogleLoginCreate(accessToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allGoogleLoginCreate(accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InviteRequest} inviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allInviteCreate(inviteRequest: InviteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allInviteCreate(inviteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allInviteGetByCodeRetrieve(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allInviteGetByCodeRetrieve(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allInviteList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invite>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allInviteList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this invite.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allInviteRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allInviteRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UseinvitecodeserializerRequest} useinvitecodeserializerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allInviteUseInviteCodeCreate(useinvitecodeserializerRequest: UseinvitecodeserializerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allInviteUseInviteCodeCreate(useinvitecodeserializerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allItemsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Item>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allItemsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allItemsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allItemsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginserializerRequest} loginserializerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allLoginCreate(loginserializerRequest: LoginserializerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allLoginCreate(loginserializerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allMilitaryAlliancesCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allMilitaryAlliancesCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MilitaryAllianceRequest} militaryAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allMilitaryAlliancesCreate(militaryAllianceRequest: MilitaryAllianceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allMilitaryAlliancesCreate(militaryAllianceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allMilitaryAlliancesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MilitaryAlliance>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allMilitaryAlliancesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allMilitaryAlliancesReceiverAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allMilitaryAlliancesReceiverAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allMilitaryAlliancesReceiverCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allMilitaryAlliancesReceiverCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allMilitaryAlliancesReceiverDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allMilitaryAlliancesReceiverDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allMilitaryAlliancesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allMilitaryAlliancesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allMilitaryAlliancesSenderAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allMilitaryAlliancesSenderAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allMilitaryAlliancesSenderCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allMilitaryAlliancesSenderCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allMilitaryAlliancesSenderDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allMilitaryAlliancesSenderDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allNationStatsRetrieve(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NationsStat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allNationStatsRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NationsRequest} nationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allNationsCreate(nationsRequest: NationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Nations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allNationsCreate(nationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allNationsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Nations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allNationsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {PatchedNationsRequest} [patchedNationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allNationsPartialUpdate(id: number, patchedNationsRequest?: PatchedNationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Nations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allNationsPartialUpdate(id, patchedNationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allNationsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Nations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allNationsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} borders borders
         * @param {number} id A unique integer value identifying this nation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allNationsSaveBordersCreate(borders: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Nations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allNationsSaveBordersCreate(borders, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NotificationRequest} notificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allNotificationsCreate(notificationRequest: NotificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allNotificationsCreate(notificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allNotificationsList(page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedNotificationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allNotificationsList(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allNotificationsMarkAsReadCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allNotificationsMarkAsReadCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allNotificationsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allNotificationsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPermissionsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Permission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPermissionsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPermissionsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Permission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPermissionsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} depth depth
         * @param {number} x x
         * @param {number} y y
         * @param {number} [controlledBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesAdjacentList(depth: number, x: number, y: number, controlledBy?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Purchase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesAdjacentList(depth, x, y, controlledBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PurchaseRequest} purchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesCreate(purchaseRequest: PurchaseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesCreate(purchaseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesDeletePurchaseCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesDeletePurchaseCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesDestroyPurchaseCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesDestroyPurchaseCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [controlledBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesList(controlledBy?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Purchase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesList(controlledBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesRebuildPurchaseCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesRebuildPurchaseCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesReceiverAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesReceiverAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesReceiverCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesReceiverCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesReceiverDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesReceiverDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesSenderAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesSenderAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesSenderCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesSenderCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesSenderDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesSenderDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesSetNameCreate(id: number, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesSetNameCreate(id, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {number} x x
         * @param {number} y y
         * @param {boolean} [adminMove] admin_move
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesSetPositionCreate(id: number, x: number, y: number, adminMove?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesSetPositionCreate(id, x, y, adminMove, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {number} nationId nationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allPurchasesTransferPurchaseCreate(id: number, nationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allPurchasesTransferPurchaseCreate(id, nationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allResourceTypesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourceType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allResourceTypesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allResourceTypesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allResourceTypesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allRolesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allRolesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allRolesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allRolesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSubpoenasCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subpoena>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allSubpoenasCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SubpoenaRequest} subpoenaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSubpoenasCreate(subpoenaRequest: SubpoenaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subpoena>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allSubpoenasCreate(subpoenaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSubpoenasList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Subpoena>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allSubpoenasList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSubpoenasReceiverAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subpoena>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allSubpoenasReceiverAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSubpoenasReceiverCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subpoena>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allSubpoenasReceiverCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSubpoenasReceiverDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subpoena>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allSubpoenasReceiverDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSubpoenasRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subpoena>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allSubpoenasRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSubpoenasSenderAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subpoena>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allSubpoenasSenderAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSubpoenasSenderCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subpoena>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allSubpoenasSenderCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSubpoenasSenderDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subpoena>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allSubpoenasSenderDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SuggestionRequest} suggestionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allSuggestionsCreate(suggestionRequest: SuggestionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Suggestion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allSuggestionsCreate(suggestionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradeAlliancesCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradeAlliancesCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TradeAllianceRequest} tradeAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradeAlliancesCreate(tradeAllianceRequest: TradeAllianceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradeAlliancesCreate(tradeAllianceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradeAlliancesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradeAlliance>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradeAlliancesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradeAlliancesReceiverAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradeAlliancesReceiverAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradeAlliancesReceiverCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradeAlliancesReceiverCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradeAlliancesReceiverDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradeAlliancesReceiverDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradeAlliancesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradeAlliancesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradeAlliancesSenderAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradeAlliancesSenderAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradeAlliancesSenderCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradeAlliancesSenderCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradeAlliancesSenderDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradeAlliancesSenderDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradeStatusList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradeStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradeStatusList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradeStatusRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradeStatusRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradesCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradesCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TradeRequest} tradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradesCreate(tradeRequest: TradeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradesCreate(tradeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Trade>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradesReceiverAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradesReceiverAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradesReceiverCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradesReceiverCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradesReceiverDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradesReceiverDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradesSenderAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradesSenderAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradesSenderCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradesSenderCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTradesSenderDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTradesSenderDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TutorialRequest} tutorialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTutorialCreate(tutorialRequest: TutorialRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tutorial>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTutorialCreate(tutorialRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTutorialList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tutorial>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTutorialList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tutorial.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allTutorialRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tutorial>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allTutorialRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUserClassesCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserClasses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allUserClassesCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUserClassesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserClasses>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allUserClassesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUserClassesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserClasses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allUserClassesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUserClassesSwitchAccountCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserClasses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allUserClassesSwitchAccountCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUserNotifsRetrieve(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notif>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allUserNotifsRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUsersList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserClass>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allUsersList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {PatchedUserClassRequest} [patchedUserClassRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUsersPartialUpdate(id: number, patchedUserClassRequest?: PatchedUserClassRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allUsersPartialUpdate(id, patchedUserClassRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUsersRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allUsersRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WaitlistRequest} waitlistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWaitlistsCreate(waitlistRequest: WaitlistRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Waitlist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWaitlistsCreate(waitlistRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarningsCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Warning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarningsCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WarningRequest} warningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarningsCreate(warningRequest: WarningRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Warning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarningsCreate(warningRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarningsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Warning>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarningsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarningsReceiverAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Warning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarningsReceiverAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarningsReceiverCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Warning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarningsReceiverCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarningsReceiverDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Warning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarningsReceiverDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarningsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Warning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarningsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarningsSenderAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Warning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarningsSenderAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarningsSenderCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Warning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarningsSenderCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarningsSenderDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Warning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarningsSenderDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarsCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<War>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarsCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WarRequest} warRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarsCreate(warRequest: WarRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<War>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarsCreate(warRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<War>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarsReceiverAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<War>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarsReceiverAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarsReceiverCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<War>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarsReceiverCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarsReceiverDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<War>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarsReceiverDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<War>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarsSenderAcceptCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<War>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarsSenderAcceptCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarsSenderCancelCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<War>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarsSenderCancelCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWarsSenderDeclineCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<War>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWarsSenderDeclineCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllApi - factory interface
 * @export
 */
export const AllApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllApiFp(configuration)
    return {
        /**
         * 
         * @param {ArticleRequest} articleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allArticlesCreate(articleRequest: ArticleRequest, options?: any): AxiosPromise<Article> {
            return localVarFp.allArticlesCreate(articleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allArticlesList(options?: any): AxiosPromise<Array<Article>> {
            return localVarFp.allArticlesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {PatchedArticleRequest} [patchedArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allArticlesPartialUpdate(id: number, patchedArticleRequest?: PatchedArticleRequest, options?: any): AxiosPromise<Article> {
            return localVarFp.allArticlesPartialUpdate(id, patchedArticleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allArticlesRetrieve(id: number, options?: any): AxiosPromise<Article> {
            return localVarFp.allArticlesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksCancelCreate(id: number, options?: any): AxiosPromise<Attack> {
            return localVarFp.allAttacksCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AttackRequest} attackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksCreate(attackRequest: AttackRequest, options?: any): AxiosPromise<Attack> {
            return localVarFp.allAttacksCreate(attackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksList(options?: any): AxiosPromise<Array<Attack>> {
            return localVarFp.allAttacksList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksReceiverAcceptCreate(id: number, options?: any): AxiosPromise<Attack> {
            return localVarFp.allAttacksReceiverAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksReceiverCancelCreate(id: number, options?: any): AxiosPromise<Attack> {
            return localVarFp.allAttacksReceiverCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksReceiverDeclineCreate(id: number, options?: any): AxiosPromise<Attack> {
            return localVarFp.allAttacksReceiverDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksRetrieve(id: number, options?: any): AxiosPromise<Attack> {
            return localVarFp.allAttacksRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksSenderAcceptCreate(id: number, options?: any): AxiosPromise<Attack> {
            return localVarFp.allAttacksSenderAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksSenderCancelCreate(id: number, options?: any): AxiosPromise<Attack> {
            return localVarFp.allAttacksSenderCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attack.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAttacksSenderDeclineCreate(id: number, options?: any): AxiosPromise<Attack> {
            return localVarFp.allAttacksSenderDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CommentRequest} commentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCommentsCreate(commentRequest: CommentRequest, options?: any): AxiosPromise<Comment> {
            return localVarFp.allCommentsCreate(commentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCommentsList(options?: any): AxiosPromise<Array<Comment>> {
            return localVarFp.allCommentsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCommentsRetrieve(id: number, options?: any): AxiosPromise<Comment> {
            return localVarFp.allCommentsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCompleteSessionCreate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.allCompleteSessionCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accessToken access_token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allGoogleLoginCreate(accessToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.allGoogleLoginCreate(accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InviteRequest} inviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allInviteCreate(inviteRequest: InviteRequest, options?: any): AxiosPromise<Invite> {
            return localVarFp.allInviteCreate(inviteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allInviteGetByCodeRetrieve(code: string, options?: any): AxiosPromise<Invite> {
            return localVarFp.allInviteGetByCodeRetrieve(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allInviteList(options?: any): AxiosPromise<Array<Invite>> {
            return localVarFp.allInviteList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this invite.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allInviteRetrieve(id: number, options?: any): AxiosPromise<Invite> {
            return localVarFp.allInviteRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UseinvitecodeserializerRequest} useinvitecodeserializerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allInviteUseInviteCodeCreate(useinvitecodeserializerRequest: UseinvitecodeserializerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.allInviteUseInviteCodeCreate(useinvitecodeserializerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allItemsList(options?: any): AxiosPromise<Array<Item>> {
            return localVarFp.allItemsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allItemsRetrieve(id: number, options?: any): AxiosPromise<Item> {
            return localVarFp.allItemsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginserializerRequest} loginserializerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allLoginCreate(loginserializerRequest: LoginserializerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.allLoginCreate(loginserializerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesCancelCreate(id: number, options?: any): AxiosPromise<MilitaryAlliance> {
            return localVarFp.allMilitaryAlliancesCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MilitaryAllianceRequest} militaryAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesCreate(militaryAllianceRequest: MilitaryAllianceRequest, options?: any): AxiosPromise<MilitaryAlliance> {
            return localVarFp.allMilitaryAlliancesCreate(militaryAllianceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesList(options?: any): AxiosPromise<Array<MilitaryAlliance>> {
            return localVarFp.allMilitaryAlliancesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesReceiverAcceptCreate(id: number, options?: any): AxiosPromise<MilitaryAlliance> {
            return localVarFp.allMilitaryAlliancesReceiverAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesReceiverCancelCreate(id: number, options?: any): AxiosPromise<MilitaryAlliance> {
            return localVarFp.allMilitaryAlliancesReceiverCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesReceiverDeclineCreate(id: number, options?: any): AxiosPromise<MilitaryAlliance> {
            return localVarFp.allMilitaryAlliancesReceiverDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesRetrieve(id: number, options?: any): AxiosPromise<MilitaryAlliance> {
            return localVarFp.allMilitaryAlliancesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesSenderAcceptCreate(id: number, options?: any): AxiosPromise<MilitaryAlliance> {
            return localVarFp.allMilitaryAlliancesSenderAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesSenderCancelCreate(id: number, options?: any): AxiosPromise<MilitaryAlliance> {
            return localVarFp.allMilitaryAlliancesSenderCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMilitaryAlliancesSenderDeclineCreate(id: number, options?: any): AxiosPromise<MilitaryAlliance> {
            return localVarFp.allMilitaryAlliancesSenderDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNationStatsRetrieve(options?: any): AxiosPromise<NationsStat> {
            return localVarFp.allNationStatsRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NationsRequest} nationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNationsCreate(nationsRequest: NationsRequest, options?: any): AxiosPromise<Nations> {
            return localVarFp.allNationsCreate(nationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNationsList(options?: any): AxiosPromise<Array<Nations>> {
            return localVarFp.allNationsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {PatchedNationsRequest} [patchedNationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNationsPartialUpdate(id: number, patchedNationsRequest?: PatchedNationsRequest, options?: any): AxiosPromise<Nations> {
            return localVarFp.allNationsPartialUpdate(id, patchedNationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNationsRetrieve(id: number, options?: any): AxiosPromise<Nations> {
            return localVarFp.allNationsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} borders borders
         * @param {number} id A unique integer value identifying this nation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNationsSaveBordersCreate(borders: string, id: number, options?: any): AxiosPromise<Nations> {
            return localVarFp.allNationsSaveBordersCreate(borders, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationRequest} notificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNotificationsCreate(notificationRequest: NotificationRequest, options?: any): AxiosPromise<Notification> {
            return localVarFp.allNotificationsCreate(notificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNotificationsList(page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedNotificationList> {
            return localVarFp.allNotificationsList(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNotificationsMarkAsReadCreate(id: number, options?: any): AxiosPromise<Notification> {
            return localVarFp.allNotificationsMarkAsReadCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allNotificationsRetrieve(id: number, options?: any): AxiosPromise<Notification> {
            return localVarFp.allNotificationsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPermissionsList(options?: any): AxiosPromise<Array<Permission>> {
            return localVarFp.allPermissionsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPermissionsRetrieve(id: number, options?: any): AxiosPromise<Permission> {
            return localVarFp.allPermissionsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} depth depth
         * @param {number} x x
         * @param {number} y y
         * @param {number} [controlledBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesAdjacentList(depth: number, x: number, y: number, controlledBy?: number, options?: any): AxiosPromise<Array<Purchase>> {
            return localVarFp.allPurchasesAdjacentList(depth, x, y, controlledBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesCancelCreate(id: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PurchaseRequest} purchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesCreate(purchaseRequest: PurchaseRequest, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesCreate(purchaseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesDeletePurchaseCreate(id: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesDeletePurchaseCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesDestroyPurchaseCreate(id: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesDestroyPurchaseCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [controlledBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesList(controlledBy?: number, options?: any): AxiosPromise<Array<Purchase>> {
            return localVarFp.allPurchasesList(controlledBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesRebuildPurchaseCreate(id: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesRebuildPurchaseCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesReceiverAcceptCreate(id: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesReceiverAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesReceiverCancelCreate(id: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesReceiverCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesReceiverDeclineCreate(id: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesReceiverDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesRetrieve(id: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesSenderAcceptCreate(id: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesSenderAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesSenderCancelCreate(id: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesSenderCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesSenderDeclineCreate(id: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesSenderDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesSetNameCreate(id: number, name: string, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesSetNameCreate(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {number} x x
         * @param {number} y y
         * @param {boolean} [adminMove] admin_move
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesSetPositionCreate(id: number, x: number, y: number, adminMove?: boolean, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesSetPositionCreate(id, x, y, adminMove, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {number} nationId nationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allPurchasesTransferPurchaseCreate(id: number, nationId: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.allPurchasesTransferPurchaseCreate(id, nationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allResourceTypesList(options?: any): AxiosPromise<Array<ResourceType>> {
            return localVarFp.allResourceTypesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allResourceTypesRetrieve(id: number, options?: any): AxiosPromise<ResourceType> {
            return localVarFp.allResourceTypesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRolesList(options?: any): AxiosPromise<Array<Role>> {
            return localVarFp.allRolesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRolesRetrieve(id: number, options?: any): AxiosPromise<Role> {
            return localVarFp.allRolesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasCancelCreate(id: number, options?: any): AxiosPromise<Subpoena> {
            return localVarFp.allSubpoenasCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubpoenaRequest} subpoenaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasCreate(subpoenaRequest: SubpoenaRequest, options?: any): AxiosPromise<Subpoena> {
            return localVarFp.allSubpoenasCreate(subpoenaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasList(options?: any): AxiosPromise<Array<Subpoena>> {
            return localVarFp.allSubpoenasList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasReceiverAcceptCreate(id: number, options?: any): AxiosPromise<Subpoena> {
            return localVarFp.allSubpoenasReceiverAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasReceiverCancelCreate(id: number, options?: any): AxiosPromise<Subpoena> {
            return localVarFp.allSubpoenasReceiverCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasReceiverDeclineCreate(id: number, options?: any): AxiosPromise<Subpoena> {
            return localVarFp.allSubpoenasReceiverDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasRetrieve(id: number, options?: any): AxiosPromise<Subpoena> {
            return localVarFp.allSubpoenasRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasSenderAcceptCreate(id: number, options?: any): AxiosPromise<Subpoena> {
            return localVarFp.allSubpoenasSenderAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasSenderCancelCreate(id: number, options?: any): AxiosPromise<Subpoena> {
            return localVarFp.allSubpoenasSenderCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSubpoenasSenderDeclineCreate(id: number, options?: any): AxiosPromise<Subpoena> {
            return localVarFp.allSubpoenasSenderDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SuggestionRequest} suggestionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allSuggestionsCreate(suggestionRequest: SuggestionRequest, options?: any): AxiosPromise<Suggestion> {
            return localVarFp.allSuggestionsCreate(suggestionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesCancelCreate(id: number, options?: any): AxiosPromise<TradeAlliance> {
            return localVarFp.allTradeAlliancesCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TradeAllianceRequest} tradeAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesCreate(tradeAllianceRequest: TradeAllianceRequest, options?: any): AxiosPromise<TradeAlliance> {
            return localVarFp.allTradeAlliancesCreate(tradeAllianceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesList(options?: any): AxiosPromise<Array<TradeAlliance>> {
            return localVarFp.allTradeAlliancesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesReceiverAcceptCreate(id: number, options?: any): AxiosPromise<TradeAlliance> {
            return localVarFp.allTradeAlliancesReceiverAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesReceiverCancelCreate(id: number, options?: any): AxiosPromise<TradeAlliance> {
            return localVarFp.allTradeAlliancesReceiverCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesReceiverDeclineCreate(id: number, options?: any): AxiosPromise<TradeAlliance> {
            return localVarFp.allTradeAlliancesReceiverDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesRetrieve(id: number, options?: any): AxiosPromise<TradeAlliance> {
            return localVarFp.allTradeAlliancesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesSenderAcceptCreate(id: number, options?: any): AxiosPromise<TradeAlliance> {
            return localVarFp.allTradeAlliancesSenderAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesSenderCancelCreate(id: number, options?: any): AxiosPromise<TradeAlliance> {
            return localVarFp.allTradeAlliancesSenderCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeAlliancesSenderDeclineCreate(id: number, options?: any): AxiosPromise<TradeAlliance> {
            return localVarFp.allTradeAlliancesSenderDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeStatusList(options?: any): AxiosPromise<Array<TradeStatus>> {
            return localVarFp.allTradeStatusList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradeStatusRetrieve(id: number, options?: any): AxiosPromise<TradeStatus> {
            return localVarFp.allTradeStatusRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesCancelCreate(id: number, options?: any): AxiosPromise<Trade> {
            return localVarFp.allTradesCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TradeRequest} tradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesCreate(tradeRequest: TradeRequest, options?: any): AxiosPromise<Trade> {
            return localVarFp.allTradesCreate(tradeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesList(options?: any): AxiosPromise<Array<Trade>> {
            return localVarFp.allTradesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesReceiverAcceptCreate(id: number, options?: any): AxiosPromise<Trade> {
            return localVarFp.allTradesReceiverAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesReceiverCancelCreate(id: number, options?: any): AxiosPromise<Trade> {
            return localVarFp.allTradesReceiverCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesReceiverDeclineCreate(id: number, options?: any): AxiosPromise<Trade> {
            return localVarFp.allTradesReceiverDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesRetrieve(id: number, options?: any): AxiosPromise<Trade> {
            return localVarFp.allTradesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesSenderAcceptCreate(id: number, options?: any): AxiosPromise<Trade> {
            return localVarFp.allTradesSenderAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesSenderCancelCreate(id: number, options?: any): AxiosPromise<Trade> {
            return localVarFp.allTradesSenderCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTradesSenderDeclineCreate(id: number, options?: any): AxiosPromise<Trade> {
            return localVarFp.allTradesSenderDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TutorialRequest} tutorialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTutorialCreate(tutorialRequest: TutorialRequest, options?: any): AxiosPromise<Tutorial> {
            return localVarFp.allTutorialCreate(tutorialRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTutorialList(options?: any): AxiosPromise<Array<Tutorial>> {
            return localVarFp.allTutorialList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tutorial.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allTutorialRetrieve(id: number, options?: any): AxiosPromise<Tutorial> {
            return localVarFp.allTutorialRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserClassesCreate(options?: any): AxiosPromise<UserClasses> {
            return localVarFp.allUserClassesCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserClassesList(options?: any): AxiosPromise<Array<UserClasses>> {
            return localVarFp.allUserClassesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserClassesRetrieve(id: number, options?: any): AxiosPromise<UserClasses> {
            return localVarFp.allUserClassesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserClassesSwitchAccountCreate(id: number, options?: any): AxiosPromise<UserClasses> {
            return localVarFp.allUserClassesSwitchAccountCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserNotifsRetrieve(options?: any): AxiosPromise<Notif> {
            return localVarFp.allUserNotifsRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUsersList(options?: any): AxiosPromise<Array<UserClass>> {
            return localVarFp.allUsersList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {PatchedUserClassRequest} [patchedUserClassRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUsersPartialUpdate(id: number, patchedUserClassRequest?: PatchedUserClassRequest, options?: any): AxiosPromise<UserClass> {
            return localVarFp.allUsersPartialUpdate(id, patchedUserClassRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUsersRetrieve(id: number, options?: any): AxiosPromise<UserClass> {
            return localVarFp.allUsersRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WaitlistRequest} waitlistRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWaitlistsCreate(waitlistRequest: WaitlistRequest, options?: any): AxiosPromise<Waitlist> {
            return localVarFp.allWaitlistsCreate(waitlistRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsCancelCreate(id: number, options?: any): AxiosPromise<Warning> {
            return localVarFp.allWarningsCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WarningRequest} warningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsCreate(warningRequest: WarningRequest, options?: any): AxiosPromise<Warning> {
            return localVarFp.allWarningsCreate(warningRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsList(options?: any): AxiosPromise<Array<Warning>> {
            return localVarFp.allWarningsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsReceiverAcceptCreate(id: number, options?: any): AxiosPromise<Warning> {
            return localVarFp.allWarningsReceiverAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsReceiverCancelCreate(id: number, options?: any): AxiosPromise<Warning> {
            return localVarFp.allWarningsReceiverCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsReceiverDeclineCreate(id: number, options?: any): AxiosPromise<Warning> {
            return localVarFp.allWarningsReceiverDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsRetrieve(id: number, options?: any): AxiosPromise<Warning> {
            return localVarFp.allWarningsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsSenderAcceptCreate(id: number, options?: any): AxiosPromise<Warning> {
            return localVarFp.allWarningsSenderAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsSenderCancelCreate(id: number, options?: any): AxiosPromise<Warning> {
            return localVarFp.allWarningsSenderCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarningsSenderDeclineCreate(id: number, options?: any): AxiosPromise<Warning> {
            return localVarFp.allWarningsSenderDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsCancelCreate(id: number, options?: any): AxiosPromise<War> {
            return localVarFp.allWarsCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WarRequest} warRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsCreate(warRequest: WarRequest, options?: any): AxiosPromise<War> {
            return localVarFp.allWarsCreate(warRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsList(options?: any): AxiosPromise<Array<War>> {
            return localVarFp.allWarsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsReceiverAcceptCreate(id: number, options?: any): AxiosPromise<War> {
            return localVarFp.allWarsReceiverAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsReceiverCancelCreate(id: number, options?: any): AxiosPromise<War> {
            return localVarFp.allWarsReceiverCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsReceiverDeclineCreate(id: number, options?: any): AxiosPromise<War> {
            return localVarFp.allWarsReceiverDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsRetrieve(id: number, options?: any): AxiosPromise<War> {
            return localVarFp.allWarsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsSenderAcceptCreate(id: number, options?: any): AxiosPromise<War> {
            return localVarFp.allWarsSenderAcceptCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsSenderCancelCreate(id: number, options?: any): AxiosPromise<War> {
            return localVarFp.allWarsSenderCancelCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWarsSenderDeclineCreate(id: number, options?: any): AxiosPromise<War> {
            return localVarFp.allWarsSenderDeclineCreate(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllApi - object-oriented interface
 * @export
 * @class AllApi
 * @extends {BaseAPI}
 */
export class AllApi extends BaseAPI {
    /**
     * 
     * @param {ArticleRequest} articleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allArticlesCreate(articleRequest: ArticleRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allArticlesCreate(articleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allArticlesList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allArticlesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this article.
     * @param {PatchedArticleRequest} [patchedArticleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allArticlesPartialUpdate(id: number, patchedArticleRequest?: PatchedArticleRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allArticlesPartialUpdate(id, patchedArticleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this article.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allArticlesRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allArticlesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this attack.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allAttacksCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allAttacksCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AttackRequest} attackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allAttacksCreate(attackRequest: AttackRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allAttacksCreate(attackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allAttacksList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allAttacksList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this attack.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allAttacksReceiverAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allAttacksReceiverAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this attack.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allAttacksReceiverCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allAttacksReceiverCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this attack.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allAttacksReceiverDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allAttacksReceiverDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this attack.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allAttacksRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allAttacksRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this attack.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allAttacksSenderAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allAttacksSenderAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this attack.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allAttacksSenderCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allAttacksSenderCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this attack.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allAttacksSenderDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allAttacksSenderDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CommentRequest} commentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allCommentsCreate(commentRequest: CommentRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allCommentsCreate(commentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allCommentsList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allCommentsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this comment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allCommentsRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allCommentsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allCompleteSessionCreate(id: string, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allCompleteSessionCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accessToken access_token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allGoogleLoginCreate(accessToken: string, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allGoogleLoginCreate(accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InviteRequest} inviteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allInviteCreate(inviteRequest: InviteRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allInviteCreate(inviteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allInviteGetByCodeRetrieve(code: string, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allInviteGetByCodeRetrieve(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allInviteList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allInviteList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this invite.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allInviteRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allInviteRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UseinvitecodeserializerRequest} useinvitecodeserializerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allInviteUseInviteCodeCreate(useinvitecodeserializerRequest: UseinvitecodeserializerRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allInviteUseInviteCodeCreate(useinvitecodeserializerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allItemsList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allItemsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allItemsRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allItemsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginserializerRequest} loginserializerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allLoginCreate(loginserializerRequest: LoginserializerRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allLoginCreate(loginserializerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this military alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allMilitaryAlliancesCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allMilitaryAlliancesCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MilitaryAllianceRequest} militaryAllianceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allMilitaryAlliancesCreate(militaryAllianceRequest: MilitaryAllianceRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allMilitaryAlliancesCreate(militaryAllianceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allMilitaryAlliancesList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allMilitaryAlliancesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this military alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allMilitaryAlliancesReceiverAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allMilitaryAlliancesReceiverAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this military alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allMilitaryAlliancesReceiverCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allMilitaryAlliancesReceiverCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this military alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allMilitaryAlliancesReceiverDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allMilitaryAlliancesReceiverDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this military alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allMilitaryAlliancesRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allMilitaryAlliancesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this military alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allMilitaryAlliancesSenderAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allMilitaryAlliancesSenderAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this military alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allMilitaryAlliancesSenderCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allMilitaryAlliancesSenderCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this military alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allMilitaryAlliancesSenderDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allMilitaryAlliancesSenderDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allNationStatsRetrieve(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allNationStatsRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NationsRequest} nationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allNationsCreate(nationsRequest: NationsRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allNationsCreate(nationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allNationsList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allNationsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nation.
     * @param {PatchedNationsRequest} [patchedNationsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allNationsPartialUpdate(id: number, patchedNationsRequest?: PatchedNationsRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allNationsPartialUpdate(id, patchedNationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allNationsRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allNationsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} borders borders
     * @param {number} id A unique integer value identifying this nation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allNationsSaveBordersCreate(borders: string, id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allNationsSaveBordersCreate(borders, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationRequest} notificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allNotificationsCreate(notificationRequest: NotificationRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allNotificationsCreate(notificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allNotificationsList(page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allNotificationsList(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this notification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allNotificationsMarkAsReadCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allNotificationsMarkAsReadCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this notification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allNotificationsRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allNotificationsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPermissionsList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPermissionsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this role permission.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPermissionsRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPermissionsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} depth depth
     * @param {number} x x
     * @param {number} y y
     * @param {number} [controlledBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesAdjacentList(depth: number, x: number, y: number, controlledBy?: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesAdjacentList(depth, x, y, controlledBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PurchaseRequest} purchaseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesCreate(purchaseRequest: PurchaseRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesCreate(purchaseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesDeletePurchaseCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesDeletePurchaseCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesDestroyPurchaseCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesDestroyPurchaseCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [controlledBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesList(controlledBy?: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesList(controlledBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesRebuildPurchaseCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesRebuildPurchaseCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesReceiverAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesReceiverAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesReceiverCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesReceiverCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesReceiverDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesReceiverDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesSenderAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesSenderAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesSenderCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesSenderCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesSenderDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesSenderDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {string} name name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesSetNameCreate(id: number, name: string, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesSetNameCreate(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {number} x x
     * @param {number} y y
     * @param {boolean} [adminMove] admin_move
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesSetPositionCreate(id: number, x: number, y: number, adminMove?: boolean, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesSetPositionCreate(id, x, y, adminMove, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {number} nationId nationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allPurchasesTransferPurchaseCreate(id: number, nationId: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allPurchasesTransferPurchaseCreate(id, nationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allResourceTypesList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allResourceTypesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this resource type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allResourceTypesRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allResourceTypesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allRolesList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allRolesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allRolesRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allRolesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this subpoena.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allSubpoenasCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allSubpoenasCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubpoenaRequest} subpoenaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allSubpoenasCreate(subpoenaRequest: SubpoenaRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allSubpoenasCreate(subpoenaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allSubpoenasList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allSubpoenasList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this subpoena.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allSubpoenasReceiverAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allSubpoenasReceiverAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this subpoena.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allSubpoenasReceiverCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allSubpoenasReceiverCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this subpoena.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allSubpoenasReceiverDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allSubpoenasReceiverDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this subpoena.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allSubpoenasRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allSubpoenasRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this subpoena.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allSubpoenasSenderAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allSubpoenasSenderAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this subpoena.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allSubpoenasSenderCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allSubpoenasSenderCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this subpoena.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allSubpoenasSenderDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allSubpoenasSenderDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SuggestionRequest} suggestionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allSuggestionsCreate(suggestionRequest: SuggestionRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allSuggestionsCreate(suggestionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradeAlliancesCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradeAlliancesCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TradeAllianceRequest} tradeAllianceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradeAlliancesCreate(tradeAllianceRequest: TradeAllianceRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradeAlliancesCreate(tradeAllianceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradeAlliancesList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradeAlliancesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradeAlliancesReceiverAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradeAlliancesReceiverAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradeAlliancesReceiverCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradeAlliancesReceiverCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradeAlliancesReceiverDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradeAlliancesReceiverDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradeAlliancesRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradeAlliancesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradeAlliancesSenderAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradeAlliancesSenderAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradeAlliancesSenderCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradeAlliancesSenderCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradeAlliancesSenderDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradeAlliancesSenderDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradeStatusList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradeStatusList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradeStatusRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradeStatusRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradesCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradesCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TradeRequest} tradeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradesCreate(tradeRequest: TradeRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradesCreate(tradeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradesList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradesReceiverAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradesReceiverAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradesReceiverCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradesReceiverCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradesReceiverDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradesReceiverDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradesRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradesSenderAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradesSenderAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradesSenderCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradesSenderCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTradesSenderDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTradesSenderDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TutorialRequest} tutorialRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTutorialCreate(tutorialRequest: TutorialRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTutorialCreate(tutorialRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTutorialList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTutorialList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this tutorial.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allTutorialRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allTutorialRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allUserClassesCreate(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allUserClassesCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allUserClassesList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allUserClassesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this user class.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allUserClassesRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allUserClassesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this user class.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allUserClassesSwitchAccountCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allUserClassesSwitchAccountCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allUserNotifsRetrieve(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allUserNotifsRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allUsersList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allUsersList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this user class.
     * @param {PatchedUserClassRequest} [patchedUserClassRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allUsersPartialUpdate(id: number, patchedUserClassRequest?: PatchedUserClassRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allUsersPartialUpdate(id, patchedUserClassRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this user class.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allUsersRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allUsersRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WaitlistRequest} waitlistRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWaitlistsCreate(waitlistRequest: WaitlistRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWaitlistsCreate(waitlistRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this warning.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarningsCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarningsCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarningRequest} warningRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarningsCreate(warningRequest: WarningRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarningsCreate(warningRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarningsList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarningsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this warning.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarningsReceiverAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarningsReceiverAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this warning.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarningsReceiverCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarningsReceiverCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this warning.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarningsReceiverDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarningsReceiverDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this warning.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarningsRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarningsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this warning.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarningsSenderAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarningsSenderAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this warning.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarningsSenderCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarningsSenderCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this warning.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarningsSenderDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarningsSenderDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this war.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarsCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarsCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarRequest} warRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarsCreate(warRequest: WarRequest, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarsCreate(warRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarsList(options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this war.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarsReceiverAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarsReceiverAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this war.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarsReceiverCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarsReceiverCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this war.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarsReceiverDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarsReceiverDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this war.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarsRetrieve(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this war.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarsSenderAcceptCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarsSenderAcceptCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this war.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarsSenderCancelCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarsSenderCancelCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this war.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllApi
     */
    public allWarsSenderDeclineCreate(id: number, options?: AxiosRequestConfig) {
        return AllApiFp(this.configuration).allWarsSenderDeclineCreate(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBillingRedirectRetrieve: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billingRedirect/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An Api View which provides a method to reset a password based on a unique token
         * @param {PasswordTokenRequest} passwordTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPasswordResetConfirmCreate: async (passwordTokenRequest: PasswordTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordTokenRequest' is not null or undefined
            assertParamExists('apiPasswordResetConfirmCreate', 'passwordTokenRequest', passwordTokenRequest)
            const localVarPath = `/api/password_reset/confirm/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An Api View which provides a method to request a password reset token based on an e-mail address  Sends a signal reset_password_token_created when a reset token was created
         * @param {EmailRequest} emailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPasswordResetCreate: async (emailRequest: EmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailRequest' is not null or undefined
            assertParamExists('apiPasswordResetCreate', 'emailRequest', emailRequest)
            const localVarPath = `/api/password_reset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An Api View which provides a method to verify that a token is valid
         * @param {ResetTokenRequest} resetTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPasswordResetValidateTokenCreate: async (resetTokenRequest: ResetTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetTokenRequest' is not null or undefined
            assertParamExists('apiPasswordResetValidateTokenCreate', 'resetTokenRequest', resetTokenRequest)
            const localVarPath = `/api/password_reset/validate_token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterRequest} registerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegisterCreate: async (registerRequest: RegisterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerRequest' is not null or undefined
            assertParamExists('apiRegisterCreate', 'registerRequest', registerRequest)
            const localVarPath = `/api/register/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdateNewsCronCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/update_news_cron`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdateNewsCronLocalCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/update_news_cron_local`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdatePopulationCronCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/update_population_cron`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdatePopulationCronLocalCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/update_population_cron_local`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdateResourcesCronCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/update_resources_cron`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdateResourcesCronLocalCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/update_resources_cron_local`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBillingRedirectRetrieve(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBillingRedirectRetrieve(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An Api View which provides a method to reset a password based on a unique token
         * @param {PasswordTokenRequest} passwordTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPasswordResetConfirmCreate(passwordTokenRequest: PasswordTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPasswordResetConfirmCreate(passwordTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An Api View which provides a method to request a password reset token based on an e-mail address  Sends a signal reset_password_token_created when a reset token was created
         * @param {EmailRequest} emailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPasswordResetCreate(emailRequest: EmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Email>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPasswordResetCreate(emailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An Api View which provides a method to verify that a token is valid
         * @param {ResetTokenRequest} resetTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPasswordResetValidateTokenCreate(resetTokenRequest: ResetTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPasswordResetValidateTokenCreate(resetTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterRequest} registerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegisterCreate(registerRequest: RegisterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Register>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegisterCreate(registerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUpdateNewsCronCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUpdateNewsCronCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUpdateNewsCronLocalCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUpdateNewsCronLocalCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUpdatePopulationCronCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUpdatePopulationCronCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUpdatePopulationCronLocalCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUpdatePopulationCronLocalCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUpdateResourcesCronCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUpdateResourcesCronCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUpdateResourcesCronLocalCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUpdateResourcesCronLocalCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBillingRedirectRetrieve(options?: any): AxiosPromise<void> {
            return localVarFp.apiBillingRedirectRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * An Api View which provides a method to reset a password based on a unique token
         * @param {PasswordTokenRequest} passwordTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPasswordResetConfirmCreate(passwordTokenRequest: PasswordTokenRequest, options?: any): AxiosPromise<PasswordToken> {
            return localVarFp.apiPasswordResetConfirmCreate(passwordTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * An Api View which provides a method to request a password reset token based on an e-mail address  Sends a signal reset_password_token_created when a reset token was created
         * @param {EmailRequest} emailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPasswordResetCreate(emailRequest: EmailRequest, options?: any): AxiosPromise<Email> {
            return localVarFp.apiPasswordResetCreate(emailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * An Api View which provides a method to verify that a token is valid
         * @param {ResetTokenRequest} resetTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPasswordResetValidateTokenCreate(resetTokenRequest: ResetTokenRequest, options?: any): AxiosPromise<ResetToken> {
            return localVarFp.apiPasswordResetValidateTokenCreate(resetTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterRequest} registerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegisterCreate(registerRequest: RegisterRequest, options?: any): AxiosPromise<Register> {
            return localVarFp.apiRegisterCreate(registerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdateNewsCronCreate(options?: any): AxiosPromise<void> {
            return localVarFp.apiUpdateNewsCronCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdateNewsCronLocalCreate(options?: any): AxiosPromise<void> {
            return localVarFp.apiUpdateNewsCronLocalCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdatePopulationCronCreate(options?: any): AxiosPromise<void> {
            return localVarFp.apiUpdatePopulationCronCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdatePopulationCronLocalCreate(options?: any): AxiosPromise<void> {
            return localVarFp.apiUpdatePopulationCronLocalCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdateResourcesCronCreate(options?: any): AxiosPromise<void> {
            return localVarFp.apiUpdateResourcesCronCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUpdateResourcesCronLocalCreate(options?: any): AxiosPromise<void> {
            return localVarFp.apiUpdateResourcesCronLocalCreate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiBillingRedirectRetrieve(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiBillingRedirectRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An Api View which provides a method to reset a password based on a unique token
     * @param {PasswordTokenRequest} passwordTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiPasswordResetConfirmCreate(passwordTokenRequest: PasswordTokenRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiPasswordResetConfirmCreate(passwordTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An Api View which provides a method to request a password reset token based on an e-mail address  Sends a signal reset_password_token_created when a reset token was created
     * @param {EmailRequest} emailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiPasswordResetCreate(emailRequest: EmailRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiPasswordResetCreate(emailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An Api View which provides a method to verify that a token is valid
     * @param {ResetTokenRequest} resetTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiPasswordResetValidateTokenCreate(resetTokenRequest: ResetTokenRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiPasswordResetValidateTokenCreate(resetTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterRequest} registerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiRegisterCreate(registerRequest: RegisterRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiRegisterCreate(registerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiUpdateNewsCronCreate(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiUpdateNewsCronCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiUpdateNewsCronLocalCreate(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiUpdateNewsCronLocalCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiUpdatePopulationCronCreate(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiUpdatePopulationCronCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiUpdatePopulationCronLocalCreate(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiUpdatePopulationCronLocalCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiUpdateResourcesCronCreate(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiUpdateResourcesCronCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiUpdateResourcesCronLocalCreate(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiUpdateResourcesCronLocalCreate(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DrfadminApi - axios parameter creator
 * @export
 */
export const DrfadminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminArticleRequest} adminArticleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminArticlesCreate: async (adminArticleRequest: AdminArticleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminArticleRequest' is not null or undefined
            assertParamExists('drfadminArticlesCreate', 'adminArticleRequest', adminArticleRequest)
            const localVarPath = `/drfadmin/articles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminArticleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminArticlesDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminArticlesDestroy', 'id', id)
            const localVarPath = `/drfadmin/articles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminArticlesList: async (className?: number, ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/articles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (className !== undefined) {
                localVarQueryParameter['class_name'] = className;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {PatchedAdminArticleRequest} [patchedAdminArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminArticlesPartialUpdate: async (id: number, patchedAdminArticleRequest?: PatchedAdminArticleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminArticlesPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/articles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedAdminArticleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminArticlesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminArticlesRetrieve', 'id', id)
            const localVarPath = `/drfadmin/articles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {AdminArticleRequest} adminArticleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminArticlesUpdate: async (id: number, adminArticleRequest: AdminArticleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminArticlesUpdate', 'id', id)
            // verify required parameter 'adminArticleRequest' is not null or undefined
            assertParamExists('drfadminArticlesUpdate', 'adminArticleRequest', adminArticleRequest)
            const localVarPath = `/drfadmin/articles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminArticleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminClassNameRequest} adminClassNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminClassesCreate: async (adminClassNameRequest: AdminClassNameRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminClassNameRequest' is not null or undefined
            assertParamExists('drfadminClassesCreate', 'adminClassNameRequest', adminClassNameRequest)
            const localVarPath = `/drfadmin/classes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminClassNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this class name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminClassesDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminClassesDestroy', 'id', id)
            const localVarPath = `/drfadmin/classes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isDemoClass] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminClassesList: async (isDemoClass?: boolean, ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/classes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (isDemoClass !== undefined) {
                localVarQueryParameter['is_demo_class'] = isDemoClass;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this class name.
         * @param {PatchedAdminClassNameRequest} [patchedAdminClassNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminClassesPartialUpdate: async (id: number, patchedAdminClassNameRequest?: PatchedAdminClassNameRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminClassesPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/classes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedAdminClassNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this class name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminClassesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminClassesRetrieve', 'id', id)
            const localVarPath = `/drfadmin/classes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this class name.
         * @param {AdminClassNameRequest} adminClassNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminClassesUpdate: async (id: number, adminClassNameRequest: AdminClassNameRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminClassesUpdate', 'id', id)
            // verify required parameter 'adminClassNameRequest' is not null or undefined
            assertParamExists('drfadminClassesUpdate', 'adminClassNameRequest', adminClassNameRequest)
            const localVarPath = `/drfadmin/classes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminClassNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminCommentRequest} adminCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminCommentsCreate: async (adminCommentRequest: AdminCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCommentRequest' is not null or undefined
            assertParamExists('drfadminCommentsCreate', 'adminCommentRequest', adminCommentRequest)
            const localVarPath = `/drfadmin/comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminCommentsDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminCommentsDestroy', 'id', id)
            const localVarPath = `/drfadmin/comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminCommentsList: async (ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {PatchedAdminCommentRequest} [patchedAdminCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminCommentsPartialUpdate: async (id: number, patchedAdminCommentRequest?: PatchedAdminCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminCommentsPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedAdminCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminCommentsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminCommentsRetrieve', 'id', id)
            const localVarPath = `/drfadmin/comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {AdminCommentRequest} adminCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminCommentsUpdate: async (id: number, adminCommentRequest: AdminCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminCommentsUpdate', 'id', id)
            // verify required parameter 'adminCommentRequest' is not null or undefined
            assertParamExists('drfadminCommentsUpdate', 'adminCommentRequest', adminCommentRequest)
            const localVarPath = `/drfadmin/comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminItemsList: async (ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminItemsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminItemsRetrieve', 'id', id)
            const localVarPath = `/drfadmin/items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MilitaryAllianceRequest} militaryAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminMilitaryAlliancesCreate: async (militaryAllianceRequest: MilitaryAllianceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'militaryAllianceRequest' is not null or undefined
            assertParamExists('drfadminMilitaryAlliancesCreate', 'militaryAllianceRequest', militaryAllianceRequest)
            const localVarPath = `/drfadmin/militaryAlliances/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(militaryAllianceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminMilitaryAlliancesDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminMilitaryAlliancesDestroy', 'id', id)
            const localVarPath = `/drfadmin/militaryAlliances/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminMilitaryAlliancesList: async (className?: number, ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/militaryAlliances/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (className !== undefined) {
                localVarQueryParameter['class_name'] = className;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {PatchedMilitaryAllianceRequest} [patchedMilitaryAllianceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminMilitaryAlliancesPartialUpdate: async (id: number, patchedMilitaryAllianceRequest?: PatchedMilitaryAllianceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminMilitaryAlliancesPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/militaryAlliances/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedMilitaryAllianceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminMilitaryAlliancesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminMilitaryAlliancesRetrieve', 'id', id)
            const localVarPath = `/drfadmin/militaryAlliances/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {MilitaryAllianceRequest} militaryAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminMilitaryAlliancesUpdate: async (id: number, militaryAllianceRequest: MilitaryAllianceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminMilitaryAlliancesUpdate', 'id', id)
            // verify required parameter 'militaryAllianceRequest' is not null or undefined
            assertParamExists('drfadminMilitaryAlliancesUpdate', 'militaryAllianceRequest', militaryAllianceRequest)
            const localVarPath = `/drfadmin/militaryAlliances/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(militaryAllianceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminNationsRequest} adminNationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminNationsCreate: async (adminNationsRequest: AdminNationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminNationsRequest' is not null or undefined
            assertParamExists('drfadminNationsCreate', 'adminNationsRequest', adminNationsRequest)
            const localVarPath = `/drfadmin/nations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminNationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminNationsDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminNationsDestroy', 'id', id)
            const localVarPath = `/drfadmin/nations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminNationsList: async (className?: number, ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/nations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (className !== undefined) {
                localVarQueryParameter['class_name'] = className;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {PatchedAdminNationsRequest} [patchedAdminNationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminNationsPartialUpdate: async (id: number, patchedAdminNationsRequest?: PatchedAdminNationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminNationsPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/nations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedAdminNationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminNationsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminNationsRetrieve', 'id', id)
            const localVarPath = `/drfadmin/nations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {AdminNationsRequest} adminNationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminNationsUpdate: async (id: number, adminNationsRequest: AdminNationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminNationsUpdate', 'id', id)
            // verify required parameter 'adminNationsRequest' is not null or undefined
            assertParamExists('drfadminNationsUpdate', 'adminNationsRequest', adminNationsRequest)
            const localVarPath = `/drfadmin/nations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminNationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPermissionsList: async (ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/permissions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPermissionsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminPermissionsRetrieve', 'id', id)
            const localVarPath = `/drfadmin/permissions/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PopulationChangeEventRequest} populationChangeEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPopulationChangeEventCreate: async (populationChangeEventRequest: PopulationChangeEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'populationChangeEventRequest' is not null or undefined
            assertParamExists('drfadminPopulationChangeEventCreate', 'populationChangeEventRequest', populationChangeEventRequest)
            const localVarPath = `/drfadmin/populationChangeEvent/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(populationChangeEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this population change event.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPopulationChangeEventDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminPopulationChangeEventDestroy', 'id', id)
            const localVarPath = `/drfadmin/populationChangeEvent/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPopulationChangeEventList: async (ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/populationChangeEvent/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this population change event.
         * @param {PatchedPopulationChangeEventRequest} [patchedPopulationChangeEventRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPopulationChangeEventPartialUpdate: async (id: number, patchedPopulationChangeEventRequest?: PatchedPopulationChangeEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminPopulationChangeEventPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/populationChangeEvent/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedPopulationChangeEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this population change event.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPopulationChangeEventRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminPopulationChangeEventRetrieve', 'id', id)
            const localVarPath = `/drfadmin/populationChangeEvent/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this population change event.
         * @param {PopulationChangeEventRequest} populationChangeEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPopulationChangeEventUpdate: async (id: number, populationChangeEventRequest: PopulationChangeEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminPopulationChangeEventUpdate', 'id', id)
            // verify required parameter 'populationChangeEventRequest' is not null or undefined
            assertParamExists('drfadminPopulationChangeEventUpdate', 'populationChangeEventRequest', populationChangeEventRequest)
            const localVarPath = `/drfadmin/populationChangeEvent/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(populationChangeEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PurchaseRequest} purchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesCreate: async (purchaseRequest: PurchaseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseRequest' is not null or undefined
            assertParamExists('drfadminPurchasesCreate', 'purchaseRequest', purchaseRequest)
            const localVarPath = `/drfadmin/purchases/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminPurchasesDestroy', 'id', id)
            const localVarPath = `/drfadmin/purchases/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesGiftCreate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminPurchasesGiftCreate', 'id', id)
            const localVarPath = `/drfadmin/purchases/{id}/gift/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesList: async (ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/purchases/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {PatchedPurchaseRequest} [patchedPurchaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesPartialUpdate: async (id: number, patchedPurchaseRequest?: PatchedPurchaseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminPurchasesPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/purchases/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedPurchaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminPurchasesRetrieve', 'id', id)
            const localVarPath = `/drfadmin/purchases/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {PurchaseRequest} purchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesUpdate: async (id: number, purchaseRequest: PurchaseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminPurchasesUpdate', 'id', id)
            // verify required parameter 'purchaseRequest' is not null or undefined
            assertParamExists('drfadminPurchasesUpdate', 'purchaseRequest', purchaseRequest)
            const localVarPath = `/drfadmin/purchases/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourceTypesCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/resourceTypes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourceTypesDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminResourceTypesDestroy', 'id', id)
            const localVarPath = `/drfadmin/resourceTypes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourceTypesList: async (ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/resourceTypes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourceTypesPartialUpdate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminResourceTypesPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/resourceTypes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourceTypesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminResourceTypesRetrieve', 'id', id)
            const localVarPath = `/drfadmin/resourceTypes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourceTypesUpdate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminResourceTypesUpdate', 'id', id)
            const localVarPath = `/drfadmin/resourceTypes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResourceRequest} resourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourcesCreate: async (resourceRequest: ResourceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceRequest' is not null or undefined
            assertParamExists('drfadminResourcesCreate', 'resourceRequest', resourceRequest)
            const localVarPath = `/drfadmin/resources/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourcesDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminResourcesDestroy', 'id', id)
            const localVarPath = `/drfadmin/resources/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourcesList: async (ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/resources/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource.
         * @param {PatchedResourceRequest} [patchedResourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourcesPartialUpdate: async (id: number, patchedResourceRequest?: PatchedResourceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminResourcesPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/resources/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedResourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourcesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminResourcesRetrieve', 'id', id)
            const localVarPath = `/drfadmin/resources/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource.
         * @param {ResourceRequest} resourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourcesUpdate: async (id: number, resourceRequest: ResourceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminResourcesUpdate', 'id', id)
            // verify required parameter 'resourceRequest' is not null or undefined
            assertParamExists('drfadminResourcesUpdate', 'resourceRequest', resourceRequest)
            const localVarPath = `/drfadmin/resources/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminRolePermissionsList: async (ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/rolePermissions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminRolePermissionsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminRolePermissionsRetrieve', 'id', id)
            const localVarPath = `/drfadmin/rolePermissions/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminRolesList: async (ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminRolesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminRolesRetrieve', 'id', id)
            const localVarPath = `/drfadmin/roles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubpoenaRequest} subpoenaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminSubpoenasCreate: async (subpoenaRequest: SubpoenaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subpoenaRequest' is not null or undefined
            assertParamExists('drfadminSubpoenasCreate', 'subpoenaRequest', subpoenaRequest)
            const localVarPath = `/drfadmin/subpoenas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subpoenaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminSubpoenasDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminSubpoenasDestroy', 'id', id)
            const localVarPath = `/drfadmin/subpoenas/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminSubpoenasList: async (className?: number, ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/subpoenas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (className !== undefined) {
                localVarQueryParameter['class_name'] = className;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {PatchedSubpoenaRequest} [patchedSubpoenaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminSubpoenasPartialUpdate: async (id: number, patchedSubpoenaRequest?: PatchedSubpoenaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminSubpoenasPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/subpoenas/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedSubpoenaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminSubpoenasRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminSubpoenasRetrieve', 'id', id)
            const localVarPath = `/drfadmin/subpoenas/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {SubpoenaRequest} subpoenaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminSubpoenasUpdate: async (id: number, subpoenaRequest: SubpoenaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminSubpoenasUpdate', 'id', id)
            // verify required parameter 'subpoenaRequest' is not null or undefined
            assertParamExists('drfadminSubpoenasUpdate', 'subpoenaRequest', subpoenaRequest)
            const localVarPath = `/drfadmin/subpoenas/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subpoenaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TradeAllianceRequest} tradeAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradeAlliancesCreate: async (tradeAllianceRequest: TradeAllianceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradeAllianceRequest' is not null or undefined
            assertParamExists('drfadminTradeAlliancesCreate', 'tradeAllianceRequest', tradeAllianceRequest)
            const localVarPath = `/drfadmin/tradeAlliances/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradeAllianceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradeAlliancesDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminTradeAlliancesDestroy', 'id', id)
            const localVarPath = `/drfadmin/tradeAlliances/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradeAlliancesList: async (className?: number, ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/tradeAlliances/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (className !== undefined) {
                localVarQueryParameter['class_name'] = className;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {PatchedTradeAllianceRequest} [patchedTradeAllianceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradeAlliancesPartialUpdate: async (id: number, patchedTradeAllianceRequest?: PatchedTradeAllianceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminTradeAlliancesPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/tradeAlliances/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedTradeAllianceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradeAlliancesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminTradeAlliancesRetrieve', 'id', id)
            const localVarPath = `/drfadmin/tradeAlliances/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {TradeAllianceRequest} tradeAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradeAlliancesUpdate: async (id: number, tradeAllianceRequest: TradeAllianceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminTradeAlliancesUpdate', 'id', id)
            // verify required parameter 'tradeAllianceRequest' is not null or undefined
            assertParamExists('drfadminTradeAlliancesUpdate', 'tradeAllianceRequest', tradeAllianceRequest)
            const localVarPath = `/drfadmin/tradeAlliances/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradeAllianceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminTradeRequest} adminTradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradesCreate: async (adminTradeRequest: AdminTradeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminTradeRequest' is not null or undefined
            assertParamExists('drfadminTradesCreate', 'adminTradeRequest', adminTradeRequest)
            const localVarPath = `/drfadmin/trades/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminTradeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradesDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminTradesDestroy', 'id', id)
            const localVarPath = `/drfadmin/trades/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradesList: async (className?: number, ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/trades/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (className !== undefined) {
                localVarQueryParameter['class_name'] = className;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {PatchedAdminTradeRequest} [patchedAdminTradeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradesPartialUpdate: async (id: number, patchedAdminTradeRequest?: PatchedAdminTradeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminTradesPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/trades/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedAdminTradeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradesRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminTradesRetrieve', 'id', id)
            const localVarPath = `/drfadmin/trades/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {AdminTradeRequest} adminTradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradesUpdate: async (id: number, adminTradeRequest: AdminTradeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminTradesUpdate', 'id', id)
            // verify required parameter 'adminTradeRequest' is not null or undefined
            assertParamExists('drfadminTradesUpdate', 'adminTradeRequest', adminTradeRequest)
            const localVarPath = `/drfadmin/trades/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminTradeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminUserClassRequest} adminUserClassRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminUsersCreate: async (adminUserClassRequest: AdminUserClassRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminUserClassRequest' is not null or undefined
            assertParamExists('drfadminUsersCreate', 'adminUserClassRequest', adminUserClassRequest)
            const localVarPath = `/drfadmin/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserClassRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminUsersDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminUsersDestroy', 'id', id)
            const localVarPath = `/drfadmin/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminUsersList: async (className?: number, ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (className !== undefined) {
                localVarQueryParameter['class_name'] = className;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {PatchedAdminUserClassRequest} [patchedAdminUserClassRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminUsersPartialUpdate: async (id: number, patchedAdminUserClassRequest?: PatchedAdminUserClassRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminUsersPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedAdminUserClassRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminUsersRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminUsersRetrieve', 'id', id)
            const localVarPath = `/drfadmin/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {AdminUserClassRequest} adminUserClassRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminUsersUpdate: async (id: number, adminUserClassRequest: AdminUserClassRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminUsersUpdate', 'id', id)
            // verify required parameter 'adminUserClassRequest' is not null or undefined
            assertParamExists('drfadminUsersUpdate', 'adminUserClassRequest', adminUserClassRequest)
            const localVarPath = `/drfadmin/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserClassRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WarningRequest} warningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarningsCreate: async (warningRequest: WarningRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warningRequest' is not null or undefined
            assertParamExists('drfadminWarningsCreate', 'warningRequest', warningRequest)
            const localVarPath = `/drfadmin/warnings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warningRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarningsDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminWarningsDestroy', 'id', id)
            const localVarPath = `/drfadmin/warnings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarningsList: async (className?: number, ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/warnings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (className !== undefined) {
                localVarQueryParameter['class_name'] = className;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {PatchedWarningRequest} [patchedWarningRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarningsPartialUpdate: async (id: number, patchedWarningRequest?: PatchedWarningRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminWarningsPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/warnings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedWarningRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarningsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminWarningsRetrieve', 'id', id)
            const localVarPath = `/drfadmin/warnings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {WarningRequest} warningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarningsUpdate: async (id: number, warningRequest: WarningRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminWarningsUpdate', 'id', id)
            // verify required parameter 'warningRequest' is not null or undefined
            assertParamExists('drfadminWarningsUpdate', 'warningRequest', warningRequest)
            const localVarPath = `/drfadmin/warnings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warningRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WarRequest} warRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarsCreate: async (warRequest: WarRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warRequest' is not null or undefined
            assertParamExists('drfadminWarsCreate', 'warRequest', warRequest)
            const localVarPath = `/drfadmin/wars/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarsDestroy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminWarsDestroy', 'id', id)
            const localVarPath = `/drfadmin/wars/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarsList: async (className?: number, ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/drfadmin/wars/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (className !== undefined) {
                localVarQueryParameter['class_name'] = className;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {PatchedWarRequest} [patchedWarRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarsPartialUpdate: async (id: number, patchedWarRequest?: PatchedWarRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminWarsPartialUpdate', 'id', id)
            const localVarPath = `/drfadmin/wars/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedWarRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarsRetrieve: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminWarsRetrieve', 'id', id)
            const localVarPath = `/drfadmin/wars/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {WarRequest} warRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarsUpdate: async (id: number, warRequest: WarRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('drfadminWarsUpdate', 'id', id)
            // verify required parameter 'warRequest' is not null or undefined
            assertParamExists('drfadminWarsUpdate', 'warRequest', warRequest)
            const localVarPath = `/drfadmin/wars/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DrfadminApi - functional programming interface
 * @export
 */
export const DrfadminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DrfadminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminArticleRequest} adminArticleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminArticlesCreate(adminArticleRequest: AdminArticleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminArticle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminArticlesCreate(adminArticleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminArticlesDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminArticlesDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminArticlesList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAdminArticleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminArticlesList(className, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {PatchedAdminArticleRequest} [patchedAdminArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminArticlesPartialUpdate(id: number, patchedAdminArticleRequest?: PatchedAdminArticleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminArticle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminArticlesPartialUpdate(id, patchedAdminArticleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminArticlesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminArticle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminArticlesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {AdminArticleRequest} adminArticleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminArticlesUpdate(id: number, adminArticleRequest: AdminArticleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminArticle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminArticlesUpdate(id, adminArticleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminClassNameRequest} adminClassNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminClassesCreate(adminClassNameRequest: AdminClassNameRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminClassName>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminClassesCreate(adminClassNameRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this class name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminClassesDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminClassesDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [isDemoClass] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminClassesList(isDemoClass?: boolean, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAdminClassNameList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminClassesList(isDemoClass, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this class name.
         * @param {PatchedAdminClassNameRequest} [patchedAdminClassNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminClassesPartialUpdate(id: number, patchedAdminClassNameRequest?: PatchedAdminClassNameRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminClassName>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminClassesPartialUpdate(id, patchedAdminClassNameRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this class name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminClassesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminClassName>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminClassesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this class name.
         * @param {AdminClassNameRequest} adminClassNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminClassesUpdate(id: number, adminClassNameRequest: AdminClassNameRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminClassName>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminClassesUpdate(id, adminClassNameRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminCommentRequest} adminCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminCommentsCreate(adminCommentRequest: AdminCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminCommentsCreate(adminCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminCommentsDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminCommentsDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminCommentsList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAdminCommentList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminCommentsList(ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {PatchedAdminCommentRequest} [patchedAdminCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminCommentsPartialUpdate(id: number, patchedAdminCommentRequest?: PatchedAdminCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminCommentsPartialUpdate(id, patchedAdminCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminCommentsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminCommentsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {AdminCommentRequest} adminCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminCommentsUpdate(id: number, adminCommentRequest: AdminCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminCommentsUpdate(id, adminCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminItemsList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAdminItemList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminItemsList(ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminItemsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminItemsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MilitaryAllianceRequest} militaryAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminMilitaryAlliancesCreate(militaryAllianceRequest: MilitaryAllianceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminMilitaryAlliancesCreate(militaryAllianceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminMilitaryAlliancesDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminMilitaryAlliancesDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminMilitaryAlliancesList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMilitaryAllianceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminMilitaryAlliancesList(className, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {PatchedMilitaryAllianceRequest} [patchedMilitaryAllianceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminMilitaryAlliancesPartialUpdate(id: number, patchedMilitaryAllianceRequest?: PatchedMilitaryAllianceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminMilitaryAlliancesPartialUpdate(id, patchedMilitaryAllianceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminMilitaryAlliancesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminMilitaryAlliancesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {MilitaryAllianceRequest} militaryAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminMilitaryAlliancesUpdate(id: number, militaryAllianceRequest: MilitaryAllianceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilitaryAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminMilitaryAlliancesUpdate(id, militaryAllianceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminNationsRequest} adminNationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminNationsCreate(adminNationsRequest: AdminNationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminNations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminNationsCreate(adminNationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminNationsDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminNationsDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminNationsList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAdminNationsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminNationsList(className, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {PatchedAdminNationsRequest} [patchedAdminNationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminNationsPartialUpdate(id: number, patchedAdminNationsRequest?: PatchedAdminNationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminNations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminNationsPartialUpdate(id, patchedAdminNationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminNationsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminNations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminNationsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {AdminNationsRequest} adminNationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminNationsUpdate(id: number, adminNationsRequest: AdminNationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminNations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminNationsUpdate(id, adminNationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPermissionsList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPermissionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPermissionsList(ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPermissionsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Permission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPermissionsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PopulationChangeEventRequest} populationChangeEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPopulationChangeEventCreate(populationChangeEventRequest: PopulationChangeEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PopulationChangeEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPopulationChangeEventCreate(populationChangeEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this population change event.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPopulationChangeEventDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPopulationChangeEventDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPopulationChangeEventList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPopulationChangeEventList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPopulationChangeEventList(ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this population change event.
         * @param {PatchedPopulationChangeEventRequest} [patchedPopulationChangeEventRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPopulationChangeEventPartialUpdate(id: number, patchedPopulationChangeEventRequest?: PatchedPopulationChangeEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PopulationChangeEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPopulationChangeEventPartialUpdate(id, patchedPopulationChangeEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this population change event.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPopulationChangeEventRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PopulationChangeEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPopulationChangeEventRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this population change event.
         * @param {PopulationChangeEventRequest} populationChangeEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPopulationChangeEventUpdate(id: number, populationChangeEventRequest: PopulationChangeEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PopulationChangeEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPopulationChangeEventUpdate(id, populationChangeEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PurchaseRequest} purchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPurchasesCreate(purchaseRequest: PurchaseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPurchasesCreate(purchaseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPurchasesDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPurchasesDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPurchasesGiftCreate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPurchasesGiftCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPurchasesList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPurchaseList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPurchasesList(ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {PatchedPurchaseRequest} [patchedPurchaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPurchasesPartialUpdate(id: number, patchedPurchaseRequest?: PatchedPurchaseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPurchasesPartialUpdate(id, patchedPurchaseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPurchasesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPurchasesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {PurchaseRequest} purchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminPurchasesUpdate(id: number, purchaseRequest: PurchaseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Purchase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminPurchasesUpdate(id, purchaseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminResourceTypesCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminResourceTypesCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminResourceTypesDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminResourceTypesDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminResourceTypesList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResourceTypeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminResourceTypesList(ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminResourceTypesPartialUpdate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminResourceTypesPartialUpdate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminResourceTypesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminResourceTypesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminResourceTypesUpdate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminResourceTypesUpdate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResourceRequest} resourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminResourcesCreate(resourceRequest: ResourceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminResourcesCreate(resourceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminResourcesDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminResourcesDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminResourcesList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResourceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminResourcesList(ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource.
         * @param {PatchedResourceRequest} [patchedResourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminResourcesPartialUpdate(id: number, patchedResourceRequest?: PatchedResourceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminResourcesPartialUpdate(id, patchedResourceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminResourcesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminResourcesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource.
         * @param {ResourceRequest} resourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminResourcesUpdate(id: number, resourceRequest: ResourceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminResourcesUpdate(id, resourceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminRolePermissionsList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedRolePermissionsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminRolePermissionsList(ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminRolePermissionsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolePermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminRolePermissionsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminRolesList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAdminRoleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminRolesList(ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminRolesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminRolesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SubpoenaRequest} subpoenaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminSubpoenasCreate(subpoenaRequest: SubpoenaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subpoena>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminSubpoenasCreate(subpoenaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminSubpoenasDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminSubpoenasDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminSubpoenasList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSubpoenaList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminSubpoenasList(className, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {PatchedSubpoenaRequest} [patchedSubpoenaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminSubpoenasPartialUpdate(id: number, patchedSubpoenaRequest?: PatchedSubpoenaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subpoena>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminSubpoenasPartialUpdate(id, patchedSubpoenaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminSubpoenasRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subpoena>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminSubpoenasRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {SubpoenaRequest} subpoenaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminSubpoenasUpdate(id: number, subpoenaRequest: SubpoenaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subpoena>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminSubpoenasUpdate(id, subpoenaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TradeAllianceRequest} tradeAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminTradeAlliancesCreate(tradeAllianceRequest: TradeAllianceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminTradeAlliancesCreate(tradeAllianceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminTradeAlliancesDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminTradeAlliancesDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminTradeAlliancesList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTradeAllianceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminTradeAlliancesList(className, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {PatchedTradeAllianceRequest} [patchedTradeAllianceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminTradeAlliancesPartialUpdate(id: number, patchedTradeAllianceRequest?: PatchedTradeAllianceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminTradeAlliancesPartialUpdate(id, patchedTradeAllianceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminTradeAlliancesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminTradeAlliancesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {TradeAllianceRequest} tradeAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminTradeAlliancesUpdate(id: number, tradeAllianceRequest: TradeAllianceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeAlliance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminTradeAlliancesUpdate(id, tradeAllianceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminTradeRequest} adminTradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminTradesCreate(adminTradeRequest: AdminTradeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTrade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminTradesCreate(adminTradeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminTradesDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminTradesDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminTradesList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAdminTradeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminTradesList(className, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {PatchedAdminTradeRequest} [patchedAdminTradeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminTradesPartialUpdate(id: number, patchedAdminTradeRequest?: PatchedAdminTradeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTrade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminTradesPartialUpdate(id, patchedAdminTradeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminTradesRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTrade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminTradesRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {AdminTradeRequest} adminTradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminTradesUpdate(id: number, adminTradeRequest: AdminTradeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTrade>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminTradesUpdate(id, adminTradeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminUserClassRequest} adminUserClassRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminUsersCreate(adminUserClassRequest: AdminUserClassRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminUsersCreate(adminUserClassRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminUsersDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminUsersDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminUsersList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAdminUserClassList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminUsersList(className, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {PatchedAdminUserClassRequest} [patchedAdminUserClassRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminUsersPartialUpdate(id: number, patchedAdminUserClassRequest?: PatchedAdminUserClassRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminUsersPartialUpdate(id, patchedAdminUserClassRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminUsersRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminUsersRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {AdminUserClassRequest} adminUserClassRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminUsersUpdate(id: number, adminUserClassRequest: AdminUserClassRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminUsersUpdate(id, adminUserClassRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WarningRequest} warningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminWarningsCreate(warningRequest: WarningRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Warning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminWarningsCreate(warningRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminWarningsDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminWarningsDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminWarningsList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedWarningList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminWarningsList(className, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {PatchedWarningRequest} [patchedWarningRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminWarningsPartialUpdate(id: number, patchedWarningRequest?: PatchedWarningRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Warning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminWarningsPartialUpdate(id, patchedWarningRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminWarningsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Warning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminWarningsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {WarningRequest} warningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminWarningsUpdate(id: number, warningRequest: WarningRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Warning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminWarningsUpdate(id, warningRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WarRequest} warRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminWarsCreate(warRequest: WarRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<War>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminWarsCreate(warRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminWarsDestroy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminWarsDestroy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminWarsList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedWarList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminWarsList(className, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {PatchedWarRequest} [patchedWarRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminWarsPartialUpdate(id: number, patchedWarRequest?: PatchedWarRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<War>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminWarsPartialUpdate(id, patchedWarRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminWarsRetrieve(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<War>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminWarsRetrieve(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {WarRequest} warRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async drfadminWarsUpdate(id: number, warRequest: WarRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<War>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.drfadminWarsUpdate(id, warRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DrfadminApi - factory interface
 * @export
 */
export const DrfadminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DrfadminApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminArticleRequest} adminArticleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminArticlesCreate(adminArticleRequest: AdminArticleRequest, options?: any): AxiosPromise<AdminArticle> {
            return localVarFp.drfadminArticlesCreate(adminArticleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminArticlesDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminArticlesDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminArticlesList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedAdminArticleList> {
            return localVarFp.drfadminArticlesList(className, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {PatchedAdminArticleRequest} [patchedAdminArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminArticlesPartialUpdate(id: number, patchedAdminArticleRequest?: PatchedAdminArticleRequest, options?: any): AxiosPromise<AdminArticle> {
            return localVarFp.drfadminArticlesPartialUpdate(id, patchedAdminArticleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminArticlesRetrieve(id: number, options?: any): AxiosPromise<AdminArticle> {
            return localVarFp.drfadminArticlesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this article.
         * @param {AdminArticleRequest} adminArticleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminArticlesUpdate(id: number, adminArticleRequest: AdminArticleRequest, options?: any): AxiosPromise<AdminArticle> {
            return localVarFp.drfadminArticlesUpdate(id, adminArticleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminClassNameRequest} adminClassNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminClassesCreate(adminClassNameRequest: AdminClassNameRequest, options?: any): AxiosPromise<AdminClassName> {
            return localVarFp.drfadminClassesCreate(adminClassNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this class name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminClassesDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminClassesDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isDemoClass] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminClassesList(isDemoClass?: boolean, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedAdminClassNameList> {
            return localVarFp.drfadminClassesList(isDemoClass, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this class name.
         * @param {PatchedAdminClassNameRequest} [patchedAdminClassNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminClassesPartialUpdate(id: number, patchedAdminClassNameRequest?: PatchedAdminClassNameRequest, options?: any): AxiosPromise<AdminClassName> {
            return localVarFp.drfadminClassesPartialUpdate(id, patchedAdminClassNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this class name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminClassesRetrieve(id: number, options?: any): AxiosPromise<AdminClassName> {
            return localVarFp.drfadminClassesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this class name.
         * @param {AdminClassNameRequest} adminClassNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminClassesUpdate(id: number, adminClassNameRequest: AdminClassNameRequest, options?: any): AxiosPromise<AdminClassName> {
            return localVarFp.drfadminClassesUpdate(id, adminClassNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminCommentRequest} adminCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminCommentsCreate(adminCommentRequest: AdminCommentRequest, options?: any): AxiosPromise<AdminComment> {
            return localVarFp.drfadminCommentsCreate(adminCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminCommentsDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminCommentsDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminCommentsList(ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedAdminCommentList> {
            return localVarFp.drfadminCommentsList(ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {PatchedAdminCommentRequest} [patchedAdminCommentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminCommentsPartialUpdate(id: number, patchedAdminCommentRequest?: PatchedAdminCommentRequest, options?: any): AxiosPromise<AdminComment> {
            return localVarFp.drfadminCommentsPartialUpdate(id, patchedAdminCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminCommentsRetrieve(id: number, options?: any): AxiosPromise<AdminComment> {
            return localVarFp.drfadminCommentsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this comment.
         * @param {AdminCommentRequest} adminCommentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminCommentsUpdate(id: number, adminCommentRequest: AdminCommentRequest, options?: any): AxiosPromise<AdminComment> {
            return localVarFp.drfadminCommentsUpdate(id, adminCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminItemsList(ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedAdminItemList> {
            return localVarFp.drfadminItemsList(ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminItemsRetrieve(id: number, options?: any): AxiosPromise<AdminItem> {
            return localVarFp.drfadminItemsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MilitaryAllianceRequest} militaryAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminMilitaryAlliancesCreate(militaryAllianceRequest: MilitaryAllianceRequest, options?: any): AxiosPromise<MilitaryAlliance> {
            return localVarFp.drfadminMilitaryAlliancesCreate(militaryAllianceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminMilitaryAlliancesDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminMilitaryAlliancesDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminMilitaryAlliancesList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedMilitaryAllianceList> {
            return localVarFp.drfadminMilitaryAlliancesList(className, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {PatchedMilitaryAllianceRequest} [patchedMilitaryAllianceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminMilitaryAlliancesPartialUpdate(id: number, patchedMilitaryAllianceRequest?: PatchedMilitaryAllianceRequest, options?: any): AxiosPromise<MilitaryAlliance> {
            return localVarFp.drfadminMilitaryAlliancesPartialUpdate(id, patchedMilitaryAllianceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminMilitaryAlliancesRetrieve(id: number, options?: any): AxiosPromise<MilitaryAlliance> {
            return localVarFp.drfadminMilitaryAlliancesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this military alliance.
         * @param {MilitaryAllianceRequest} militaryAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminMilitaryAlliancesUpdate(id: number, militaryAllianceRequest: MilitaryAllianceRequest, options?: any): AxiosPromise<MilitaryAlliance> {
            return localVarFp.drfadminMilitaryAlliancesUpdate(id, militaryAllianceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminNationsRequest} adminNationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminNationsCreate(adminNationsRequest: AdminNationsRequest, options?: any): AxiosPromise<AdminNations> {
            return localVarFp.drfadminNationsCreate(adminNationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminNationsDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminNationsDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminNationsList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedAdminNationsList> {
            return localVarFp.drfadminNationsList(className, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {PatchedAdminNationsRequest} [patchedAdminNationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminNationsPartialUpdate(id: number, patchedAdminNationsRequest?: PatchedAdminNationsRequest, options?: any): AxiosPromise<AdminNations> {
            return localVarFp.drfadminNationsPartialUpdate(id, patchedAdminNationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminNationsRetrieve(id: number, options?: any): AxiosPromise<AdminNations> {
            return localVarFp.drfadminNationsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this nation.
         * @param {AdminNationsRequest} adminNationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminNationsUpdate(id: number, adminNationsRequest: AdminNationsRequest, options?: any): AxiosPromise<AdminNations> {
            return localVarFp.drfadminNationsUpdate(id, adminNationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPermissionsList(ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedPermissionList> {
            return localVarFp.drfadminPermissionsList(ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPermissionsRetrieve(id: number, options?: any): AxiosPromise<Permission> {
            return localVarFp.drfadminPermissionsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PopulationChangeEventRequest} populationChangeEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPopulationChangeEventCreate(populationChangeEventRequest: PopulationChangeEventRequest, options?: any): AxiosPromise<PopulationChangeEvent> {
            return localVarFp.drfadminPopulationChangeEventCreate(populationChangeEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this population change event.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPopulationChangeEventDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminPopulationChangeEventDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPopulationChangeEventList(ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedPopulationChangeEventList> {
            return localVarFp.drfadminPopulationChangeEventList(ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this population change event.
         * @param {PatchedPopulationChangeEventRequest} [patchedPopulationChangeEventRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPopulationChangeEventPartialUpdate(id: number, patchedPopulationChangeEventRequest?: PatchedPopulationChangeEventRequest, options?: any): AxiosPromise<PopulationChangeEvent> {
            return localVarFp.drfadminPopulationChangeEventPartialUpdate(id, patchedPopulationChangeEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this population change event.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPopulationChangeEventRetrieve(id: number, options?: any): AxiosPromise<PopulationChangeEvent> {
            return localVarFp.drfadminPopulationChangeEventRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this population change event.
         * @param {PopulationChangeEventRequest} populationChangeEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPopulationChangeEventUpdate(id: number, populationChangeEventRequest: PopulationChangeEventRequest, options?: any): AxiosPromise<PopulationChangeEvent> {
            return localVarFp.drfadminPopulationChangeEventUpdate(id, populationChangeEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PurchaseRequest} purchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesCreate(purchaseRequest: PurchaseRequest, options?: any): AxiosPromise<Purchase> {
            return localVarFp.drfadminPurchasesCreate(purchaseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminPurchasesDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesGiftCreate(id: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.drfadminPurchasesGiftCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesList(ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedPurchaseList> {
            return localVarFp.drfadminPurchasesList(ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {PatchedPurchaseRequest} [patchedPurchaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesPartialUpdate(id: number, patchedPurchaseRequest?: PatchedPurchaseRequest, options?: any): AxiosPromise<Purchase> {
            return localVarFp.drfadminPurchasesPartialUpdate(id, patchedPurchaseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesRetrieve(id: number, options?: any): AxiosPromise<Purchase> {
            return localVarFp.drfadminPurchasesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this purchase.
         * @param {PurchaseRequest} purchaseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminPurchasesUpdate(id: number, purchaseRequest: PurchaseRequest, options?: any): AxiosPromise<Purchase> {
            return localVarFp.drfadminPurchasesUpdate(id, purchaseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourceTypesCreate(options?: any): AxiosPromise<ResourceType> {
            return localVarFp.drfadminResourceTypesCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourceTypesDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminResourceTypesDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourceTypesList(ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedResourceTypeList> {
            return localVarFp.drfadminResourceTypesList(ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourceTypesPartialUpdate(id: number, options?: any): AxiosPromise<ResourceType> {
            return localVarFp.drfadminResourceTypesPartialUpdate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourceTypesRetrieve(id: number, options?: any): AxiosPromise<ResourceType> {
            return localVarFp.drfadminResourceTypesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourceTypesUpdate(id: number, options?: any): AxiosPromise<ResourceType> {
            return localVarFp.drfadminResourceTypesUpdate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResourceRequest} resourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourcesCreate(resourceRequest: ResourceRequest, options?: any): AxiosPromise<Resource> {
            return localVarFp.drfadminResourcesCreate(resourceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourcesDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminResourcesDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourcesList(ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedResourceList> {
            return localVarFp.drfadminResourcesList(ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource.
         * @param {PatchedResourceRequest} [patchedResourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourcesPartialUpdate(id: number, patchedResourceRequest?: PatchedResourceRequest, options?: any): AxiosPromise<Resource> {
            return localVarFp.drfadminResourcesPartialUpdate(id, patchedResourceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourcesRetrieve(id: number, options?: any): AxiosPromise<Resource> {
            return localVarFp.drfadminResourcesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this resource.
         * @param {ResourceRequest} resourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminResourcesUpdate(id: number, resourceRequest: ResourceRequest, options?: any): AxiosPromise<Resource> {
            return localVarFp.drfadminResourcesUpdate(id, resourceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminRolePermissionsList(ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedRolePermissionsList> {
            return localVarFp.drfadminRolePermissionsList(ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminRolePermissionsRetrieve(id: number, options?: any): AxiosPromise<RolePermissions> {
            return localVarFp.drfadminRolePermissionsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminRolesList(ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedAdminRoleList> {
            return localVarFp.drfadminRolesList(ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminRolesRetrieve(id: number, options?: any): AxiosPromise<AdminRole> {
            return localVarFp.drfadminRolesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubpoenaRequest} subpoenaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminSubpoenasCreate(subpoenaRequest: SubpoenaRequest, options?: any): AxiosPromise<Subpoena> {
            return localVarFp.drfadminSubpoenasCreate(subpoenaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminSubpoenasDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminSubpoenasDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminSubpoenasList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedSubpoenaList> {
            return localVarFp.drfadminSubpoenasList(className, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {PatchedSubpoenaRequest} [patchedSubpoenaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminSubpoenasPartialUpdate(id: number, patchedSubpoenaRequest?: PatchedSubpoenaRequest, options?: any): AxiosPromise<Subpoena> {
            return localVarFp.drfadminSubpoenasPartialUpdate(id, patchedSubpoenaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminSubpoenasRetrieve(id: number, options?: any): AxiosPromise<Subpoena> {
            return localVarFp.drfadminSubpoenasRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this subpoena.
         * @param {SubpoenaRequest} subpoenaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminSubpoenasUpdate(id: number, subpoenaRequest: SubpoenaRequest, options?: any): AxiosPromise<Subpoena> {
            return localVarFp.drfadminSubpoenasUpdate(id, subpoenaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TradeAllianceRequest} tradeAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradeAlliancesCreate(tradeAllianceRequest: TradeAllianceRequest, options?: any): AxiosPromise<TradeAlliance> {
            return localVarFp.drfadminTradeAlliancesCreate(tradeAllianceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradeAlliancesDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminTradeAlliancesDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradeAlliancesList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedTradeAllianceList> {
            return localVarFp.drfadminTradeAlliancesList(className, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {PatchedTradeAllianceRequest} [patchedTradeAllianceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradeAlliancesPartialUpdate(id: number, patchedTradeAllianceRequest?: PatchedTradeAllianceRequest, options?: any): AxiosPromise<TradeAlliance> {
            return localVarFp.drfadminTradeAlliancesPartialUpdate(id, patchedTradeAllianceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradeAlliancesRetrieve(id: number, options?: any): AxiosPromise<TradeAlliance> {
            return localVarFp.drfadminTradeAlliancesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade alliance.
         * @param {TradeAllianceRequest} tradeAllianceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradeAlliancesUpdate(id: number, tradeAllianceRequest: TradeAllianceRequest, options?: any): AxiosPromise<TradeAlliance> {
            return localVarFp.drfadminTradeAlliancesUpdate(id, tradeAllianceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminTradeRequest} adminTradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradesCreate(adminTradeRequest: AdminTradeRequest, options?: any): AxiosPromise<AdminTrade> {
            return localVarFp.drfadminTradesCreate(adminTradeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradesDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminTradesDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradesList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedAdminTradeList> {
            return localVarFp.drfadminTradesList(className, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {PatchedAdminTradeRequest} [patchedAdminTradeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradesPartialUpdate(id: number, patchedAdminTradeRequest?: PatchedAdminTradeRequest, options?: any): AxiosPromise<AdminTrade> {
            return localVarFp.drfadminTradesPartialUpdate(id, patchedAdminTradeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradesRetrieve(id: number, options?: any): AxiosPromise<AdminTrade> {
            return localVarFp.drfadminTradesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this trade.
         * @param {AdminTradeRequest} adminTradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminTradesUpdate(id: number, adminTradeRequest: AdminTradeRequest, options?: any): AxiosPromise<AdminTrade> {
            return localVarFp.drfadminTradesUpdate(id, adminTradeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminUserClassRequest} adminUserClassRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminUsersCreate(adminUserClassRequest: AdminUserClassRequest, options?: any): AxiosPromise<AdminUserClass> {
            return localVarFp.drfadminUsersCreate(adminUserClassRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminUsersDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminUsersDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminUsersList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedAdminUserClassList> {
            return localVarFp.drfadminUsersList(className, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {PatchedAdminUserClassRequest} [patchedAdminUserClassRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminUsersPartialUpdate(id: number, patchedAdminUserClassRequest?: PatchedAdminUserClassRequest, options?: any): AxiosPromise<AdminUserClass> {
            return localVarFp.drfadminUsersPartialUpdate(id, patchedAdminUserClassRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminUsersRetrieve(id: number, options?: any): AxiosPromise<AdminUserClass> {
            return localVarFp.drfadminUsersRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user class.
         * @param {AdminUserClassRequest} adminUserClassRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminUsersUpdate(id: number, adminUserClassRequest: AdminUserClassRequest, options?: any): AxiosPromise<AdminUserClass> {
            return localVarFp.drfadminUsersUpdate(id, adminUserClassRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WarningRequest} warningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarningsCreate(warningRequest: WarningRequest, options?: any): AxiosPromise<Warning> {
            return localVarFp.drfadminWarningsCreate(warningRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarningsDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminWarningsDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarningsList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedWarningList> {
            return localVarFp.drfadminWarningsList(className, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {PatchedWarningRequest} [patchedWarningRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarningsPartialUpdate(id: number, patchedWarningRequest?: PatchedWarningRequest, options?: any): AxiosPromise<Warning> {
            return localVarFp.drfadminWarningsPartialUpdate(id, patchedWarningRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarningsRetrieve(id: number, options?: any): AxiosPromise<Warning> {
            return localVarFp.drfadminWarningsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this warning.
         * @param {WarningRequest} warningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarningsUpdate(id: number, warningRequest: WarningRequest, options?: any): AxiosPromise<Warning> {
            return localVarFp.drfadminWarningsUpdate(id, warningRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WarRequest} warRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarsCreate(warRequest: WarRequest, options?: any): AxiosPromise<War> {
            return localVarFp.drfadminWarsCreate(warRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarsDestroy(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.drfadminWarsDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [className] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarsList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedWarList> {
            return localVarFp.drfadminWarsList(className, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {PatchedWarRequest} [patchedWarRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarsPartialUpdate(id: number, patchedWarRequest?: PatchedWarRequest, options?: any): AxiosPromise<War> {
            return localVarFp.drfadminWarsPartialUpdate(id, patchedWarRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarsRetrieve(id: number, options?: any): AxiosPromise<War> {
            return localVarFp.drfadminWarsRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this war.
         * @param {WarRequest} warRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        drfadminWarsUpdate(id: number, warRequest: WarRequest, options?: any): AxiosPromise<War> {
            return localVarFp.drfadminWarsUpdate(id, warRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DrfadminApi - object-oriented interface
 * @export
 * @class DrfadminApi
 * @extends {BaseAPI}
 */
export class DrfadminApi extends BaseAPI {
    /**
     * 
     * @param {AdminArticleRequest} adminArticleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminArticlesCreate(adminArticleRequest: AdminArticleRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminArticlesCreate(adminArticleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this article.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminArticlesDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminArticlesDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [className] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminArticlesList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminArticlesList(className, ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this article.
     * @param {PatchedAdminArticleRequest} [patchedAdminArticleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminArticlesPartialUpdate(id: number, patchedAdminArticleRequest?: PatchedAdminArticleRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminArticlesPartialUpdate(id, patchedAdminArticleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this article.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminArticlesRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminArticlesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this article.
     * @param {AdminArticleRequest} adminArticleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminArticlesUpdate(id: number, adminArticleRequest: AdminArticleRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminArticlesUpdate(id, adminArticleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminClassNameRequest} adminClassNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminClassesCreate(adminClassNameRequest: AdminClassNameRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminClassesCreate(adminClassNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this class name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminClassesDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminClassesDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isDemoClass] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminClassesList(isDemoClass?: boolean, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminClassesList(isDemoClass, ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this class name.
     * @param {PatchedAdminClassNameRequest} [patchedAdminClassNameRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminClassesPartialUpdate(id: number, patchedAdminClassNameRequest?: PatchedAdminClassNameRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminClassesPartialUpdate(id, patchedAdminClassNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this class name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminClassesRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminClassesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this class name.
     * @param {AdminClassNameRequest} adminClassNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminClassesUpdate(id: number, adminClassNameRequest: AdminClassNameRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminClassesUpdate(id, adminClassNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminCommentRequest} adminCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminCommentsCreate(adminCommentRequest: AdminCommentRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminCommentsCreate(adminCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this comment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminCommentsDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminCommentsDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminCommentsList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminCommentsList(ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this comment.
     * @param {PatchedAdminCommentRequest} [patchedAdminCommentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminCommentsPartialUpdate(id: number, patchedAdminCommentRequest?: PatchedAdminCommentRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminCommentsPartialUpdate(id, patchedAdminCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this comment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminCommentsRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminCommentsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this comment.
     * @param {AdminCommentRequest} adminCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminCommentsUpdate(id: number, adminCommentRequest: AdminCommentRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminCommentsUpdate(id, adminCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminItemsList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminItemsList(ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminItemsRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminItemsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MilitaryAllianceRequest} militaryAllianceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminMilitaryAlliancesCreate(militaryAllianceRequest: MilitaryAllianceRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminMilitaryAlliancesCreate(militaryAllianceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this military alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminMilitaryAlliancesDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminMilitaryAlliancesDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [className] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminMilitaryAlliancesList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminMilitaryAlliancesList(className, ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this military alliance.
     * @param {PatchedMilitaryAllianceRequest} [patchedMilitaryAllianceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminMilitaryAlliancesPartialUpdate(id: number, patchedMilitaryAllianceRequest?: PatchedMilitaryAllianceRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminMilitaryAlliancesPartialUpdate(id, patchedMilitaryAllianceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this military alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminMilitaryAlliancesRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminMilitaryAlliancesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this military alliance.
     * @param {MilitaryAllianceRequest} militaryAllianceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminMilitaryAlliancesUpdate(id: number, militaryAllianceRequest: MilitaryAllianceRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminMilitaryAlliancesUpdate(id, militaryAllianceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminNationsRequest} adminNationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminNationsCreate(adminNationsRequest: AdminNationsRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminNationsCreate(adminNationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminNationsDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminNationsDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [className] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminNationsList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminNationsList(className, ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nation.
     * @param {PatchedAdminNationsRequest} [patchedAdminNationsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminNationsPartialUpdate(id: number, patchedAdminNationsRequest?: PatchedAdminNationsRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminNationsPartialUpdate(id, patchedAdminNationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminNationsRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminNationsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this nation.
     * @param {AdminNationsRequest} adminNationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminNationsUpdate(id: number, adminNationsRequest: AdminNationsRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminNationsUpdate(id, adminNationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPermissionsList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPermissionsList(ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this role permission.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPermissionsRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPermissionsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PopulationChangeEventRequest} populationChangeEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPopulationChangeEventCreate(populationChangeEventRequest: PopulationChangeEventRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPopulationChangeEventCreate(populationChangeEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this population change event.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPopulationChangeEventDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPopulationChangeEventDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPopulationChangeEventList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPopulationChangeEventList(ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this population change event.
     * @param {PatchedPopulationChangeEventRequest} [patchedPopulationChangeEventRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPopulationChangeEventPartialUpdate(id: number, patchedPopulationChangeEventRequest?: PatchedPopulationChangeEventRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPopulationChangeEventPartialUpdate(id, patchedPopulationChangeEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this population change event.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPopulationChangeEventRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPopulationChangeEventRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this population change event.
     * @param {PopulationChangeEventRequest} populationChangeEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPopulationChangeEventUpdate(id: number, populationChangeEventRequest: PopulationChangeEventRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPopulationChangeEventUpdate(id, populationChangeEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PurchaseRequest} purchaseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPurchasesCreate(purchaseRequest: PurchaseRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPurchasesCreate(purchaseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPurchasesDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPurchasesDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPurchasesGiftCreate(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPurchasesGiftCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPurchasesList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPurchasesList(ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {PatchedPurchaseRequest} [patchedPurchaseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPurchasesPartialUpdate(id: number, patchedPurchaseRequest?: PatchedPurchaseRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPurchasesPartialUpdate(id, patchedPurchaseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPurchasesRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPurchasesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this purchase.
     * @param {PurchaseRequest} purchaseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminPurchasesUpdate(id: number, purchaseRequest: PurchaseRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminPurchasesUpdate(id, purchaseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminResourceTypesCreate(options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminResourceTypesCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this resource type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminResourceTypesDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminResourceTypesDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminResourceTypesList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminResourceTypesList(ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this resource type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminResourceTypesPartialUpdate(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminResourceTypesPartialUpdate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this resource type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminResourceTypesRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminResourceTypesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this resource type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminResourceTypesUpdate(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminResourceTypesUpdate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResourceRequest} resourceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminResourcesCreate(resourceRequest: ResourceRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminResourcesCreate(resourceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminResourcesDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminResourcesDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminResourcesList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminResourcesList(ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this resource.
     * @param {PatchedResourceRequest} [patchedResourceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminResourcesPartialUpdate(id: number, patchedResourceRequest?: PatchedResourceRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminResourcesPartialUpdate(id, patchedResourceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminResourcesRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminResourcesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this resource.
     * @param {ResourceRequest} resourceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminResourcesUpdate(id: number, resourceRequest: ResourceRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminResourcesUpdate(id, resourceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminRolePermissionsList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminRolePermissionsList(ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this role permission.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminRolePermissionsRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminRolePermissionsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminRolesList(ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminRolesList(ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this role.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminRolesRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminRolesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubpoenaRequest} subpoenaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminSubpoenasCreate(subpoenaRequest: SubpoenaRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminSubpoenasCreate(subpoenaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this subpoena.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminSubpoenasDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminSubpoenasDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [className] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminSubpoenasList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminSubpoenasList(className, ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this subpoena.
     * @param {PatchedSubpoenaRequest} [patchedSubpoenaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminSubpoenasPartialUpdate(id: number, patchedSubpoenaRequest?: PatchedSubpoenaRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminSubpoenasPartialUpdate(id, patchedSubpoenaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this subpoena.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminSubpoenasRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminSubpoenasRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this subpoena.
     * @param {SubpoenaRequest} subpoenaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminSubpoenasUpdate(id: number, subpoenaRequest: SubpoenaRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminSubpoenasUpdate(id, subpoenaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TradeAllianceRequest} tradeAllianceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminTradeAlliancesCreate(tradeAllianceRequest: TradeAllianceRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminTradeAlliancesCreate(tradeAllianceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminTradeAlliancesDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminTradeAlliancesDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [className] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminTradeAlliancesList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminTradeAlliancesList(className, ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade alliance.
     * @param {PatchedTradeAllianceRequest} [patchedTradeAllianceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminTradeAlliancesPartialUpdate(id: number, patchedTradeAllianceRequest?: PatchedTradeAllianceRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminTradeAlliancesPartialUpdate(id, patchedTradeAllianceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade alliance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminTradeAlliancesRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminTradeAlliancesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade alliance.
     * @param {TradeAllianceRequest} tradeAllianceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminTradeAlliancesUpdate(id: number, tradeAllianceRequest: TradeAllianceRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminTradeAlliancesUpdate(id, tradeAllianceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminTradeRequest} adminTradeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminTradesCreate(adminTradeRequest: AdminTradeRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminTradesCreate(adminTradeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminTradesDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminTradesDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [className] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminTradesList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminTradesList(className, ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade.
     * @param {PatchedAdminTradeRequest} [patchedAdminTradeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminTradesPartialUpdate(id: number, patchedAdminTradeRequest?: PatchedAdminTradeRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminTradesPartialUpdate(id, patchedAdminTradeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminTradesRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminTradesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this trade.
     * @param {AdminTradeRequest} adminTradeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminTradesUpdate(id: number, adminTradeRequest: AdminTradeRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminTradesUpdate(id, adminTradeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminUserClassRequest} adminUserClassRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminUsersCreate(adminUserClassRequest: AdminUserClassRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminUsersCreate(adminUserClassRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this user class.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminUsersDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminUsersDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [className] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminUsersList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminUsersList(className, ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this user class.
     * @param {PatchedAdminUserClassRequest} [patchedAdminUserClassRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminUsersPartialUpdate(id: number, patchedAdminUserClassRequest?: PatchedAdminUserClassRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminUsersPartialUpdate(id, patchedAdminUserClassRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this user class.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminUsersRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminUsersRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this user class.
     * @param {AdminUserClassRequest} adminUserClassRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminUsersUpdate(id: number, adminUserClassRequest: AdminUserClassRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminUsersUpdate(id, adminUserClassRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarningRequest} warningRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminWarningsCreate(warningRequest: WarningRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminWarningsCreate(warningRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this warning.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminWarningsDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminWarningsDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [className] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminWarningsList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminWarningsList(className, ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this warning.
     * @param {PatchedWarningRequest} [patchedWarningRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminWarningsPartialUpdate(id: number, patchedWarningRequest?: PatchedWarningRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminWarningsPartialUpdate(id, patchedWarningRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this warning.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminWarningsRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminWarningsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this warning.
     * @param {WarningRequest} warningRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminWarningsUpdate(id: number, warningRequest: WarningRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminWarningsUpdate(id, warningRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarRequest} warRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminWarsCreate(warRequest: WarRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminWarsCreate(warRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this war.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminWarsDestroy(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminWarsDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [className] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminWarsList(className?: number, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminWarsList(className, ordering, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this war.
     * @param {PatchedWarRequest} [patchedWarRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminWarsPartialUpdate(id: number, patchedWarRequest?: PatchedWarRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminWarsPartialUpdate(id, patchedWarRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this war.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminWarsRetrieve(id: number, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminWarsRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this war.
     * @param {WarRequest} warRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrfadminApi
     */
    public drfadminWarsUpdate(id: number, warRequest: WarRequest, options?: AxiosRequestConfig) {
        return DrfadminApiFp(this.configuration).drfadminWarsUpdate(id, warRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


