/** @jsxImportSource @emotion/react */
import { useUser } from "../../hooks/useUser";
import {useMemo} from "react";
import { Nations, NationStat } from "../../my-api-client";
import {
  Container,
} from "@mui/material";

import LoadingScreen from "../Loading/loadingScreen.tsx";
import JoyrideWrapper from "../Joyridewrapper";
import UserList from "./UserList.tsx";
import FlagCard from "./FlagCard.tsx";
import ResourcePanel from "./ResourcePanel.tsx";
import AlliancesPanel from "./AlliancesPanel.tsx";
import PowerPointsPanel from "./PowerPointsPanel.tsx";
import Grid from '@mui/material/Grid2';
import NationStats from "./NationStats.tsx";

export default function NationContent({
  index,
  nation,
  stats,
}: {
  index: number;
  nation: Nations;
  stats?: NationStat;
}) {
  const user = useUser();

  const steps = useMemo(() => {
    let initialSteps = [
      {
        target: "#flag_" + nation.id,
        content: "The comptroller can upload the nations flag",
      },
      {
        target: "#nation_name_" + nation.id,
        content: "The comptroller can add their nations name",
      },
      {
        target: "#constitution_" + nation.id,
        content:
          "You can view a nations constitution and the comptroller can write their nations constitution",
      },
      {
        target: "#administration_" + nation.id,
        content: "The members and roles for each nation",
      },
      {
        target: "#production_" + nation.id,
        content:
          "As you build infrastructure, your production will increase and display your next production values here",
      },
      {
        target: "#consumption_" + nation.id,
        content: "As you build infrastructure you may also have daily costs",
      },
      {
        target: "#stats_" + nation.id,
        content:
          "These are the current stats for each nation which are influenced by actions taken by your nation",
      },
      {
        target: "#power_points_" + nation.id,
        content: "There are many ways each nation can gain points.",
      },
    ];

    if (user.isAdmin) {
      initialSteps.splice(7, 0, {
        target: "#population_stat_" + nation.id,
        content:
          "You can add events here which will affect the population of this nation",
      });
    }

    if (user.nation.id != nation.id) {
      if (user.nation.name == "World Bank" && index == 0) {
        // keep
      } else {
        initialSteps = [];
      }
    }

    return initialSteps;
  }, [user, nation, index]);

  if (!user) {
    return <LoadingScreen />;
  }

  return (
    <>
      <JoyrideWrapper steps={steps} id={"nation_ride"} />
      <Container sx={{ maxWidth: "1200px" }}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <FlagCard nation={nation} />
          </Grid>
          <Grid  size={12}>
            <UserList users={nation.users} />
          </Grid>
          <Grid size={{xs: 12, sm: 6, md: "grow"}}>
            <ResourcePanel nation={nation} stats={stats} />
          </Grid>
          <Grid size={{xs: 12, sm: 6, md: "grow"}}>
            <AlliancesPanel nation={nation} />
          </Grid>
          <Grid size={{xs: 12, sm: 12, md: "grow"}}>
            <PowerPointsPanel stats={stats} />
          </Grid>
          <NationStats stats={stats} nation={nation} />
        </Grid>
      </Container>
    </>
  );
}
