/** @jsxImportSource @emotion/react */
import { Typography, TypographyProps } from "@mui/material";

import Box from "@mui/material/Box";
import { Item } from "../../my-api-client";

interface ItemInputProps extends TypographyProps {
  item: Item;
  costKey: string;
  title: string;
}

export default function Cost({
  item,
  costKey,
  title,
  ...props
}: ItemInputProps) {
  const resourceMap = {
    agCost: `${import.meta.env.VITE_CDN}/images/Ag.png`,
    lumberCost: `${import.meta.env.VITE_CDN}/images/Lumber.png`,
    oilCost: `${import.meta.env.VITE_CDN}/images/Oil.png`,
    mineralCost: `${import.meta.env.VITE_CDN}/images/Minerals.png`,
    dailyAgCost: `${import.meta.env.VITE_CDN}/images/Ag.png`,
    dailyLumberCost: `${import.meta.env.VITE_CDN}/images/Lumber.png`,
    dailyOilCost: `${import.meta.env.VITE_CDN}/images/Oil.png`,
    dailyMineralCost: `${import.meta.env.VITE_CDN}/images/Minerals.png`,
  };
  const keys1 = ["agCost", "lumberCost", "oilCost", "mineralCost"];
  const keys2 = [
    "dailyAgCost",
    "dailyLumberCost",
    "dailyOilCost",
    "dailyMineralCost",
  ];
  const keys = costKey == "dailyCost" ? keys2 : keys1;
  const hasCost = () => {
    const price = item.itemPrice;
    if (costKey == "dailyCost") {
      return (
        price.dailyAgCost ||
        price.dailyLumberCost ||
        price.dailyOilCost ||
        price.dailyMineralCost
      );
    } else {
      return (
        price.agCost || price.lumberCost || price.oilCost || price.mineralCost
      );
    }
  };

  return (
    <>
      {hasCost() ? (
        <Box
          gap={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          {...props}
        >
          <Typography variant="subtitle2">
            <span>{title}:</span>
          </Typography>
          <Box
            sx={{
              display: "flex",
            }}
          >
            {keys
              .map((key) => {
                return {
                  key: key,
                  // @ts-ignore
                  price: item.itemPrice[key],
                };
              })
              .filter((x) => x.price > 0)
              .map((x) => {
                return (
                  <Typography
                    key={(item.id, x.key)}
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img
                      width="20"
                      height="20"
                      alt="image"
                      src={
                        // @ts-ignore
                        resourceMap[x.key]
                      }
                    />
                    {x.price}&nbsp;
                  </Typography>
                );
              })}
          </Box>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
