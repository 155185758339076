import {FC, MouseEvent, useEffect, useState} from "react";
import {IconButton, Badge, Menu, MenuItem, Typography, ListItemText} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import useSWR from "swr";
import { Notification, UserClass } from "../../../my-api-client";
import { apiClient } from "../../../services/apiClient";
import { myMutate, useUser } from "../../../hooks/useUser";
import RouterLink from "../../RouterLink";

interface NotificationDisplay {
  id: number;
  text: string;
  link: string;
  isRead: boolean;
  createdAt: string;
}

const linkMap: Record<string, string> = {
  Trade: "/app/trade",
  TradeAlliance: "/app/diplomacy?tab=trade_alliances",
  MilitaryAlliance: "/app/diplomacy?tab=military_alliances",
  War: "/app/diplomacy?tab=wars",
  Subpoena: "/app/diplomacy?tab=subpoenas",
  Warning: "/app/diplomacy?tab=warnings",
  Purchase: "/app/development?tab=pending",
  Takeover: "/app/map",
};

const getNotificationLink = (notification: Notification): string => {
  if (notification.modelName === "Purchase") {
    // @ts-ignore
    return {
      PURCHASE_PLACED: "/app/map",
      SENDER_ACCEPTED: "/app/development?tab=approved",
      SENDER_REJECTED: "/app/development?tab=declined",
    }[notification.notificationStatus] || "";
  }
  return linkMap[notification.modelName] || "";
};

const getNotificationText = (notification: Notification, user: UserClass): string => {
  if (notification.notificationText) return notification.notificationText;

  const receivingNation = notification.receivingNation?.name || "";
  const offeringNation = notification.offeringNation.name;
  const modelNameMap: Record<string, string> = {
    Trade: "Trade",
    TradeAlliance: "Trade Alliance",
    MilitaryAlliance: "Military Alliance",
    War: "War",
    Subpoena: "Subpoena",
    Warning: "Warning",
    Purchase: "Purchase",
    Attack: "Attack",
    Takeover: "Takeover",
  };

  const tradeType = modelNameMap[notification.modelName] || "";
  const direction = notification.offeringNation.id === user.nation.id ? "SENDING" : "RECEIVING";

  const notificationTemplates: Record<string, Record<string, Record<string, string>>> = {
    Purchase: {
      SENDING: {
        CREATED: "Your nation has proposed a project",
        SENDER_ACCEPTED: "Your nation approved a project",
        SENDER_REJECTED: "The purchase was declined",
      },
    },
    Attack: {
      SENDING: {
        CREATED: `Your nation has proposed an attack against ${receivingNation}`,
        SENDER_ACCEPTED: `Your nation performed an attack against ${receivingNation}`,
        SENDER_REJECTED: `The attack against ${receivingNation} was declined`,
      },
      RECEIVING: {
        SENDER_ACCEPTED: `Your nation has been attacked by ${offeringNation}`,
      },
    },
    Trade: {
      SENDING: {
        CREATED: `Your nation has proposed a ${tradeType} with ${receivingNation}`,
        SENDER_ACCEPTED: `Your nation has sent a ${tradeType} to ${receivingNation}`,
        SENDER_REJECTED: `Your nation has declined a ${tradeType} with ${receivingNation}`,
        SENDER_CANCELED: `Your nation has canceled a ${tradeType} with ${receivingNation}`,
      },
      RECEIVING: {
        SENDER_ACCEPTED: `Your nation has received a ${tradeType} from ${offeringNation}`,
        SENDER_CANCELED: `${offeringNation} has rescinded a ${tradeType} offer`,
        RECEIVER_ACCEPTED: `Your nation has accepted a ${tradeType} from ${offeringNation}`,
        RECEIVER_DECLINED: `Your nation has declined a ${tradeType} from ${offeringNation}`,
      },
    },
  };

  // Trade is used as a fallback for many trade types
  return notificationTemplates[tradeType]?.[direction]?.[notification.notificationStatus] || notificationTemplates["Trade"]?.[direction]?.[notification.notificationStatus] || "";
};

const NotificationsDropdown: FC = () => {
  const user = useUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<NotificationDisplay[]>([]);

  const { data: notificationsData } = useSWR(apiClient().allNotificationsList.name, () =>
      apiClient().allNotificationsList().then(res => res.data.results)
  );

  useEffect(() => {
    if (notificationsData) {
      // regaccount test class lumber let x  = [notificationsData[0],notificationsData[0],notificationsData[0],notificationsData[0],notificationsData[0]]
      setNotifications(
          notificationsData.map(notification => ({
            id: notification.id,
            text: getNotificationText(notification, user),
            link: getNotificationLink(notification),
            isRead: notification.isRead,
            createdAt: new Date(notification.createdAt).toLocaleDateString(),
          })).filter(n => n.text)
      );
    }
  }, [notificationsData, user]);

  const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
    Promise.all(
        notifications.filter(n => !n.isRead).map(n => apiClient().allNotificationsMarkAsReadCreate(n.id))
    ).then(() => myMutate(apiClient().allNotificationsList.name));
    setNotifications(notifications.map(n => ({ ...n, isRead: true })));
  };

  return (
      <>
        <IconButton color="inherit" onClick={handleClick}>
          <Badge badgeContent={notifications.filter(n => !n.isRead).length} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {notifications.length ? (
              notifications.map(n => (
                  <MenuItem
                      key={n.id}
                      sx={{ backgroundColor: n.isRead ? "#f0f0f0" : "", marginBottom: "3px" }}
                      onClick={handleClose}
                      component={RouterLink}
                      to={`${n.link}${n.link.includes("?") ? "&" : "?"}notificationId=${n.id}`}
                  >
                    <ListItemText primary={n.text} secondary={n.createdAt} />
                  </MenuItem>
              ))
          ) : (
              <MenuItem onClick={handleClose}>
                <Typography variant="body2">No Notifications</Typography>
              </MenuItem>
          )}
        </Menu>
      </>
  );
};

export default NotificationsDropdown;
