import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function QuillEditor(props: ReactQuill.ReactQuillProps) {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "list",
    "bullet",
    "align",
  ];

  return (
    <ReactQuill theme="snow" modules={modules} formats={formats} {...props} />
  );
}
