import {FC, useState} from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";

type Props = {
  avatarUrl: string;
  userId: string;
  cancelEdit: () => void;
  onFileUpload: (file: File) => void;
  width: number;
  height: number;
  initialAspectRatio: number;
  saving: boolean;
};

export const UploadAvatar: FC<Props> = ({
  avatarUrl,
  cancelEdit,
  onFileUpload,
  initialAspectRatio,
  width,
  height,
  saving,
}) => {
  const [cropper, setCropper] = useState<any>();

  const getCropData = async () => {
    if (cropper) {
      const file = await fetch(
        cropper
          .getCroppedCanvas({ width: width * 2, height: height * 2 })
          .toDataURL(),
      )
        .then((res) => res.blob())
        .then((blob) => {
          return new File(
            [blob],
            Math.random().toString(36).slice(2) + ".png",
            { type: "image/png" },
          );
        });
      if (file) {
        onFileUpload(file);
      }
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Cropper
          src={avatarUrl}
          style={{ height: height, width: width }}
          initialAspectRatio={initialAspectRatio}
          guides={false}
          checkOrientation={false}
          cropBoxResizable={false}
          dragMode={"move"}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          sx={{ margin: "10px" }}
          onClick={cancelEdit}
          variant="outlined"
          disabled={saving}
        >
          Cancel
        </Button>
        <Button
          sx={{ margin: "10px" }}
          onClick={getCropData}
          variant="outlined"
          disabled={saving}
        >
          Crop Image
        </Button>
      </Box>
    </Box>
  );
};
