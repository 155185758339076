/** @jsxImportSource @emotion/react */
import { apiClient } from "../../services/apiClient";
import Alliances from "./alliances";
import { Stack } from "@mui/material";

import DeclarationModal from "./declarationModal";
import { useUser } from "../../hooks/useUser";
import { permissionService } from "../../services/permissionService";
import { getButtonSend } from "./utils";
import { TradeAlliance } from "../../my-api-client";

export default function SubpoenaDeclarations() {
  const user = useUser();
  return (
    <Stack
      sx={{
        textAlign: "center",
      }}
      spacing={2}
    >
      <DeclarationModal
        listCall={apiClient().allSubpoenasList.bind(apiClient())}
        allianceCreate={apiClient().allSubpoenasCreate.bind(apiClient())}
        allianceTypeTitle={"Send Subpoena"}
        allianceTypeText={"send a subpoena to"}
        allianceTypeId={"subpoena"}
        disabled={permissionService.canInitiateSubpoena(user)}
      />
      <Alliances
        listCall={apiClient().allSubpoenasList.bind(apiClient())}
        buttonMapSend={getButtonSend(user, "Subpoena", "Subpoena")}
        buttonMapReceive={(_alliance: TradeAlliance) => {
          return {};
        }}
        customStatusMap={{
          SENDER_ACCEPTED: "Delivered",
        }}
      ></Alliances>
    </Stack>
  );
}
