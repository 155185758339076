import { ReactNode } from "react";
import { Container, Typography, Card, CardContent } from "@mui/material";
import { School, Map, Public } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";

const Introduction = () => {
  return (
    <Container maxWidth="lg" sx={{ textAlign: "center", py: 6 }}>
      {/* Hero Section */}
      <Typography variant="h3" fontWeight={700} gutterBottom>
        Welcome to GeoStrat!
      </Typography>
      <Typography variant="h6" color="textSecondary" gutterBottom>
        GeoStrat is an interactive, strategy-based geography game designed for
        geography teachers. Students build nations, form alliances, manage
        resources, and learn real-world decision-making—all in a fun and
        engaging way.
      </Typography>

      {/* Features Section */}
      <Grid container spacing={4} sx={{ mt: 6 }}>
        <FeatureCard
          icon={<Public fontSize="large" color="primary" />}
          title="Build & Manage Nations"
          description="Guide students as they create and develop their own countries."
        />
        <FeatureCard
          icon={<Map fontSize="large" color="primary" />}
          title="Interactive Map & News"
          description="See nations evolve over time with daily news updates and a world map."
        />
        <FeatureCard
          icon={<School fontSize="large" color="primary" />}
          title="Designed for Education"
          description="Teach geography, decision-making, and global relations in a hands-on way."
        />
      </Grid>
    </Container>
  );
};

const FeatureCard = ({
  icon,
  title,
  description,
}: {
  icon: ReactNode;
  title: string;
  description: string;
}) => {
  return (
    <Grid size={{ xs: 12, sm: 6, md: 4 }}>
      <Card sx={{ textAlign: "center", p: 3, height: "100%" }}>
        <CardContent>
          {icon}
          <Typography variant="h6" fontWeight={600} sx={{ mt: 2 }}>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Introduction;
