import { FormEvent, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";

export default function Confirm() {
  const [queryParams] = useSearchParams();
  const email = queryParams.get("email");

  const { token } = useParams();
  const [error, setError] = useState("");
  const [badToken, setBadToken] = useState(false);
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const validateToken = () => {
    fetch(import.meta.env.VITE_NODE + "/api/password_reset/validate_token/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        token: token,
      }),
    })
      .then(isResponseOk)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        setError("Invalid link. Please try requesting a new password reset.");
        setBadToken(true);
      });
  };

  useEffect(() => {
    validateToken();
  }, []);

  const isResponseOk = (response: any) => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    } else {
      return response.json().then((data: any) => {
        console.error(data);
        throw Error(data.password?.join("\n") || "Unable to validate password");
      });
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    event.preventDefault();

    const snack = enqueueSnackbar(
      <>
        Saving &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );

    fetch(import.meta.env.VITE_NODE + "/api/password_reset/confirm/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        password: data.get("password"),
        token: token,
      }),
    })
      .then(isResponseOk)
      .then(() => {
        setError("");
        setBadToken(false);
        // setSuccess("Password successfully reset. Please try logging in");
        enqueueSnackbar("Password successfully reset. Redirecting", {
          variant: "success",
        });
        navigate("/login?reset=true");
      })
      .catch((err: Error) => {
        console.error(err);
        setError(err.message);
        setSuccess("");
        enqueueSnackbar("Error resetting password: " + err.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        closeSnackbar(snack);
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1 }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Set New Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              disabled
            />
            <TextField
              margin="normal"
              required
              fullWidth
              disabled={badToken || !!success}
              id="password"
              label="New Password"
              name="password"
              type="password"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              disabled={badToken || !!success}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save Password
            </Button>
            <Box sx={{ color: "red" }}>
              <pre>{error}</pre>
            </Box>
            <Box>{success}</Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}
