import React, {useMemo} from "react";
import {Card, Avatar, CardContent, Stack, Divider, Link} from "@mui/material";
import {NationUserClass, Role} from "../../my-api-client";
import Typography from "@mui/material/Typography";
import { SupervisorAccount } from "@mui/icons-material";
import Box from "@mui/material/Box";
import RouterLink from "../RouterLink";

interface UserListProps {
    users: NationUserClass[];
}

const UserList: React.FC<UserListProps> = ({ users }) => {
    // Sort the users based on the role order
    const sortedUsers = useMemo(() => {
        // Define the role order
        const roleOrder = [
            "Prime Minister",
            "Defense Minister",
            "Chief Financial Officer",
            "Secretary of State",
            "Comptroller",
        ];

        // Function to get the role index based on the defined order
        const getRoleOrderIndex = (role: Role) => roleOrder.indexOf(role.name);
        // Find the first role in the user's list that matches the role order
        return users.sort((a, b) => {
            const aRoleIndex =
                a.roles.map(getRoleOrderIndex).find((index) => index !== -1) ||
                Infinity;
            const bRoleIndex =
                b.roles.map(getRoleOrderIndex).find((index) => index !== -1) ||
                Infinity;

            return bRoleIndex - aRoleIndex;
        });
    }, [users]);

    return (
        <Card>
            <CardContent>
                <Stack display="flex" gap={3} p={2}>

                <Box display="flex" alignItems="center" gap={1}>
                    <SupervisorAccount />
                    <Typography variant={"h6"}>
                        Leadership
                    </Typography>
                </Box>
                <Divider sx={{ mt: 1, width: "100%", margin: "auto" }} />
                <Stack direction="row" gap={2} sx={{flexWrap: "wrap", justifyContent: "center", alignItems: "center"}} >
            {sortedUsers.map((user) => (
                <Link to={`/app/profile/${user.id}`} component={RouterLink} key={user.id}>
                <Box gap={1} sx={{ display: "flex", alignItems: "center", p: 1, flexDirection: "column", textAlign: "center", maxWidth: "150px" }}>
                    <Avatar src={user.imageUrl} alt={user.fullName} sx={{ width: 92, height: 92 }} />
                    <Box>
                        <Typography variant="h6">{user.fullName}</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {user.roles
                                .map((userRole) => {
                                    return userRole.name;
                                })
                                .join(", ")}
                        </Typography>
                    </Box>
                </Box>
                </Link>
            ))}
                </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default UserList;