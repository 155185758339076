import News from "../../components/News";


function NewsPage() {
  return (
    <>
      <News />
    </>
  );
}

export default NewsPage;
