import Trade from "../components/Trade";


function TradePage() {
  return (
    <>
      <Trade />
    </>
  );
}

export default TradePage;
