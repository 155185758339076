import {Box, Card, CardContent, Divider, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Handshake } from "@mui/icons-material";
import {useMilitaryAlliances, useTradeAlliances} from "../../hooks/useAlliances.ts";
import {
    Nations
} from "../../my-api-client";
import { FC } from "react";
import AlliancesCard from "./AllianceCard.tsx";

interface AlliancesPanelProps {
    nation: Nations;
}

const AlliancesPanel: FC<AlliancesPanelProps> = ({ nation }) => {
    const tradeAlliances = useTradeAlliances();
    const militaryAlliances = useMilitaryAlliances();
    return (
        <Card  sx={{height: "100%"}}>
            <CardContent>
                <Stack gap={3} p={2}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Handshake  />
                        <Typography variant="h6">
                            Alliances
                        </Typography>
                    </Box>
                    <Divider sx={{ mt: 1, width: "100%", margin: "auto" }} />
                    <AlliancesCard alliances={tradeAlliances.data} name={"Trade"} nation={nation}/>
                    <AlliancesCard alliances={militaryAlliances.data} name={"Military"} nation={nation}/>
                </Stack></CardContent></Card>
    );
};

export default AlliancesPanel;
