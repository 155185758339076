import LandingHeader from "../components/LandingHeader";
import Landing from "./landing.tsx";
import {
    useOutlet,
} from "react-router-dom";

function Home() {
    const outlet = useOutlet();

    return (
        <>
            <LandingHeader></LandingHeader>
            {outlet || <Landing />}
        </>
    );
}

export default Home