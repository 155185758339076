import { Fetcher } from "swr";
import { UserClass } from "./my-api-client";
import { apiClient } from "./services/apiClient.ts";

export const userFetcher: Fetcher<UserClass> = () => {
  return apiClient()
    .allUsersRetrieve(0)
    .then((res) => {
      return res.data;
    });
};
