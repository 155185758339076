/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import NationDropdown from "../NationDropdown";
import { myMutate, useUser } from "../../hooks/useUser";
import useSWR, { Fetcher } from "swr";
import {
  TradeAlliance,
  TradeAllianceRequest,
  TradeStatusStatusEnum,
} from "../../my-api-client";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import GeoButton from "../GeoButton";
import PostAddIcon from "@mui/icons-material/PostAdd";
import QuillEditor from "../Editor/quill_editor.tsx";
import { useNations } from "../../hooks/useNations.ts";
import Box from "@mui/material/Box";

interface AllianceModalInputProps {
  allianceCreate: (
    tradeAllianceRequest: TradeAllianceRequest,
    options?: AxiosRequestConfig<any> | undefined,
  ) => Promise<AxiosResponse<TradeAlliance, any>>;
  listCall: (
    options?: AxiosRequestConfig<any> | undefined,
  ) => Promise<AxiosResponse<TradeAlliance[], any>>;
  allianceTypeTitle: string;
  disabled: { success: boolean; message: string };
}

export default function AllianceModal({
  allianceCreate,
  allianceTypeTitle,
  listCall,
  disabled,
}: AllianceModalInputProps) {
  const { data: nations } = useNations();
  const [saving, setSaving] = useState(false);
  const user = useUser();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [allianceType, setAllianceType] = useState("public");
  const [selectedNation, setSelectedNation] = useState<number | null>(null);

  const default_alliance_text = `<p class="ql-align-center">Geo-Strat AGREEMENT &amp; ESTABLISHED ALLIANCE</p>
  <p><br/></p>
  <p>1.This is a ${allianceTypeTitle} alliance agreement. This agreement is established between ${user.nation.name} and \u200B<Nation’s Name>\u200B.</p>
  <p><br/></p>
  <p>2. This alliance intends to establish an agreement and understanding of the following: (Write out the “rules” or
    conditions of the agreement.)</p>
  <p><br/></p>
  <p><br/></p>
  <p>3. If this agreement is broken the offending party agrees to the following terms:</p>
  <p><br/></p>
  <p><br/></p>
  <p>4. Conditions which are acceptable to break the alliance agreement (if any):</p>
  <p><br/></p>
  <p><br/></p>.`;
  //   const default_alliance_text = `Geo-Strat AGREEMENT & ESTABLISHED ALLIANCE
  // <br/><br/>
  // 1.This is a ${allianceTypeTitle} alliance agreement. This agreement is established between ${user.nation.name} and \u200B<Nation’s Name>\u200B.
  // <br/><br/>
  // 2. This alliance intends to establish an agreement and understanding of the following: (Write out the “rules” or conditions of the agreement.)
  // <br/>
  // <br/>
  // <br/>
  // <br/>
  // 3. If this agreement is broken the offending party agrees to the following terms:
  // <br/>
  // <br/>
  // <br/>
  // <br/>
  // 4. Conditions which are acceptable to break the alliance agreement (if any):
  // <br/>
  // <br/>
  // <br/>
  // .
  // `;
  const [declaration, setDeclaration] = useState(default_alliance_text);
  const [disabledNations, setDisabledNations] = useState<number[]>([]);

  const tradeAlliancesFetcher: Fetcher<TradeAlliance[]> = () => {
    return listCall().then((res) => {
      return res.data;
    });
  };

  const { data: alliances } = useSWR<TradeAlliance[], Error>(
    listCall.name.replace("bound ", ""),
    tradeAlliancesFetcher,
  );

  useEffect(() => {
    const selected = nations?.find((n) => n.id === selectedNation)?.name;
    if (selected) {
      const regex = /\u200B(.*?)\u200B/;
      setDeclaration((declaration) =>
        declaration.replace(regex, `\u200B${selected}\u200B`),
      );
    }
  }, [nations, selectedNation]);

  useEffect(() => {
    if (alliances) {
      const existing = alliances.filter(
        (alliance) =>
          (alliance.offeringNationId === user?.nation.id ||
            alliance.receivingNationId === user?.nation.id) &&
          [
            TradeStatusStatusEnum.Created.toString(),
            TradeStatusStatusEnum.SenderAccepted.toString(),
            TradeStatusStatusEnum.ReceiverAccepted.toString(),
          ].indexOf(alliance.status!) > -1,
      );
      if (existing.length > 0) {
        const results: number[] = [];
        existing.forEach((alliance) => {
          results.push(alliance.offeringNationId);
          results.push(alliance.receivingNationId);
        });
        setDisabledNations(results);
      }
    }
  }, [alliances, user?.nation.id]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setAllianceType(event.target.value);
  };

  const handleNationChange = (event: SelectChangeEvent<number>) => {
    setSelectedNation(event.target.value as number);
  };

  const submitAlliance = () => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    allianceCreate({
      offeringNationId: user?.nation.id || 0,
      receivingNationId: selectedNation!,
      declaration,
      isSecret: allianceType === "secret",
    })
      .then(() => {
        enqueueSnackbar("Saved", { variant: "success" });
        myMutate(listCall.name);
        handleClose();
        setSelectedNation(null);
        setDeclaration(default_alliance_text);
      })
      .catch((e) => {
        enqueueSnackbar("Error: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);

        closeSnackbar(snack);
      });
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        sx={{ justifyContent: { sm: "center", xs: "left" } }}
      >
        <IconButton onClick={handleOpen}>
          <PostAddIcon />
        </IconButton>
      </Stack>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogContent>
          <Stack gap={1} p={1}>
            <Box>{user?.nation.name} would like to request a</Box>
            <FormControl fullWidth>
              <Select value={allianceType} onChange={handleChange}>
                <MenuItem value={"public"}>Public</MenuItem>
                <MenuItem value={"secret"}>Secret</MenuItem>
              </Select>
            </FormControl>
            <Box>{allianceTypeTitle} alliance with</Box>
            <NationDropdown
              selected={selectedNation}
              excluded={disabledNations}
              onChange={handleNationChange}
            />
            <Box>with the terms in the following agreement</Box>
            <QuillEditor value={declaration} onChange={setDeclaration} />
          </Stack>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <GeoButton
              onClick={submitAlliance}
              title={
                disabled.message ||
                (selectedNation == null
                  ? "Must select a nation"
                  : declaration.trim().length < 100
                    ? "Declaration must be at least 100 characters"
                    : "")
              }
              disabled={
                !disabled.success ||
                selectedNation == null ||
                declaration.trim().length < 100 ||
                saving
              }
            >
              Propose Alliance
            </GeoButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
